import styles from "./urunlisteson.module.css";
import styles2 from "./BelgeCreateYeni.module.css";

import React, { useEffect, useState , useRef, Animated } from 'react';
import { initializeApp } from "firebase/app";
import { getDocs, getFirestore } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy, onSnapshot} from "firebase/firestore"; 
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { Link, useNavigate } from 'react-router-dom';
import AramaSayfasi from "./AramaSayfasi";
import Ymenuopen from "./acikmenu";
const firebaseConfig = {
  apiKey: "AIzaSyBvuq7FyhGZ1OuPuYMDlxIvNx-ip_WVC8E",
  authDomain: "myinventory-73dc6.firebaseapp.com",
  projectId: "myinventory-73dc6",
  storageBucket: "myinventory-73dc6.appspot.com",
  messagingSenderId: "1009595674740",
  appId: "1:1009595674740:web:618105201f35104b27e121",
  measurementId: "G-F89WMPB355"
};
const Belgeyap = () => {

  const [usern, setusern] = useState("");
  const [firman, setfirman] = useState("");
  const [arama, setarama] = useState(0);



  const [actimi, setactimi] = useState(1);

    const [yerim, setyerim] = useState(0);
    const [cikislist, setcikislist] = useState([]);
    const [secilimi, setsecilimi] = useState([]);
    const [aciklama, setaciklama] = useState("");
    const [oncekiler, setoncekiler] = useState([]);
    const [kapali, setkapali] = useState(false);

    const ekleItem = (nere) => {
      // Eğer öğe dizide yoksa, ekle; varsa çıkar
      const index = secilimi.indexOf(nere); // Yeni öğenin dizideki konumunu bul

      if (index === -1) {
          // Yeni öğe dizide yoksa, ekle
          setsecilimi([...secilimi, nere]);
      } else {
          // Yeni öğe dizide varsa, çıkar
          const updatedList = secilimi.filter(item => item !== nere); // Yeni öğeyi çıkar
          setsecilimi(updatedList);
      }
  };

  const [kaynak, setkaynak] = useState("https://storage.googleapis.com/qstock-2521b.appspot.com/uploaded/f7P9eGACiLFBdYlFCaZ8.pdf");
  
  

  const [isMenuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    getoncekiler()
    
  }, []);


  const [baslik, setbaslik] = useState("");
  const [baslikiki, setbaslikiki] = useState("");
  const [tarihcesit, settarihcesit] = useState("");

  const yersec = async (yerrim) => {
      setyerim(1)
      if (yerrim == 0 ) {
          setbaslik("Stok Çıkış Belgesi")
          setbaslikiki("Çıkış Yapılan Stokları Seçiniz")
          settarihcesit("Çıkış")

          cikislar()

      }
      if (yerrim == 1 ) {
          setbaslik("Stok Giriş Belgesi")
          setbaslikiki("Giriş Yapılan Stokları Seçiniz")
          settarihcesit("Giriş")

          girisler()

      }

      if (yerrim == 2 ) {
          setbaslik("Stok Raporu")
          setbaslikiki("Stokları Seçiniz")
          settarihcesit("Giriş")

          urunler()

      }

    };

    const ackapa = async () => {
      
      setkapali(!kapali)
    };


    const belgesec = async (url) => {
      window.open(url, '_blank'); // _blank hedefi yeni bir sekme olarak açar


      setkaynak(url)
      console.log(url)
      setactimi(0)
      
    };

    const indirtest = async () => {
      
      try {
        
          const shareOptions = {
            type: 'application/pdf',
            url: kaynak,
            subject: 'Sample PDF',
          };
        
      } catch (error) {
        console.error('Error sharing PDF:', error);
      }
      
    };

    const olustur = async () => {

      const now = new Date();
      const formattedDateTime = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`;
      try {
          const depos = (localStorage.getItem('depo'))
          const selectedItems = secilimi.map(index => cikislist[index]);
          console.log(selectedItems,depos,formattedDateTime,now,aciklama)

        const docRef = await addDoc(collection(db, "belge_oncesi"), {
          sahip: depos,
          tarih: formattedDateTime,
          tariht : now,
          veri : selectedItems,
          aciklama : aciklama,
          
          
        });

        fetch(`http://2.59.117.93:2024/api/pdf/${docRef.id}`)
              .then(response => {
                  if (!response.ok) {
                  throw new Error('Network response was not ok');
                  }
                  // PDF verisiyle işlemleri burada yapabilirsiniz.
                  return response; // PDF dosyası olarak blob olarak alınabilir.
              })
              .then(pdfBlob => {
                  // PDF ile yapmak istediğiniz işlemleri burada gerçekleştirebilirsiniz.
                  // Örneğin, pdfBlob'u bir WebView içinde gösterebilirsiniz.

              })
              .catch(error => {
                  console.error('There was a problem with the fetch operation:', error);
              });

    
      } catch (error) {
        console.log("hata:",error)
      }




  };

    const cikislar = async () => {
      const fetchData = async () => {
  
          try {
              const q = query(collection(db, "stok_cikis"), where("sahip", "==", localStorage.getItem('depo')), orderBy("tariht", "desc"));
  
              const unsubscribe = onSnapshot(q, (querySnapshot) => {
                  const tutucu = [];
                  console.log("girdiki2");
  
                  querySnapshot.forEach((doc) => {
                      const data = {
                          urun: doc.data().urun,
                          aciklama: doc.data().aciklama,
                          adet: doc.data().adet,
                          barkod: doc.data().barkod,
                          neden: doc.data().neden,
                          palet: doc.data().palet,
                          tarih: doc.data().tarih,
                          baslik: doc.data().baslik,


                      };
  
                      tutucu.push(data);
                  });
  
                  setcikislist(tutucu);

              });
          } catch (error) {
              console.log("hata:", error);
          }
      };
  
      fetchData();
  };
  

  const girisler = async () => {
      const fetchData = async () => {
          console.log("gird3iki");
  
          try {
              const q = query(collection(db, "stok_giris"), where("sahip", "==", localStorage.getItem('depo')), orderBy("tariht", "desc"));
  
              const unsubscribe = onSnapshot(q, (querySnapshot) => {
                  const tutucu = [];
                  console.log("girdiki2");
  
                  querySnapshot.forEach((doc) => {
                      const data = {
                          urun: doc.data().urun,
                          aciklama: doc.data().aciklama,
                          adet: doc.data().adet,
                          barkod: doc.data().barkod,
                          neden: doc.data().neden,
                          palet: doc.data().palet,
                          tarih: doc.data().tarih,
                          baslik: doc.data().baslik,


                      };
  
                      tutucu.push(data);
                  });
  
                  setcikislist(tutucu);

              });
          } catch (error) {
              console.log("hata:", error);
          }
      };
  
      fetchData();
  };
  
  const urunler = async () => {
      const fetchData = async () => {
          console.log("gird3iki");
  
          try {
              const q = query(collection(db, "urunler"), where("sahip", "==", localStorage.getItem('depo')), orderBy("tariht", "desc"));
  
              const unsubscribe = onSnapshot(q, (querySnapshot) => {
                  const tutucu = [];
                  console.log("girdiki2");
  
                  querySnapshot.forEach((doc) => {
                      const data = {
                          urun: doc.data().urun,
                          aciklama: doc.data().aciklama,
                          adet: doc.data().adet,
                          barkod: doc.data().barkod,
                          palet: doc.data().palet,
                          tarih: doc.data().tarih,
                          baslik: doc.data().aciklama,


                      };
  
                      tutucu.push(data);
                  });
  
                  setcikislist(tutucu);

              });
          } catch (error) {
              console.log("hata:", error);
          }
      };
  
      fetchData();
  };

  const getoncekiler = async () => {
    const fetchData = async () => {

        try {
            const q = query(collection(db, "belgeler"), where("depono", "==", localStorage.getItem('depo')), orderBy("tarih", "desc"));

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const tutucu = [];

                querySnapshot.forEach((doc) => {
                    const data = {
                        aciklama: doc.data().aciklama,
                        tarih: doc.data().tariht,
                        link: doc.data().link,


                    };

                    tutucu.push(data);
                });

                setoncekiler(tutucu);

            });
        } catch (error) {
            console.log("hata:", error);
        }
    };

    fetchData();
};

const toggleMenu = () => {
  setMenuVisible(!isMenuVisible);
};










  const handleAramaChange = (newValue) => {

    setarama(arama === 1 ? 0 : 1);

   
  };
  const auth = getAuth();
  const user = auth.currentUser;
 








    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);


    

      

    








    
     
    
    
    

      if (arama === 0 ) {


  return (





<div className={styles.urunListesiN}>

      <div className={styles.navigationDrawerParent}>


      <Ymenuopen data=""/>






<div className={styles.frameParent}>
        <div className={styles.menu01Parent}>
         
        <div className={styles.inputField}>
            <div className={styles.inputFieldBase}>
              <div className={styles.inputFieldBase}>
                <div className={styles.label}>Email</div>
                <div className={styles.input}>
                  <div className={styles.content}>
                    <img
                      className={styles.searchSmIcon}
                      alt=""
                      src="/searchsm.svg"
                    />
                    <div className={styles.text} onClick={(e) => {
                e.stopPropagation(); 
                handleAramaChange();
              }}>Ara</div>
                  </div>
                  <img
                    className={styles.helpIcon}
                    alt=""
                    src="/help-icon.svg"
                  />
                </div>
              </div>
              
            </div>
          </div>
          
        </div>


        <div className={styles.productsListParent}>
          




        {kapali == false ? 


          <div className={styles2.onProgressKapali} onClick={(e) => {
            e.stopPropagation(); 
           ackapa();
          }}>
            <div className={styles2.placeholder}>
              <div className={styles2.content}>
                <b className={styles2.oluturulanBelgeler}>
                  Oluşturulan Belgeler
                </b>
                <div className={styles2.div9}>({oncekiler.length})</div>
              </div>
              <img
                className={styles2.uiIconclosefilled}
                alt=""
                src="/chevrondown.svg"
              />
            </div>
          </div>
:

          <div className={styles2.onProgress}>
            <div className={styles2.placeholder} onClick={(e) => {
            e.stopPropagation(); 
           ackapa();
          }}>
              <div className={styles2.content}>
                <b className={styles2.oluturulanBelgeler}>
                  Oluşturulan Belgeler
                </b>
                <div className={styles2.div9}>({oncekiler.length})</div>
              </div>
              <img
                className={styles2.uiIconclosefilled}
                alt=""
                src="/chevronup.svg"
              />
            </div>
            <img className={styles2.dividerIcon} alt="" src="/divider3.svg" />
            <div className={styles2.container1}>
            {oncekiler.map((item,index) => (

              <div className={styles2.task01} onClick={(e) => {
                e.stopPropagation(); 
                belgesec(item.link);
              }}>
                <div className={styles2.badges}>
                  <img
                    className={styles2.homePageIcon}
                    alt=""
                    src="/document@2x.png"
                  />
                </div>
                <div className={styles2.container2}>
                  <div className={styles2.right}>
                    <div className={styles2.steps}>
                      <div className={styles2.right}>
                        <div className={styles2.left11}>
                          <div className={styles2.bottom1}>
                            <div className={styles2.date}>
                              <div className={styles2.sonOluturulma}>
                              {item.aciklama}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles2.left12}>
                          <div className={styles2.bottom1}>
                            <div className={styles2.date}>
                              <div className={styles2.div11}>{item.tarih}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>




            ))}

              
              
            </div>
          </div>
      }


          <div className={styles2.wrapper5}>
            <div className={styles2.lg}>
              <div className={styles2.stepper}>
                <div className={styles2.steps}>
                  <div className={styles2.step}>
                    <div className={styles2.circle}>
                      <div className={styles2.div13}>1</div>
                    </div>
                    <div className={styles2.content}>
                      <div className={styles2.belgeTrSein}>Belge Türü Seçin</div>
                      <div className={styles2.line} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles2.password}>
                <div className={styles2.ctaButton} onClick={(e) => {
                e.stopPropagation(); 
                yersec(0);
              }}>
                  <div className={styles2.label1}>
                    <div className={styles2.xlLabel}>Stok Çıkış Belgesi</div>
                  </div>
                </div>
                <div className={styles2.ctaButton} onClick={(e) => {
                e.stopPropagation(); 
                yersec(1);
              }}>
                  <div className={styles2.label1}>
                    <div className={styles2.xlLabel}>Stok Giriş Belgesi</div>
                  </div>
                </div>
                <div className={styles2.ctaButton} onClick={(e) => {
                e.stopPropagation(); 
                yersec(2);
              }}>
                  <div className={styles2.label1}>
                    <div className={styles2.xlLabel}>Stok Raporu</div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          {yerim == 1 ? 

          <div className={styles2.wrapper6}>
            <div className={styles2.lg1}>
              <div className={styles2.stepper}>
                <div className={styles2.steps}>
                  <div className={styles2.step}>
                    <div className={styles2.circle}>
                      <div className={styles2.div13}>2</div>
                    </div>
                    <div className={styles2.content}>
                      <div className={styles2.belgeTrSein}>İçeriği Doldurun</div>
                      <div className={styles2.line} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles2.content1}>
                <b className={styles2.stokKBelgesi2}>{baslik}</b>
              </div>
              <div className={styles2.frameGroup}>
                <div className={styles2.taskNameWrapper}>
                  <div className={styles2.taskName}>
                    <div className={styles2.placeholder}>
                      <div className={styles2.sonOluturulma}>Açıklama</div>
                    </div>
                    <div className={styles2.container6}>
                      <div className={styles2.placeholder2}>
                        
                        <input
          className={styles2.smallLabel}
          placeholder="Açıklama"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setaciklama(e.target.value);
          }}
        />  
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles2.frameContainer}>
                  <div className={styles2.frameWrapper}>
                    <div className={styles2.placeholder2}>
                      <div className={styles2.date}>
                        <div className={styles2.date}>
                          <b className={styles2.kYaplanStokalar}>
                          {baslikiki}
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>


                 

                  {cikislist.map((item,index) => (

                  <div className={ secilimi.includes(index) ? styles2.product4 : styles2.product41 } onClick={(e) => {
                    e.stopPropagation(); 
                    ekleItem(index);
                  }}>
                    <div className={styles2.componenticonicUncheckParent}>
                    {secilimi.includes(index) ?

                      <img
                        className={styles2.componenticonicUncheck}
                        alt=""
                        src="/componenticonic-uncheck2.svg"
                      /> :   <img
                      className={styles2.componenticonicUncheck}
                      alt=""
                      src="/componenticonic-uncheck3.svg"
                    /> }
                      <div className={styles2.markaIdParent}>
                        <div className={styles2.markaId}>
                        {tarihcesit}  Tarihi: {item.tarih}
                        </div>
                        <div className={styles2.backpackRidd}>
                        {item.urun}
                        </div>
                        <div className={styles2.oluturulanBelgeler}>
                        {item.baslik}
                        </div>
                      </div>
                    </div>
                    <div className={styles2.separator} />
                    <div className={styles2.idParent}>
                      <div className={styles2.id}>
                        <div className={styles2.sonOluturulma}>Barkod</div>
                        <div className={styles2.div15}>{(item.barkod).length > 1 ? (item.barkod).length + "Adet" : item.barkod}</div>
                      </div>
                      <div className={styles2.var}>
                        <div className={styles2.sonOluturulma}>Adet:</div>
                        <div className={styles2.div15}>{item.adet}</div>
                      </div>
                      <div className={styles2.var}>
                        <div className={styles2.sonOluturulma}>{tarihcesit} Paleti:</div>
                        <div className={styles2.div15}>{item.palet}</div>
                      </div>
                    </div>
                  </div>

                  ))}



                </div>
              </div>
              <div className={styles2.nextStepButton} onClick={(e) => {
                e.stopPropagation(); 
                olustur();
              }} >
                <div className={styles2.button}>
                  <div className={styles2.devamEt0}>
                    Devam Et ({secilimi.length} Adet Seçili)
                  </div>
                </div>
              </div>
            </div>
          </div>
: null }


{yerim == 2 ? 

          <div className={styles2.wrapper6}>
            <div className={styles2.lg}>
              <div className={styles2.stepper}>
                <div className={styles2.steps}>
                  <div className={styles2.step}>
                    <div className={styles2.circle}>
                      <div className={styles2.div13}>3</div>
                    </div>
                    <div className={styles2.content}>
                      <div className={styles2.belgeTrSein}>Oluşturuluyor</div>
                      <div className={styles2.line} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles2.content2}>
                <div className={styles2.header}>
                  <img
                    className={styles2.image9Icon}
                    alt=""
                    src="/image-9@2x.png"
                  />
                </div>
              </div>
              <div className={styles2.confirmButton}>
                <div className={styles2.button}>
                  <div className={styles2.devamEt0}>Kaydet</div>
                </div>
              </div>
            </div>
          </div>
:null}

        </div>










          

        </div>
      </div>
      </div>




















  );
              }
              else {
                return(
                <AramaSayfasi handleAramaChange={handleAramaChange}/>
                )
                }


};

export default Belgeyap;
