import styles from "./MusteriListesi.module.css";
import styles2 from "./AnaSayfaWeb.module.css";
import styles3 from "./MusteriEkle.module.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import React, { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getDocs, getFirestore } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy} from "firebase/firestore"; 
import Ymenuopen from "./acikmenu";
import { Link, useNavigate } from 'react-router-dom';
import AramaSayfasi from "./AramaSayfasi";
import UrunEkle from "./UrunEkle";
const firebaseConfig = {
  apiKey: "AIzaSyBvuq7FyhGZ1OuPuYMDlxIvNx-ip_WVC8E",
  authDomain: "myinventory-73dc6.firebaseapp.com",
  projectId: "myinventory-73dc6",
  storageBucket: "myinventory-73dc6.appspot.com",
  messagingSenderId: "1009595674740",
  appId: "1:1009595674740:web:618105201f35104b27e121",
  measurementId: "G-F89WMPB355"
};


const MusteriListesi = () => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);



  const [madi, setmadi] = useState('');
    const [mfirma, setmfirma] = useState('');
    const [memail, setmemail] = useState('');
    const [mtelefon, setmtelefon] = useState('');
    const [mnot, setmnot] = useState('');



  const [eklevisible, seteklevisible] = useState(1);



  const [musterilist, setmusterilist] = useState([]);

  useEffect(() => {
    const fetchData = async () => {

    


      try {

      
  
        const fetchData = async () => {
          const musteriliste = [];

  
          const querySnapshot = await getDocs(
            query(
              collection(db, "musteri_listesi"),where("sahip","==",localStorage.getItem('depo')),orderBy("tariht","desc")
            )
          ); 
        
          querySnapshot.forEach((doc) => {
  

              const data = {
                musteri: doc.data().musteri,
                email: doc.data().email,
                firma: doc.data().firma,
                telefon: doc.data().telefon,
                not: doc.data().not,


                
    
              };
              musteriliste.push(data);
              
            
  
  
          });
         
          setmusterilist(musteriliste)
        };  
        fetchData();
      
      }
      catch(error) {
        
      }
    
    };

    fetchData();
    
  }, []);


  const [navvisible, setnavvisible] = useState(0);

      const handleClick = () => {
      
        setnavvisible(navvisible === 1 ? 0 : 1);
    
      };

      const [musterieklevis, setmusterieklevis] = useState(1);

      const handleClickekle = () => {
      
        setmusterieklevis(musterieklevis === 1 ? 0 : 1);
    
      };


      const  navfunc = async (nere) => {
         
        navigate(nere);
      };
      const navigate = useNavigate();




      const kayittamamla = async () => {

        handleClickekle()

        const now = new Date();
        const formattedDateTime = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`;
        try {
          const docRef = await addDoc(collection(db, "musteri_listesi"), {
            musteri: madi,
            email: memail,
            firma: mfirma,
            telefon: mtelefon,
            not: mnot,
            sahip : localStorage.getItem('depo'),
            ok:0,
            tarih: formattedDateTime,
            tariht : now,
            
            
          });
          
          window.location.reload();

      
      
        } catch (error) {
          
        }
      }





      const [arama, setarama] = useState(0);

      const handleAramaChange = (newValue) => {
    
        setarama(arama === 1 ? 0 : 1);
    
       
      };

      const [usern, setusern] = useState("");
  const [firman, setfirman] = useState("");

  const auth = getAuth();
  const user = auth.currentUser;
  useEffect(() => {
  
    const updatedkod = [];
  
    const fetchData = async () => {
      try {
        
  
    
      
      const querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("id", "==", user.uid)
        )
      );
  
      for (const doc of querySnapshot.docs) {
  
        setusern(doc.data().user)
        setfirman(doc.data().firma)
        
      }
    }
  
    catch (error) {
  
      
  
  }
  
      
  
    };
      
    fetchData();
  
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {

    setShowPopup(!showPopup);
  };
      if (arama === 0 ) {


  return (


    <div className={styles.musteriListesi}>



<Ymenuopen data="musteriler"/>
{showPopup == true ?
        <div className="popup-overlay"  onClick={closePopup}>
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <div className="popup-inner">








            <div className={styles3.headerParent}>
          <div className={styles3.header}>
            <div className={styles3.ctaButton} onClick={(e) => {
                e.stopPropagation(); 
                togglePopup();
              }}>
              <img
                className={styles3.uiIconclosefilled}
                alt=""
                src="/arrowleft.svg"
              />
            </div>
            <b className={styles3.mteriKayd}>Müşteri Kaydı</b>
          </div>
          <div className={styles3.frameGroup}>
            <div className={styles3.frameContainer}>
              <div className={styles3.taskNameParent}>
                <div className={styles3.taskName}>
                  <div className={styles3.placeholder}>
                    <div className={styles3.mteriAd}>Müşteri Adı</div>
                  </div>
                  <div className={styles3.container1}>
                    <div className={styles3.placeholder}>
                      <input
          className={styles3.mteriAd}
          placeholder="Müşteri Adı"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setmadi(e.target.value);
          }}
        /> 
                    </div>
                  </div>
                </div>
                <div className={styles3.taskName1}>
                  <div className={styles3.placeholder}>
                    <div className={styles3.mteriAd}>Firma Adı</div>
                  </div>
                  <div className={styles3.container1}>
                    <div className={styles3.placeholder}>
                      <input
          className={styles3.mteriAd}
          placeholder="Firma Adı"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setmfirma(e.target.value);
          }}
        /> 
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles3.taskNameGroup}>
                <div className={styles3.taskName2}>
                  <div className={styles3.placeholder}>
                    <div className={styles3.mteriAd}>Email</div>
                  </div>
                  <div className={styles3.container1}>
                    <div className={styles3.placeholder}>
                      <input
          className={styles3.mteriAd}
          placeholder="Email"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setmemail(e.target.value);
          }}
        /> 
                    </div>
                  </div>
                </div>
                <div className={styles3.taskName3}>
                  <div className={styles3.placeholder}>
                    <div className={styles3.mteriAd}>Telefon</div>
                  </div>
                  <div className={styles3.container1}>
                    <div className={styles3.placeholder}>
                    <div className={styles3.mteriAdx}>+90</div>

                      <input
          className={styles3.mteriAd}
          placeholder="Telefon"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setmtelefon(e.target.value);
          }}
        /> 
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles3.taskName4}>
                <div className={styles3.placeholder}>
                  <div className={styles3.mteriAd}>Not</div>
                </div>
                <div className={styles3.container5}>
                  <div className={styles3.placeholder}>
                    <input
          className={styles3.smallLabel4}
          placeholder="Not"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setmnot(e.target.value);
          }}
        /> 
                  </div>
                </div>
              </div>
            </div>
            <div className={styles3.buttons}>
              <div className={styles3.ctaButton1} onClick={(e) => {
                e.stopPropagation(); 
                kayittamamla();
              }}>
                <div className={styles3.label6}>
                  <div className={styles3.xlLabel}>Kaydet</div>
                </div>
              </div>
              <div className={styles3.ctaButton2} onClick={(e) => {
                e.stopPropagation(); 
                togglePopup();
              }}>
                <div className={styles3.label6}>
                  <div className={styles3.xlLabel}>İptal</div>
                </div>
              </div>
            </div>
          </div>
        </div>










            </div>
          </div>
        </div>
      : null }



      <div className={styles.frameParent}>
        <div className={styles.menu01Parent}>
          
          <div className={styles.inputField}>
            <div className={styles.inputFieldBase}>
              <div className={styles.inputFieldBase}>
                <div className={styles.input}>
                  <div className={styles.content}>
                    <img
                      className={styles.searchSmIcon}
                      alt=""
                      src="/searchsm.svg"
                    />
                    <div className={styles.text} onClick={(e) => {
                e.stopPropagation(); 
                handleAramaChange();
              }}>Ara</div>
                  </div>
                  <img
                    className={styles.helpIcon}
                    alt=""
                    src="/help-icon.svg"
                  />
                </div>
              </div>
             
            </div>
          </div>
          
        </div>
        <div className={styles.productsList}>
          <div className={styles.productsList1}>Müşteri Listesi ({musterilist.length})</div>


          <div className={styles.add} onClick={(e) => {
                e.stopPropagation(); 
                togglePopup();
              }}>
            <div className={styles.btnSend}>
              <div className={styles.shape} />
            </div>
            <img
              className={styles.componenticonicPlus}
              alt=""
              src="/componenticonic-plus.svg"
            />
          </div>


        </div>








        <div className={styles.frameGroup}>

        {musterilist.map((item) => (

          <div key={item.musteri}  className={styles.frameWrapper}>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                
              <img className={styles2.icon} alt="" src="/wicon.png" />
                <div className={styles.frameWrapper1}>
                  <div className={styles.canKraParent}>
                    <b className={styles.canKra}>{item.musteri}</b>
                    <div className={styles.aFirmas}>{item.firma}</div>
                  </div>
                </div>
              </div>
              <img className={styles.frameChild} alt="" src="/vector-1.svg" />
              <div className={styles.frameParent1}>
                <div className={styles.phoneParent}>
                  <img
                    className={styles.phoneIcon}
                    alt=""
                    src="/phone@2x.png"
                  />
                  <div className={styles.krckrccorpnet}>{item.telefon}</div>
                </div>
                <div className={styles.emailSignParent}>
                  <img
                    className={styles.phoneIcon}
                    alt=""
                    src="/email-sign@2x.png"
                  />
                  <div className={styles.krckrccorpnet}>{item.email}</div>
                </div>
              </div>
 
              {item.not !== "" ? 
              <div className={styles.notAlannotAlannotAlanWrapper}>
                <div className={styles.notAlannotAlannot}>
                  {item.not}
                </div>
              </div>
: null }

            </div>
          </div>
        ))}
        </div>
      </div>

     
    </div> 
     


  );
        }
  else {
    return(
    <AramaSayfasi handleAramaChange={handleAramaChange}/>
    )
    }
};

export default MusteriListesi;
