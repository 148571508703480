import styles from "./StokBilgi.module.css";
import styles2 from "./AnaSayfaWeb.module.css";
import styles3 from "./StokBilgiiki.module.css";

import React, { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getDocs, getFirestore } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy,getDoc,doc} from "firebase/firestore"; 
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { PieChart } from '@mui/x-charts/PieChart';

import { Link, useNavigate } from 'react-router-dom';

import Ymenuopen from "./acikmenu";
const firebaseConfig = {
  apiKey: "AIzaSyBvuq7FyhGZ1OuPuYMDlxIvNx-ip_WVC8E",
  authDomain: "myinventory-73dc6.firebaseapp.com",
  projectId: "myinventory-73dc6",
  storageBucket: "myinventory-73dc6.appspot.com",
  messagingSenderId: "1009595674740",
  appId: "1:1009595674740:web:618105201f35104b27e121",
  measurementId: "G-F89WMPB355"
};
const StokBilgi = () => {

  const [usern, setusern] = useState("");
  const [firman, setfirman] = useState("");

  const auth = getAuth();
  const user = auth.currentUser;
  useEffect(() => {
  
    const updatedkod = [];
  
    const fetchData = async () => {
      try {
        
  
    
      
      const querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("id", "==", user.uid)
        )
      );
  
      for (const doc of querySnapshot.docs) {
  
        setusern(doc.data().user)
        setfirman(doc.data().firma)
        
      }
    }
  
    catch (error) {
  
      
  
  }
  
      
  
    };
      
    fetchData();
  
  }, []);
  const [acikkapali, setacikkapali] = useState([]);

  const acikmiki = async (nere) => {
 
    if (acikkapali.includes(nere)) {
      const yeniListe = acikkapali.filter(item => item !== nere);
      setacikkapali(yeniListe);
    } else {
      setacikkapali([...acikkapali, nere]);
    }
  
  };
  const params = new URLSearchParams(window.location.search);
  const veri1 = params.get('id');
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const [urunbilgi, seturunbilgi] = useState([]);
  const [cikis_list, setcikis_list] = useState([]);
  const [giris_list, setgiris_list] = useState([]);
  const [gosterim_list, setgosterim_list] = useState([]);

  const [urunid, seturunid] = useState("");

  const [urunlist, seturunlist] = useState([]);



  const doSomething = async() =>{
    //datayenile()
           
    try {
    
      
    
      const fetchData = async () => {
        const ggliste = [];

        try {
          const depo = localStorage.getItem('depo');
          const querySnapshot = await getDocs(
            query(
              collection(db, "urunler"),
              where("sahip", "==", depo),
              where("urun", "==", veri1)
            )
          );
      
          querySnapshot.forEach((doc) => {
            const data = {
              adet: doc.data().adet,
              palet: doc.data().palet,
              urun:doc.data().urun,
              cesit:doc.data().cesit,
              son_giris:doc.data().son_giris,
              son_cikis:doc.data().son_cikis,

              marka:doc.data().marka,

              giren: doc.data().giren,
              paletid: doc.id,
              cikan: doc.data().cikan,
              imgurl: doc.data().imgurl,
              barkod: doc.data().barkod,
            };

            ggliste.push(data);
            console.log(data);
          });
          
          seturunlist(ggliste);
          console.log(ggliste)
        } catch (error) {
          console.error("Veri çekme hatassı:", error);
        }
      };  
      
      fetchData(); 
      
    
      
    
    }
    
    catch(error) {
      console.log("hata:",error)
    }
              }
            useEffect(() =>{
              doSomething();
             },[])








  useEffect(() => {
    const fetchData = async () => {

    


      try {

      
  
        const fetchData = async () => {
          const cesitliste = [];
  
            try {
            const docRef = doc(db, "urunler",veri1);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                seturunid(docSnap.id)
            seturunbilgi(docSnap.data())
            setsliceColor(["#d2f3fa","#fefad1"])

            setseri([docSnap.data().giren,docSnap.data().cikan])
            } else {
            }
        }
        catch(error) {
          
        }
        };  
        fetchData();
      
      }
      catch(error) {
        
      }
    
    };

    fetchData();
    
  }, []);






  const cikisyap = async () => {

    setShowConfirm(true)

  
  
  
  
  
  
  
  
  
  }




  const urunlerfunc = async () => {

    


    try {

    

      const fetchData = async () => {
        const cesitliste = [];

          try {
          const docRef = doc(db, "urunler",veri1);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
              seturunid(docSnap.id)
          seturunbilgi(docSnap.data())
          setsliceColor(["#d2f3fa","#fefad1"])

          setseri([docSnap.data().giren,docSnap.data().cikan])
          } else {
          }
      }
      catch(error) {
        
      }
      };  
      fetchData();
    
    }
    catch(error) {
      
    }
  
  };



  const widthAndHeight = 240

const [isMenuVisible, setMenuVisible] = useState(false);
const [seri, setseri] = useState([]);
const [sliceColor, setsliceColor] = useState([]);

useEffect(() => {
  const fetchData = async () => {

  


    try {

    

      const fetchData = async () => {
        const cikisliste = [];
          const seritutucu = [];

          const q = query(collection(db, "stok_cikis"), where("urunid", "==",veri1));

          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            cikisliste.push(doc.data())
          });

         
          
          setcikis_list(cikisliste)
          setgosterim_list(cikisliste)
      };  
      fetchData();
    
    }
    catch(error) {
      
    }
  
  };

  fetchData();
  
}, []);




const stokcikisfunc = async () => {

  


  try {

  

    const fetchData = async () => {
      const cikisliste = [];
        const seritutucu = [];

        const q = query(collection(db, "stok_cikis"), where("urunid", "==", veri1));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          cikisliste.push(doc.data())
        });

       
        
        setcikis_list(cikisliste)
        setgosterim_list(cikisliste)
    };  
    fetchData();
  
  }
  catch(error) {
    
  }

};


const secmearaci = async (hangisi) => {

  sethangi(hangisi)
  if (hangisi == 0){

    setgosterim_list(cikis_list)
  }

  if (hangisi == 1){
    const fetchData = async () => {

  


      try {

      
  
        const fetchData = async () => {
          const girisliste = [];
            const seritutucu = [];

            const q = query(collection(db, "stok_giris"), where("urunid", "==",veri1));

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              girisliste.push(doc.data())
            });

           
            
            setgiris_list(girisliste)
            setgosterim_list(girisliste)
        };  
        fetchData();
      
      }
      catch(error) {
        
      }
    
    };

    fetchData();
  }
 
};

const [hangi, sethangi] = useState(0);

const toggleMenu = () => {
  setMenuVisible(!isMenuVisible);
};

const [showConfirm, setShowConfirm] = useState(false);


const [refreshing, setRefreshing] = useState(false);

  const onRefresh = async () => {
    setRefreshing(true);
    try {
      await fetchData(); // Verileri yeniden getir
    } catch (error) {
      console.error("onRefresh Hatası:", error);
    } finally {
      setRefreshing(false);
    }
  };

  const fetchData = async () => {
    try {
      sethangi(0)

      await stokcikisfunc();
      await urunlerfunc();
      
    } catch (error) {
      console.error("fetchData Hatası:", error);
    }
  };

  
  const handleConfirm = () => {
    setShowConfirm(false);
  };
  const [navvisible, setnavvisible] = useState(1);
  const [webgizle, setwebgizle] = useState(1);

      const handleClick = () => {
      
        setnavvisible(navvisible === 1 ? 0 : 1);
    
      };
      const  navfunc = async (nere) => {
         
        navigate(nere);
      };

      const  godetay = async (nere) => {
         
        navigate("/panel/stok");
      };
      const navigate = useNavigate();


  return (
    <div className={styles.stokBilgi}>



<Ymenuopen data="stokbilgi"/>



{urunlist.length > 0 ? 

      <div className={styles.headerParent}>

        
        <div className={styles.header}>
          <div className={styles.ctaButton}>
            <img
            onClick={(e) => {
              e.stopPropagation(); 
              godetay();
            }}
              className={styles.uiIconclosefilled}
              alt=""
              src="/arrowleft.svg"
            />
          </div>
          <b className={styles.stokBilgi1}>Stok Bilgi</b>
        </div>



        <div className={styles.header1}>
          <div className={styles.stokSmi}>{urunlist[0].urun}</div>
        </div>
        {webgizle === 0 ? 

        <div className={styles.ctaButtonParent}>
          <div className={styles.ctaButton1}>
            <div className={styles.label}>
              <div className={styles.xlLabel}>Stok Girişi Yap</div>
            </div>
          </div>
          <div className={styles.ctaButton2}>
            <div className={styles.label}>
              <div className={styles.xlLabel}>Stok Çıkışı Yap</div>
            </div>
          </div>
        </div>
: null }


        <div className={styles.tabs}>
          <div className={styles.tabs1}>
            <div className={styles.tab01}>
              <div className={styles.label}>
              </div>
            </div>
            <div className={styles.tab02}>
              <div className={styles.label}>
                <div className={styles.xlLabel}>Giriş / Çıkış Grafiği</div>
              </div>
            </div>
            <div className={styles.tab01}>
              
            </div>
          </div>
        </div>
        <div className={styles.chart}>
          <div className={styles.pieChartIcon}>
          <PieChart
          width={400}
  height={200}
  series={[
    {
      
      data: [
        { value: urunlist.reduce((total, item) => total + item.giren, 0), color: '#d2f3fa' }, { value: urunlist.reduce((total, item) => total + item.cikan, 0), color: '#fefad1' },
        // ...
      ],
    },
  ]}


/>
</div>
          <div className={styles.horizontal}>
            <div className={styles.label5}>
              <div className={styles.labelChild} />
              <div className={styles.label}>
                <div className={styles.largeLabel}>Giren ({urunlist.reduce((total, item) => total + item.giren, 0)})</div>
              </div>
            </div>
            <div className={styles.label5}>
              <div className={styles.labelItem} />
              <div className={styles.label}>
                <div className={styles.largeLabel}>Çıkan ({urunlist.reduce((total, item) => total + item.cikan, 0)})</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.stats}>
          <div className={styles.horizontal1}>
            <div className={styles.totalGoals}>
              <div className={styles.sonStokGiri}>Son Stok Giriş</div>
              <b className={styles.mart}>{urunlist.reduce((enYakinTarih, item) => (!enYakinTarih || new Date(item.son_giris) > new Date(enYakinTarih)) ? item.son_giris : enYakinTarih, null)}</b>
            </div>
            <div className={styles.completedGoals}>
              <div className={styles.sonStokGiri}>Son Stok Çıkış</div>
              <b className={styles.b}>{urunlist.reduce((enYakinTarih, item) => (!enYakinTarih || new Date(item.son_giris) > new Date(enYakinTarih)) ? item.son_cikis : enYakinTarih, null)}</b>
            </div>
            <div className={styles.successRate}>
              <div className={styles.stokstokKategorisi}>
               Güncel Stok
              </div>
              <b className={styles.b}>{urunlist.reduce((total, item) => total + item.adet, 0)}</b>
            </div>
          </div>
          
        </div>




        <div className={styles.tabMenu}>
          <div className={styles.frameParent}>

{hangi === 0 ? 
            <div className={styles.profitParent} >
              <div className={styles.profit}>Gelen Ürünler</div>
              <img className={styles.selectorIcon} alt="" src="/selector.svg" />
            </div>
: <div className={styles.profitParent}
onClick={(e) => {
  e.stopPropagation(); 
  secmearaci(0);
}}>
<div className={styles.candidates}>Gelen Ürünler</div>
</div> }

{hangi === 1 ? 
            <div className={styles.profitParent}>
              <div className={styles.profit}>Giden Ürünler</div>
              <img className={styles.selectorIcon} alt="" src="/selector.svg" />
            </div>
: <div className={styles.profitParent} onClick={(e) => {
  e.stopPropagation(); 
  secmearaci(1);
}}>
<div className={styles.candidates}>Giden Ürünler</div>
</div> }
            


          </div>
          <img className={styles.separatorIcon} alt="" src="/separator.svg" />
        </div>




        <div className={styles.recommendedParent}>


        {gosterim_list.map((item) => (

          <div className={styles.recommended}>
            <div className={styles.recommended1}>İşlem Tarihi : {item.tarih}</div>
            <div className={styles.funds1}>
              <div className={styles.frameGroup}>
                <div className={styles.frameContainer}>
                  <div className={styles.imageParent}>
                   
                    <div className={styles.newWorldFundParent}>
                      <div className={styles.profit}>{item.baslik}</div>
                      <div className={styles.industries}>{item.tarih}</div>
                    </div>
                  </div>
                  <div className={styles.midRisk}>
                    <div className={styles.midRisk1}>{item.neden}</div>
                  </div>
                </div>
                <div className={styles.frameParent1}>
                  <div className={styles.yearParent}>
                   
                    <div className={styles.year}>
                      <div className={styles.year1}>Adet</div>
                      <div className={styles.div10}>{item.adet}</div>
                    </div>
                  </div>
                  <div className={styles.year8}>
                    <div className={styles.year1}>Not</div>
                    <div className={styles.div14}>
                    {item.aciklama}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
               ))}


        </div>
      </div>
: null }


{urunlist != null ? 

<div className={styles3.barkodList}>
        <div className={styles3.productsListParent}>
          <div className={styles3.productsList}>Barkod Listesi</div>

          {urunlist.sort((a, b) => parseInt(a.palet) - parseInt(b.palet)).map((item, indexiki) => (


          <div className={styles3.onProgressKapaliParent}>

          {!acikkapali.includes(indexiki) ? 

            <div className={styles3.onProgressKapali} onClick={(e) => {
              e.stopPropagation(); 
              acikmiki(indexiki);
            }}>
              <div className={styles3.placeholder}>
                <div className={styles3.left9}>
                  <b className={styles3.palet1}>Palet No : {item.palet}</b>
                  <div className={styles3.adet}>{item.adet} Adet</div>
                </div>
                <img
                  className={styles3.uiIconclosefilled}
                  alt=""
                  src="/chevrondown.svg"
                />
              </div>
            </div>

:
            <div className={styles3.onProgress}>
              <div className={styles3.placeholder} onClick={(e) => {
              e.stopPropagation(); 
              acikmiki(indexiki);
            }}>
                <div className={styles3.left9}>
                  <b className={styles3.palet1}>Palet No : {item.palet}</b>
                  <div className={styles3.adet}>{item.adet} Adet</div>
                </div>
                <img
                  className={styles3.uiIconclosefilled}
                  alt=""
                  src="/chevronup.svg"
                />
              </div>
              <img className={styles3.dividerIcon} alt="" src="/divider.svg" />
              <div className={styles3.container1}>
                
                { (urunlist[indexiki].barkod).map((itemx,index) => (

                <div className={styles3.product4}>
                  <div className={styles3.markaIdParent}>
                    <div className={styles3.markaId}> Barkod No : {itemx}</div>
                    
                  </div>
                </div>

                ))}
              </div>
            </div>
}

         
          </div>
        ))}


        </div>
      </div>

: null }

{showConfirm && (
        <div className="modal">
          <div className="modal-content">
            <h2>Onay</h2>
            <p>Stok çıkışını onaylıyor musunuz?</p>
            <button onClick={handleConfirm}>Onayla</button>
            <button onClick={() => setShowConfirm(false)}>İptal</button>
          </div>
        </div>
      )}



    </div>
  );
};

export default StokBilgi;
