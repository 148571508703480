import styles from "./Gelengiden.module.css";
import React, { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getDocs, getFirestore } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy} from "firebase/firestore"; 
import AramaSayfasi from "./AramaSayfasi";
import styles2 from "./AnaSayfaWeb.module.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Ymenuopen from "./acikmenu";


import { Link, useNavigate } from 'react-router-dom';

const firebaseConfig = {
  apiKey: "AIzaSyBvuq7FyhGZ1OuPuYMDlxIvNx-ip_WVC8E",
  authDomain: "myinventory-73dc6.firebaseapp.com",
  projectId: "myinventory-73dc6",
  storageBucket: "myinventory-73dc6.appspot.com",
  messagingSenderId: "1009595674740",
  appId: "1:1009595674740:web:618105201f35104b27e121",
  measurementId: "G-F89WMPB355"
};
const Gelengiden = () => {

  const [arama, setarama] = useState(0);

  const handleAramaChange = (newValue) => {

    setarama(arama === 1 ? 0 : 1);

   
  };
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const [gglist, setgglist] = useState([]);
  const [gglistgosterim, setgglistgosterim] = useState([]);

  const [gglistgiden, setgglistgiden] = useState([]);


  const [hangi, sethangi] = useState(0);

  const prest = (ne) => {
    settord(ne)
    if (hangi == 0) {
          if (ne == 0) {

              const fetchData = async () => {
      
          
      
      
                  try {
            
                  
              
                    const fetchData = async () => {
                      const ggliste = [];
            
              
                      const querySnapshot = await getDocs(
                        query(
                          collection(db, "gelecek_urun_kayit"),where("sahip","==",localStorage.getItem('depo')),orderBy("tariht","desc")
                        )
                      ); 
                    
                      querySnapshot.forEach((doc) => {
              
            
                          const data = {
                            urun: doc.data().urun,
                            aciklama: doc.data().aciklama,
                            adet: doc.data().adet,
                            barkod: doc.data().barkod,
                            cesit: doc.data().cesit,
                            kaynak: doc.data().kaynak,
                            marka: doc.data().marka,
                            baslik: doc.data().baslik,
                            tarih: doc.data().tarih,
                            durum: doc.data().durum,
                            id: doc.id,
        
        
                            
                
                          };
                          ggliste.push(data);
                          
                        
              
              
                      });
                    
                      setgglistgosterim(ggliste)
                    };  
                    fetchData();
                  
                  }
                  catch(error) {
                    
                  }
                
                };
            
                fetchData();
              
          }

          if (ne == 1) {
              secililiste("Yeni")
          }

          if (ne == 2) {
              secililiste("Yolda")
          }
          if (ne == 3) {
              secililiste("İptal")
          }
          if (ne == 4) {
              secililiste("Tamamlandı")
          }
  }

  if (hangi == 1) {
    if (ne == 0) {

        const fetchData = async () => {

    


            try {
      
            
        
              const fetchData = async () => {
                const ggliste = [];
      
        
                const querySnapshot = await getDocs(
                  query(
                    collection(db, "gidecek_urun_kayit"),where("sahip","==",localStorage.getItem('depo')),orderBy("tariht","desc")
                  )
                ); 
              
                querySnapshot.forEach((doc) => {
        
      
                    const data = {
                      urun: doc.data().urun,
                      aciklama: doc.data().aciklama,
                      adet: doc.data().adet,
                      barkod: doc.data().barkod,
                      cesit: doc.data().cesit,
                      kaynak: doc.data().kaynak,
                      marka: doc.data().marka,
                      baslik: doc.data().baslik,
                      tarih: doc.data().tarih,
                      durum: doc.data().durum,
                      id: doc.id,
  
                      olusturan : "QUSER",

                      
          
                    };
                    gglistgosterim.push(data);
                    
                  
        
        
                });
              
                setgglist(ggliste)
              };  
              fetchData();
            
            }
            catch(error) {
              
            }
          
          };
      
          fetchData();
        
    }

    if (ne == 1) {
        secililistegiden("Yeni")
    }

    if (ne == 2) {
        secililistegiden("Yolda")
    }
    if (ne == 3) {
        secililistegiden("İptal")
    }
    if (ne == 4) {
        secililistegiden("Tamamlandı")
    }
}
  }; 

  const secmearaci = async (hangisi) => {
   
    sethangi(hangisi)
    if (hangisi == 0){
      setgglistgosterim(gglist)
    }

    if (hangisi == 1){
      setgglistgosterim(gglistgiden)
    }
   
};



const [tord, settord] = useState(0);
     
    useEffect(() => {
        const fetchData = async () => {
    
        
    
    
          try {
    
          
      
            const fetchData = async () => {
              const ggliste = [];
    
      
              const querySnapshot = await getDocs(
                query(
                  collection(db, "gelecek_urun_kayit"),where("sahip","==",localStorage.getItem('depo')),orderBy("tariht","desc")
                )
              ); 
            
              querySnapshot.forEach((doc) => {
      
    
                  const data = {
                    urun: doc.data().urun,
                    aciklama: doc.data().aciklama,
                    adet: doc.data().adet,
                    barkod: doc.data().barkod,
                    cesit: doc.data().cesit,
                    kaynak: doc.data().kaynak,
                    marka: doc.data().marka,
                    baslik: doc.data().baslik,
                    tarih: doc.data().tarih,
                    durum: doc.data().durum,
                    id: doc.id,


                    
        
                  };
                  ggliste.push(data);
                  
                
      
      
              });
             
              setgglist(ggliste)
              setgglistgosterim(ggliste)
            };  
            fetchData();
          
          }
          catch(error) {
            
          }
        
        };
    
        fetchData();
        
      }, []);

      useEffect(() => {
        const fetchData = async () => {
    
        
    
    
          try {
    
          
      
            const fetchData = async () => {
              const ggliste = [];
    
      
              const querySnapshot = await getDocs(
                query(
                  collection(db, "gidecek_urun_kayit"),where("sahip","==",localStorage.getItem('depo')),orderBy("tariht","desc")
                )
              ); 
            
              querySnapshot.forEach((doc) => {
      
    
                  const data = {
                    urun: doc.data().urun,
                    aciklama: doc.data().aciklama,
                    adet: doc.data().adet,
                    barkod: doc.data().barkod,
                    cesit: doc.data().cesit,
                    kaynak: doc.data().kaynak,
                    marka: doc.data().marka,
                    baslik: doc.data().baslik,
                    tarih: doc.data().tarih,
                    durum: doc.data().durum,
                    id: doc.id,


                    
        
                  };
                  ggliste.push(data);
                  
                
      
      
              });
             
              setgglistgiden(ggliste)
            };  
            fetchData();
          
          }
          catch(error) {
            
          }
        
        };
    
        fetchData();
        
      }, []);

      const secililiste = async (sorgu) => {
        const fetchData = async () => {
    
            setgglist([])
    
    
            try {
      
            
        
              const fetchData = async () => {
                const ggliste = [];
      
        
                const querySnapshot = await getDocs(
                    query(
                      collection(db, "gelecek_urun_kayit"),where("sahip","==",localStorage.getItem('depo')),
                      where("durum", "==", sorgu)
                    )
                  );

                querySnapshot.forEach((doc) => {
        
      
                    const data = {
                      urun: doc.data().urun,
                      aciklama: doc.data().aciklama,
                      adet: doc.data().adet,
                      barkod: doc.data().barkod,
                      cesit: doc.data().cesit,
                      kaynak: doc.data().kaynak,
                      marka: doc.data().marka,
                      baslik: doc.data().baslik,
                      tarih: doc.data().tarih,
                      durum: doc.data().durum,
                      id: doc.id,
  
  
                      
          
                    };
                    ggliste.push(data);
                    
                  
        
        
                });
               
                setgglistgosterim(ggliste)
              };  
              fetchData();
            
            }
            catch(error) {
              
            }
          
          };
      
          fetchData();
  };


  const secililistegiden = async (sorgu) => {
    const fetchData = async () => {

        setgglist([])


        try {
  
        
    
          const fetchData = async () => {
            const ggliste = [];
  
    
            const querySnapshot = await getDocs(
                query(
                  collection(db, "gidecek_urun_kayit"),where("sahip","==",localStorage.getItem('depo')),
                  where("durum", "==", sorgu)
                )
              );

            querySnapshot.forEach((doc) => {
    
  
                const data = {
                  urun: doc.data().urun,
                  aciklama: doc.data().aciklama,
                  adet: doc.data().adet,
                  barkod: doc.data().barkod,
                  cesit: doc.data().cesit,
                  kaynak: doc.data().kaynak,
                  marka: doc.data().marka,
                  baslik: doc.data().baslik,
                  tarih: doc.data().tarih,
                  durum: doc.data().durum,
                  id: doc.id,


                  
      
                };
                ggliste.push(data);
                
              
    
    
            });
           
            setgglistgosterim(ggliste)
          };  
          fetchData();
        
        }
        catch(error) {
          
        }
      
      };
  
      fetchData();
};


const [navvisible, setnavvisible] = useState(0);

const handleClick = () => {

  setnavvisible(navvisible === 1 ? 0 : 1);

};
const  navfunc = async (nere) => {
  
   
  navigate(nere);
};
const navigate = useNavigate();


const  godetay = async (nere) => {
         
  navigate("/panel/gelengiden/durum?id="+nere);
};

const [usern, setusern] = useState("");
  const [firman, setfirman] = useState("");

  const auth = getAuth();
  const user = auth.currentUser;
  useEffect(() => {
  
    const updatedkod = [];
  
    const fetchData = async () => {
      try {
        
  
    
      
      const querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("id", "==", user.uid)
        )
      );
  
      for (const doc of querySnapshot.docs) {
  
        setusern(doc.data().user)
        setfirman(doc.data().firma)
        
      }
    }
  
    catch (error) {
  
      
      
  
  }
  
      
  
    };
      
    fetchData();
  
  }, []);



if (arama === 0 ) {

  return (
    <div className={styles.gelengiden}>
  <Ymenuopen data="gelengiden"/>

      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div className={styles.menu01Parent}>
            
            <div className={styles.inputField}>
              <div className={styles.inputFieldBase}>
                <div className={styles.inputFieldBase}>
                  <div className={styles.input}>
                    <div className={styles.content}>
                      <img
                        className={styles.searchSmIcon}
                        alt=""
                        src="/searchsm.svg"
                      />


<div className={styles.text} onClick={(e) => {
                e.stopPropagation(); 
                handleAramaChange();
              }}>Ara</div>


                    </div>
                    <img
                      className={styles.helpIcon}
                      alt=""
                      src="/help-icon.svg"
                    />
                  </div>
                </div>
               
              </div>
            </div>
            
          </div>


          <div className={styles.tabMenu}>
            <div className={styles.frameContainer}>

{hangi === 0 ? 
              <div className={styles.profitParent}>
                <div className={styles.profit}>Gelecek Ürünler</div>
                <img
                  className={styles.selectorIcon}
                  alt=""
                  src="/selector.svg"
                />
              </div>
: <div className={styles.profitParent}  onClick={(e) => {
  e.stopPropagation(); 
  secmearaci(0);
}}>
<div className={styles.candidates}>Gelecek Ürünler</div>

</div> }
{hangi === 1 ? 

              <div className={styles.profitParent}>
                <div className={styles.profit}>Giden Ürünler</div>
                <img
                  className={styles.selectorIcon}
                  alt=""
                  src="/selector.svg"
                />
              </div> : <div className={styles.profitParent} onClick={(e) => {
  e.stopPropagation(); 
  secmearaci(1);
}} >
                <div className={styles.candidates}>Giden Ürünler</div>
               
              </div> }

            </div>
            <img className={styles.separatorIcon} alt="" src="/separator.svg" />
          </div>


          <div className={styles.add}>
            <div className={styles.btnSend}>
              <div className={styles.shape} />
            </div>
            <img
              className={styles.componenticonicPlus}
              alt=""
              src="/componenticonic-plus.svg"
            />
          </div>
        </div>
        
        <div className={styles.fundsListing}>
          <div className={styles.timeline}>
            <div className={styles.shape1} />
            <div className={styles.weeklyParent}>

              <div className={tord === 0 ? styles.weekly1 : styles.weekly} onClick={(e) => {
                e.stopPropagation(); 
                prest(0);
              }}>Tümü</div>
              <div className={tord === 1 ? styles.weekly1 : styles.weekly} onClick={(e) => {
                e.stopPropagation(); 
                prest(1);
              }}>Yeni</div>
              <div className={tord === 2 ? styles.weekly1 : styles.weekly} onClick={(e) => {
                e.stopPropagation(); 
                prest(2);
              }}>Yolda</div>
              <div className={tord === 3 ? styles.weekly1 : styles.weekly} onClick={(e) => {
                e.stopPropagation(); 
                prest(3);
              }}> İptal</div>
               <div className={tord === 4 ? styles.weekly1 : styles.weekly} onClick={(e) => {
                e.stopPropagation(); 
                prest(4);
              }}> Tamamlandı</div>
            </div>
          </div>
        </div>

        <div className={styles.relatedResultWrapper}>
          <div className={styles.relatedResult}>
            <div className={styles.frameParent1}>


            {gglistgosterim.map((item) => (

              <div key={item.tarih}  className={styles.newWorldFundParent} onClick={(e) => {
                e.stopPropagation(); 
                godetay(item.tarih);
              }}>
                <div className={styles.profit}>Kayıt Tarihi : {item.tarih}</div>
                <div className={styles.frameParent2}>
                  <div className={styles.frameParent3}>
                    <div className={styles.image8Parent}>
                      <img
                        className={styles.image8Icon}
                        alt=""
                        src="/wicon.png"
                      />
                      <div className={styles.newWorldFundGroup}>


                        {item.baslik !== "" ? 
                 <div className={styles.profit}>
                    {item.baslik}
                  </div>
                  :<div className={styles.profit}>
                    {hangi === 0 ? "Stok Girişi" : "Stok Çıkışı"}
                </div> }


                        <div className={styles.industries}>{item.kaynak}</div>
                      </div>
                    </div>
                   
                    <div className={styles.midRisk}>
                    {item.durum == "Yolda" ? <div className={styles.midRisk1}>{item.durum}</div> : null }
                    {item.durum == "İptal" ? <div className={styles.midRisk1iptal}>{item.durum}</div> : null }
                    {item.durum == "Tamamlandı" ? <div className={styles.midRisk1ok}>{item.durum}</div> : null }
                    {item.durum == "Oluşturuldu" ? <div className={styles.midRisk1new}>{item.durum}</div> : null }

                  </div>
                  </div>
                  <div className={styles.separator} />
                  <div className={styles.frameParent4}>
                    <div className={styles.yearParent}>
                      <div className={styles.year}>
                        <div className={styles.year1}>Ürün</div>
                        <div className={styles.div9}>{item.urun}</div>
                      </div>
                      <div className={styles.year}>
                        <div className={styles.year1}>Marka</div>
                        <div className={styles.div9}>{item.marka}</div>
                      </div>
                      <div className={styles.year}>
                        <div className={styles.year1}>Model</div>
                        <div className={styles.div9}>{item.cesit}</div>
                      </div>
                      <div className={styles.year}>
                        <div className={styles.year1}>Adet</div>
                        <div className={styles.div9}>{item.adet}</div>
                      </div>
                    </div>

                    {item.aciklama !== "" ? 
  <div className={styles.year8}>
    <div className={styles.year1}>Not</div>
    <div className={styles.div13}>
      {item.aciklama}
    </div>
  </div>
: null }

                  </div>
                </div>
              </div>

            ))}





            </div>


            
          </div>
        </div>
      </div>
    </div>
  );

            }

            else {
              return(
              <AramaSayfasi handleAramaChange={handleAramaChange}/>
              )
              }







};

export default Gelengiden;
