import styles from "./AnaSayfaWeb.module.css";
import stylespop from "./WebDepoekle.module.css";

import { initializeApp } from "firebase/app";
import { documentId, getDocs, getFirestore } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy , onSnapshot,doc,updateDoc} from "firebase/firestore"; 
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PieChart } from '@mui/x-charts/PieChart';
import navbar from "./nav";
import { BarChart } from '@mui/x-charts/BarChart';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Ymenuopen from "./acikmenu";
import Ymenukapali from "./kapalimenu";
import './PopupComponent.css';

import AramaSayfasi from "./AramaSayfasi";
const firebaseConfig = {
  apiKey: "AIzaSyBvuq7FyhGZ1OuPuYMDlxIvNx-ip_WVC8E",
  authDomain: "myinventory-73dc6.firebaseapp.com",
  projectId: "myinventory-73dc6",
  storageBucket: "myinventory-73dc6.appspot.com",
  messagingSenderId: "1009595674740",
  appId: "1:1009595674740:web:618105201f35104b27e121",
  measurementId: "G-F89WMPB355"
};



const AnaSayfaWeb = () => {


  const [showPopup, setShowPopup] = useState(false);
  const { t, i18n } = useTranslation();

  const togglePopup = () => {
    setdno(generateRandomString())

    setShowPopup(!showPopup);
  };


  function generateRandomString() {
    let result = '';
    const characters = 'ABCDEFGHJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const [dno, setdno] = useState("");
  
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth();
  const user = auth.currentUser;

  const [navvisible, setnavvisible] = useState(0);

  const [arama, setarama] = useState(0);

  const handleAramaChange = (newValue) => {

    setarama(arama === 1 ? 0 : 1);

   
  };

  const navigate = useNavigate();


    const [aramalistd, setaramalistd] = useState([]);
    const [aramacesit, setaramacesit] = useState(0);
    const [urunlistiki, seturunlistiki] = useState([]);
    const [urunlistikiarama, seturunlistikiarama] = useState([]);
    const [urunlistikiyeni, seturunlistikiyeni] = useState([]);
    const [gelecektoplam, setgelecektoplam] = useState(0);

    const [gidecekktoplambar, setgidecekktoplambar] = useState(0);
    const [gelecekktoplambar, setgelecekktoplambar] = useState(0);
    const [gelecekktoplambariki, setgelecekktoplambariki] = useState(0);

    const [barData, setbarData] = useState([]);
    const [testbir, settestbir] = useState([]);
    const [testiki, settestiki] = useState([]);
    const [testuc, settestuc] = useState([]);

    const [urunlist, seturunlist] = useState([]);
    const [toplamstok, settoplamstok] = useState(0);
    const toplamAdet = (liste) => Object.values(liste).reduce((toplam, adet) => toplam + adet, 0);
    const [catadet, setcatadet] = useState([]);

    function randomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
    
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
    
      return color;
    }

    const [dhangi, setdhangi] = useState(0);
  const [dadi, setdadi] = useState("");
  const [dkodu, setdkodu] = useState("");

    const handlePresscesitsec = (cesit,no) => {
      setaramacesit(no)
      if (cesit != 0) {
          stok_arama(cesit)
      }
      else {
          settestiki(testuc)



          




      }
      
    }; 
  
    const depokodlu = async () => {

   
      try {
        
  
        const querySnapshot = await getDocs(
          query(
            collection(db, "depolar"),where("kod","==",dkodu)
          )
        ); 
      
          try {
            if (querySnapshot.empty) {
              alert("Depo Kodunu Kontrol Edin !")

            }
            querySnapshot.forEach(async (doc2) => {
  
              const washingtonRef = doc(db, "depolar", doc2.id);
              console.log("test3",washingtonRef)
              await updateDoc(washingtonRef, {
                kullanicilar: [...doc2.data().kullanicilar, user.email],
                
                
              });
             
              localStorage.setItem('depo', dkodu);

              setShowPopup(!showPopup);

            });
          } catch (error) {
            console.error("Hata oluştu:", error);
          }
        
        
         
  
         
    
    
      } catch (error) {
        console.log("hata:",error)
      }
    }

    const depokayitben = async () => {

      const now = new Date();
      const formattedDateTime = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`;
      try {
        const docRef = await addDoc(collection(db, "depolar"), {
          kullanicilar: [user.email],
          sahip:(user.email),
          kod : dno,
          depo: dadi,
          
          
          
        });
        setShowPopup(!showPopup);
        localStorage.setItem('depo', dno);


  
  
    
      } catch (error) {
        console.log("hata:",error)
      }
    }
  const [sliceColor, setsliceColor] = useState([]);
  const [sliceColoriki, setsliceColoriki] = useState([]);
  const [sliceColorikiyeni, setsliceColorikiyeni] = useState([]);

  const widthAndHeight = 240
 
  const [seri, setseri] = useState([]);
  const [seriiki, setseriiki] = useState([]);
  const [seriikiyeni, setseriikiyeni] = useState([]);

  const [tedarikcilist, settedarikcilist] = useState([]);

const tedarikciekle = async () => {
 
    navigate('/panel/tedarikciler');
};

const musteriekle = async () => {
 
  navigate('/panel/musteriler');
};

useEffect(() => {
  
  ds2();
  ds3();
  ds4();
  ds5();
  ds6();
  ds7();

  verigetir();

}, []);

const handleClick = () => {

    setnavvisible(navvisible === 1 ? 0 : 1);

  };





const handledeposec = (nere) => {
 
  const fetchData = async () => {

    console.log(nere)
    setsecilidepo(nere)
    localStorage.setItem('depo', nere);
    console.log("budog:",nere)
    ds2();
    ds3();
    ds4();
    ds5();
    ds6();
    ds7();

    verigetir();

  }

  fetchData();

  
  

  };

  

const ara = async () => {
   
    
  };
  
const  navfunc = async (nere) => {
     
    navigate(nere);
  };
  
  const gomusteriler = async () => {
     
    navigate('/panel/musteriler');
  };




  const [usern, setusern] = useState("");
  const [firman, setfirman] = useState("");


    const ds7 = async () => {

    const updatedkod = [];
  
    const fetchData = async () => {
      console.log(auth.currentUser)
      try {
        getdepo((auth.currentUser).email)

      }
      catch (error) {
  
        console.log(error)
    
    }

      try {
        
  
    
      
      const querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("id", "==", user.uid)
        )
      );
  
      for (const doc of querySnapshot.docs) {
  
        setusern(doc.data().user)
        setfirman(doc.data().firma)
        getdepo((auth.currentUser).email)
        
      }
    }
  
    catch (error) {
  
      console.log(error)
  
  }
  
      
  
    };
      
    fetchData();
  
  };










    const ds6 = async () => {

    const fetchData = async () => {
  
     

      
  
      
  
        try {
            const fetchData = async () => {
              const gelecek = [];
              const gidecek = [];
              const gidecekxx = 0 ;
  
              setgelecekktoplambar(0)
              setgidecekktoplambar(0)
              const gelecekdatatutucu = [];
              const colortutucu = [];
              const colortutucuiki = [];
              const seritutucuiki = [];
  
  
             

              const querySnapshot3 = await getDocs(query(collection(db, "urunler"),where("sahip","==",localStorage.getItem('depo'))));
              
          
              querySnapshot3.forEach((doc2) => {
                  
                  setgidecekktoplambar((prevValue) => prevValue + doc2.data().cikan);
                 
          
              
              });

              const querySnapshot6 = await getDocs(query(collection(db, "gidecek_urun_kayit"),where("sahip","==",localStorage.getItem('depo')),where("ok","!=",2)));
              
          
              querySnapshot6.forEach((doc2) => {
                  
                  setgidecekktoplambar((prevValue) => prevValue + doc2.data().adet);
                 
          
              
              });
  
  
              const querySnapshot = await getDocs(query(collection(db, "gelecek_urun_kayit"),where("sahip","==",localStorage.getItem('depo')),where("Durun","!=","Tamamlandı")));
              
          
              querySnapshot.forEach((doc) => {
                setgelecekktoplambar((prevValue) => prevValue + doc.data().adet);
  
              
              });

              
          
            
 
          
              
  
  
  
  
  
              
             
              setbarData([{
                value: gelecekktoplambar,
                label: "Mart",
                spacing: 2,
                labelWidth: 30,
                labelTextStyle: {color: 'gray'},
                frontColor: '#FFCD38',
              },
              {value: gidecekktoplambar, frontColor: '#3cd598'}])
              
  
  
            };
          
            fetchData();
  
            setbarData([{
              value: gelecekktoplambar,
              label: "Mart",
              spacing: 2,
              labelWidth: 30,
              labelTextStyle: {color: 'gray'},
              frontColor: '#FFCD38',
            },
            {value: gidecekktoplambar, frontColor: '#3cd598'}])
          } catch (error) {
          }
      
      };
  
      fetchData();
  
  
  };


        const ds5 = async () => {

        const fetchData = async () => {
    
        
    
    
          try {
    
          
      
            const fetchData = async () => {
              const tedarikciliste = [];
    
      
              const querySnapshot = await getDocs(
                query(
                  collection(db, "tedarikci_listesi"),where("sahip","==",localStorage.getItem('depo')),orderBy("tariht","desc")
                )
              ); 
            
              querySnapshot.forEach((doc) => {
      
    
                  const data = {
                    tedarikci: doc.data().tedarikci,
                    email: doc.data().email,
                    firma: doc.data().firma,
                    telefon: doc.data().telefon,
                    not: doc.data().not,
  
  
                    
        
                  };
                  tedarikciliste.push(data);
                  
                
      
      
              });
             
              settedarikcilist(tedarikciliste)
            };  
            fetchData();
          
          }
          catch(error) {
            
          }
        
        };
    
        fetchData();
        
      };
  
  
  
     
  
  
        const ds4 = async () => {

          const fetchData = async () => {
            setgelecektoplam(0);
        
            try {
              const querySnapshot = await getDocs(
                query(collection(db, "gelecek_urun_kayit"),where("sahip","==",localStorage.getItem('depo')), where("durum", "!=", "Tamamlandı"))
              );
        
              querySnapshot.forEach((doc) => {
                setgelecektoplam((prevValue) => prevValue + doc.data().adet);
              });
        
            } catch (error) {
              
            }
          };
        
          fetchData();
        };
        
  
       
  
        var xx = [
          { value: 10, color: 'orange' },
          // Diğer veriler...
        ];
        
        // Verilerinizi dolduracağınız data dizisi
       

     

        const stok_arama = (cesit) => {

        
          const fetchData = async () => {
  
              seturunlistiki([])
              setsliceColoriki([])
  
              setseriiki([])
              var dataiki = []  

              try {
                  const fetchData = async () => {
                    const totalStockByIsim = {};
  
                    const colortutucuiki = [];
                    const seritutucuiki = [];
                    const cesitliste = [];

                    const querySnapshot = await getDocs(query(collection(db, "urunler"),where("sahip","==",localStorage.getItem('depo')),where("cesit","==",cesit)));
                    
                    const totalStockByCategory = {};
  
                    querySnapshot.forEach((doc) => {
                      const data = {
                        urun: doc.data().urun,
                        aciklama: doc.data().aciklama,
                        adet: doc.data().adet,
                        barkod: doc.data().barkod,
                        cesit: doc.data().cesit,
                        kaynak: doc.data().kaynak,
                        marka: doc.data().marka,
                      };
                
                      if (!totalStockByIsim[data.urun]) {
                        totalStockByIsim[data.urun] = data.adet;
    
                      } else {
                        totalStockByIsim[data.urun] += data.adet;
    
                      }
    
                      
                      
                      cesitliste.push(data);
                    });
                    console.log(totalStockByIsim)
                    const keys = Object.keys(totalStockByIsim);
  
                    keys.forEach(key => {
                      const value = totalStockByIsim[key];
                      seritutucuiki.push(value)
                      colortutucuiki.push(randomColor())
                      dataiki.push({value:value,color:randomColor(),label:key+" ("+value+") Adet"})

  
                    });
                    seturunlistiki(cesitliste); //
                    settestiki(dataiki)

                    setuadet(totalStockByIsim)
                    
                    
  
                    setsliceColoriki(colortutucuiki) //
  
                    setseriiki(seritutucuiki) //
                  
  
  
                  };
                
                  fetchData();
                } catch (error) {
                  console.log("hata:", error);
                }
            
            };
  
            fetchData()
  
  
  
        }; 
        const [isMenuVisible, setMenuVisible] = useState(false);
  
   
    const [musterilist, setmusterilist] = useState([]);
    const [uadetx, setuadetx] = useState([]);

    const ds3 = async () => {

      const fetchData = async () => {
  
      
  
  
        try {
  
        
    
          const fetchData = async () => {
            const musteriliste = [];
  
    
            const querySnapshot = await getDocs(
              query(
                collection(db, "musteri_listesi"),where("sahip","==",localStorage.getItem('depo')),orderBy("tariht","desc")
              )
            ); 
          
            querySnapshot.forEach((doc) => {
    
  
                const data = {
                  musteri: doc.data().musteri,
                  email: doc.data().email,
                  firma: doc.data().firma,
                  telefon: doc.data().telefon,
                  not: doc.data().not,
  
  
                  
      
                };
                musteriliste.push(data);
                
              
    
    
            });
           
            setmusterilist(musteriliste)
          };  
          fetchData();
        
        }
        catch(error) {
          
        }
      
      };
  
      fetchData();
      
    };
  
   



    const [depolist, setdepolist] = useState([]);
    const [secilidepo, setsecilidepo] = useState("");



      const getdepo = async (useri) => {
  
       
  
  
        try {
          console.log(useri)
  
            const q = query(collection(db, "depolar"),where("kullanicilar","array-contains",useri));
  
              const unsubscribe = onSnapshot(q, (querySnapshot) => {
                setdepolist([])
                var data = [];
                var dataiki = [];
                const depoliste = [];


                querySnapshot.forEach((doc) => {
  
                const data = {
                  kullanicilar: doc.data().kullanicilar,
                  kod : doc.data().kod,
                  depo: doc.data().depo,
    
                };
                depoliste.push(data);
                
              });            
  
              setdepolist(depoliste)

              console.log(data)
  
    
            });
  
           
            
  
  
            
           
         
  
  
        
        }
        catch(error) {
          console.log("hata2:",error)
        }
      
      };
  
      
      const [gidecekktoplambarx, setgidecekktoplambarx] = useState(0);
      const [uadet, setuadet] = useState([]);
      const [catadetx, setcatadetx] = useState([]);
      const [tutucu, settutucu] = useState([]);



      const ds2 = async () => {

        const fetchData = async () => {


         




            seturunlist([])

            settoplamstok(0)
    



            setbarData([{
              value: gelecekktoplambar,
              label: "Mart",
              spacing: 2,
              labelWidth: 30,
              labelTextStyle: {color: 'gray'},
              frontColor: '#FFCD38',
            },
            {value: gidecekktoplambar+gidecekktoplambarx, frontColor: '#3cd598'}])



    
            try {
                const fetchData = async () => {
                  const cesitliste = [];
                  const seritutucu = [];
                  const colortutucu = [];
                  const colortutucuiki = [];
                  const seritutucuiki = [];

                  var data = [];
                  var dataiki = [];
                  const q5= query(collection(db, "urunler"),where("sahip","==",localStorage.getItem('depo')));


                  const unsubscribe5 = onSnapshot(q5, (querySnapshot5) => {
                    const cesitliste = [];
                  const seritutucu = [];
                  const colortutucu = [];
                  const colortutucuiki = [];
                  const seritutucuiki = [];

                  
                  const totalStockByCategory = {};

                  const totalStockByIsim = {};

              
                  querySnapshot5.forEach((doc) => {
                    const data = {
                      urun: doc.data().urun,
                      aciklama: doc.data().aciklama,
                      adet: doc.data().adet,
                      barkod: doc.data().barkod,
                      cesit: doc.data().cesit,
                      kaynak: doc.data().kaynak,
                      marka: doc.data().marka,
                    };
              
                    if (data.adet > 0) {
                    if (!totalStockByCategory[data.cesit]) {
                      totalStockByCategory[data.cesit] = data.adet;
                      if (!totalStockByIsim[data.urun]) {
                        totalStockByIsim[data.urun] = data.adet;
                        
  
                      } else {
                        totalStockByIsim[data.urun] += data.adet;
  
                      }

                    } else {
                      totalStockByCategory[data.cesit] += data.adet;
                      if (!totalStockByIsim[data.urun]) {
                        totalStockByIsim[data.urun] = data.adet;
  
                      } else {
                        totalStockByIsim[data.urun] += data.adet;
  
                      }

                    }
                  }
                    cesitliste.push(data);
                  });
              
                  console.log(totalStockByIsim)
                  setuadet(totalStockByIsim)  
                  setuadetx(totalStockByIsim) //bu2

                  setcatadet(totalStockByCategory)  
                  setcatadetx(totalStockByCategory) //bu1
                  seturunlist(cesitliste);
                  
                  for (const x in totalStockByCategory) {
                    seritutucu.push(totalStockByCategory[x])
                    colortutucu.push(randomColor())
                    data.push({value:totalStockByCategory[x],color:randomColor(),label:x+"("+totalStockByCategory[x]+")"})

                  }

                  settestbir(data)

                  
                  const keys = Object.keys(totalStockByIsim);

                  keys.forEach(key => {
                    const value = totalStockByIsim[key];
                    seritutucuiki.push(value)
                    colortutucuiki.push(randomColor())

                  });


                  setsliceColor(colortutucu)
                  seturunlistiki(cesitliste)
                  seturunlistikiyeni(cesitliste)
                  setseri(seritutucu)

                  setsliceColoriki(colortutucuiki)

                  setseriiki(seritutucuiki)
                
                  setsliceColorikiyeni(colortutucuiki)
                  settutucu(seritutucuiki)

                  setseriikiyeni(seritutucuiki)


                });
                };
                
              
                fetchData();
              } catch (error) {
                console.log("hata:", error);
              }
          
          };

          fetchData();
        };




    //ds1
    const verigetir = async () => {
      var data = [];
      var dataiki = [];
      const fetchDataiki = async () => {


        setgelecekktoplambariki(0)

        const querySnapshot = await getDocs(
          query(
            collection(db, "urunler"),where("sahip","==",localStorage.getItem('depo'))
          )
        ); 
      
        querySnapshot.forEach((doc) => {


            
            setgelecekktoplambariki((prevValue) => prevValue + doc.data().giren);

          


        });




      seturunlist([])

      settoplamstok(0)




      setbarData([{
        value: gelecekktoplambar,
        label: "Mart",
        spacing: 2,
        labelWidth: 30,
        labelTextStyle: {color: 'gray'},
        frontColor: '#FFCD38',
      },
      {value: gidecekktoplambar+gidecekktoplambarx, frontColor: '#3cd598'}])




      try {
          const fetchData = async () => {
            const cesitliste = [];
            const seritutucu = [];
            const colortutucu = [];
            const colortutucuiki = [];
            const seritutucuiki = [];


            const q5= query(collection(db, "urunler"),where("sahip","==",localStorage.getItem('depo')));


            const unsubscribe5 = onSnapshot(q5, (querySnapshot5) => {
              const cesitliste = [];
            const seritutucu = [];
            const colortutucu = [];
            const colortutucuiki = [];
            const seritutucuiki = [];

            
            const totalStockByCategory = {};

            const totalStockByIsim = {};

        
            querySnapshot5.forEach((doc) => {
              const data = {
                urun: doc.data().urun,
                aciklama: doc.data().aciklama,
                adet: doc.data().adet,
                barkod: doc.data().barkod,
                cesit: doc.data().cesit,
                kaynak: doc.data().kaynak,
                marka: doc.data().marka,
              };
        
              if (data.adet > 0) {
              if (!totalStockByCategory[data.cesit]) {
                totalStockByCategory[data.cesit] = data.adet;
                if (!totalStockByIsim[data.urun]) {
                  totalStockByIsim[data.urun] = data.adet;
                  

                } else {
                  totalStockByIsim[data.urun] += data.adet;

                }

              } else {
                totalStockByCategory[data.cesit] += data.adet;
                if (!totalStockByIsim[data.urun]) {
                  totalStockByIsim[data.urun] = data.adet;

                } else {
                  totalStockByIsim[data.urun] += data.adet;

                }

              }
            }
              cesitliste.push(data);
            });
        
            console.log(totalStockByIsim)
            setuadet(totalStockByIsim)  
            setuadetx(totalStockByIsim) //bu2

            setcatadet(totalStockByCategory)  
            setcatadetx(totalStockByCategory) //bu1
            seturunlist(cesitliste);
            
            for (const x in totalStockByCategory) {
              seritutucu.push(totalStockByCategory[x])
              colortutucu.push(randomColor())
              data.push({value:totalStockByCategory[x],color:randomColor(),label:x+"("+totalStockByCategory[x]+")"})

            }

            settestbir(data)


            
            const keys = Object.keys(totalStockByIsim);

            keys.forEach(key => {
              const value = totalStockByIsim[key];
              seritutucuiki.push(value)
              colortutucuiki.push(randomColor())
              dataiki.push({value:value,color:randomColor(),label:key+" ("+value+") Adet"})

            });

            settestuc(dataiki)
            settestiki(dataiki)
            setsliceColor(colortutucu)
            seturunlistiki(cesitliste)
            seturunlistikiyeni(cesitliste)
            setseri(seritutucu)

            setsliceColoriki(colortutucuiki)

            setseriiki(seritutucuiki)
          
            setsliceColorikiyeni(colortutucuiki)
            settutucu(seritutucuiki)

            setseriikiyeni(seritutucuiki)


          });
          };
          
        
          fetchData();
        } catch (error) {
          console.log("hata:", error);
        }
      };

      fetchDataiki();
    
    };

    



    const closePopup = () => {
      setShowPopup(false);
    };
  

    if (arama === 0 ) {


  return (
    
    <div className={styles.anaSayfaWeb}>
 
  <Ymenuopen data="anaSayfa"/>

  {showPopup == true ?
        <div className="popup-overlay"  onClick={closePopup}>
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <div className="popup-inner">
              
              


              <div className={stylespop.webDepoekle}>
      <div className={stylespop.tabMenuParent}>
        <div className={stylespop.tabMenu}>
          <div className={stylespop.frameParent}>
            <div className={dhangi == 0 ? stylespop.profitParent :stylespop.profitGroup }  onClick={(e) => {
                e.stopPropagation(); 
                setdhangi(0);
              }}>
              <div className={dhangi == 0 ? stylespop.profit : stylespop.profit }>{t('yeniolustur')}</div>
              {dhangi == 0 ? 
              <img
                className={stylespop.selectorIcon}
                alt=""
                src="/selector1.svg"
              />
              : null }
            </div>
            <div className={dhangi == 1 ? stylespop.profitParent :stylespop.profitGroup }  onClick={(e) => {
                e.stopPropagation(); 
                setdhangi(1);
              }}>
              <div className={dhangi == 1 ? stylespop.profit : stylespop.profit }>{t('davetkodukullan')}</div>
              {dhangi == 1 ? 
              <img
                className={stylespop.selectorIcon}
                alt=""
                src="/selector1.svg"
              />
               : null }
            </div>
          </div>
          <img className={stylespop.separatorIcon} alt="" src="/separator2.svg" />
        </div>




{dhangi == 0 ?
        <div className={stylespop.password}>
          <div className={stylespop.password1}>
            <div className={stylespop.labelParent}>
              <div className={stylespop.label}>
                <div className={stylespop.smallLabel}>{t('depoadi')} : </div>
              </div>
              <div className={stylespop.container}>
                <div className={stylespop.placeholderWrapper}>
                  <div className={stylespop.placeholderWrapper}>
                    <input
          className={stylespop.smallLabel}
          placeholder={t('depoadi')}
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setdadi(e.target.value);
          }}
        /> 
                  </div>
                </div>
              </div>
              <div className={stylespop.label1}>
                <div className={stylespop.smallLabel1}>altına düşerse bildir.</div>
              </div>
            </div>
            <div className={stylespop.labelParent}>
              <div className={stylespop.label}>
                <div className={stylespop.smallLabel}>{`Depo Davet Kodu : `}</div>
              </div>
              <div className={stylespop.container1}>
                <div className={stylespop.placeholderWrapper}>
                  <div className={stylespop.placeholderWrapper}>
                    <div className={stylespop.smallLabel}>{dno}</div>
                  </div>
                </div>
              </div>
              <div className={stylespop.label1}>
                <div className={stylespop.smallLabel1}>altına düşerse bildir.</div>
              </div>
            </div>
          </div>
          <div className={stylespop.ctaButton} onClick={(e) => {
                e.stopPropagation(); 
                depokayitben();
              }}>
            <div className={stylespop.placeholderWrapper}>
              <div className={stylespop.xlLabel}>{t('olustur')}</div>
            </div>
          </div>
        </div>

:
        <div className={stylespop.password2}>
          <div className={stylespop.password3}>
            <div className={stylespop.labelParent}>
              <div className={stylespop.label}>
                <div className={stylespop.smallLabel}>{t('davetkodu')}:</div>
              </div>
              <div className={stylespop.container2}>
                <div className={stylespop.placeholderWrapper}>
                  <div className={stylespop.placeholderWrapper}>
                    <input
          className={stylespop.smallLabel}
          placeholder={t('davetkodu')}
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setdkodu(e.target.value);
          }}
        /> 
                  </div>
                </div>
              </div>
              <div className={stylespop.label1}>
                <div className={stylespop.smallLabel1}>altına düşerse bildir.</div>
              </div>
            </div>
          </div>
          <div className={stylespop.ctaButton} onClick={(e) => {
                e.stopPropagation(); 
                depokodlu();
              }}>
            <div className={stylespop.placeholderWrapper}>
              <div className={stylespop.xlLabel}>{t('katil')}</div>
            </div>
          </div>
        </div>

}


      </div>
    </div>







            </div>
          </div>
        </div>
      : null }

      <div className={styles.instanceParent}>
        <div className={styles.menu01Parent}>


                  
          <div className={styles.inputField}>
            <div className={styles.inputFieldBase}>
              <div className={styles.inputFieldBase}>
                <div className={styles.label}>Email</div>
                <div className={styles.input}>
                  <div className={styles.content}>
                    <img
                      className={styles.searchSmIcon}
                      alt=""
                      src="/searchsm.svg"
                    />
                    <div className={styles.text} onClick={(e) => {
                e.stopPropagation(); 
                handleAramaChange();
              }}>Ara</div>
                  </div>
                  <img
                    className={styles.helpIcon}
                    alt=""
                    src="/help-icon.svg"
                  />
                </div>
              </div>
              
            </div>
          </div>
          
        </div>


        <div className={styles.overviewParent}>
          <div className={styles.overviewx}>
            <div className={styles.overview1x}>{t('depolarim')} ({depolist.length})</div>
          </div>
          <div className={styles.overview2x}>
            <div className={styles.purchaseParentx}>
              


              {depolist.map((item) => (


              <div className={localStorage.getItem('depo') == item.kod ? styles.purchasex : styles.purchase2x} onClick={(e) => {
                
                handledeposec(item.kod);
              }}>
                <div className={styles.isabellaMoraxn}>{item.depo}</div>
              </div>


              ))}


              <div className={styles.purchase4x} onClick={(e) => {
                
                togglePopup();
              }}>
                <div className={styles.isabellaMoranx}>+ {t('yenidepo')}</div>
              </div>
            </div>
          </div>
        </div>


        <div className={styles.overview}>
          <div className={styles.group}>
            <div className={styles.show}>
              <div className={styles.showThisYearParent}>
                <div className={styles.showThisYearContainer}>
                  <span>{t('goster')}:</span>
                  <span className={styles.span}>{` `}</span>
                  <span className={styles.buAy}>{t('tümü')}</span>
                </div>
                <img
                  className={styles.icDropdownIcon}
                  alt=""
                  src="/ic-dropdown.svg"
                />
              </div>
            </div>
            <div className={styles.overview1}>{t('ozet')}</div>
          </div>
          
        </div>
        <div className={styles.frameParent}>
          <div className={styles.frameGroup}>
            <div className={styles.purchaseParent}>
              <div className={styles.purchase}>
                <div className={styles.mask} />
                <div className={styles.comparedTo19000Container}>
                  <p className={styles.art}>{t('artis')}</p>
                  <p className={styles.art}>(0) {t('adet')}</p>
                </div>
                <div className={styles.div9}>{toplamAdet(catadet)}</div>
                <div className={styles.parent}>
                  <div className={styles.div10}>+0.0%</div>
                  <img
                    className={styles.icDownIcon}
                    alt=""
                    src="/ic-down.svg"
                  />
                </div>
                <div className={styles.purchase1}>{t('stok')}({t('adet')})</div>
              </div>
              <div className={styles.purchase}>
                <div className={styles.mask} />
                <div className={styles.comparedTo19000Container}>
                  <p className={styles.art}>{t('artis')}</p>
                  <p className={styles.art}>(000)</p>
                </div>
                <div className={styles.div11}>{gidecekktoplambar}</div>
                <div className={styles.parent}>
                  <div className={styles.div10}>+0.0%</div>
                  <img
                    className={styles.icDownIcon}
                    alt=""
                    src="/ic-down.svg"
                  />
                </div>
                <div className={styles.sales1}>{t('toplamcikis')}</div>
              </div>
            </div>

            
            <div className={styles.frameContainer}>
              <div className={styles.returnWrapper}>
                <div className={styles.purchase}>
                  <div className={styles.mask} />
                  <div className={styles.comparedTo165}>{t('azalis')} (000)</div>
                  <div className={styles.div13}>{gelecekktoplambariki}</div>
                  <div className={styles.parent2}>
                    <div className={styles.div10}>-0.0%</div>
                    <img
                      className={styles.icDownIcon}
                      alt=""
                      src="/ic-down1.svg"
                    />
                  </div>
                  <div className={styles.return1}>Toplam Giriş</div>
                </div>
              </div>
              <div className={styles.returnWrapper}>
                <div className={styles.purchase}>
                  <div className={styles.mask} />
                  <div className={styles.comparedTo165}>Azalış (000)</div>
                  <div className={styles.div13}>{gelecekktoplambar}</div>
                  <div className={styles.parent2}>
                    <div className={styles.div10}>-0.0%</div>
                    <img
                      className={styles.icDownIcon}
                      alt=""
                      src="/ic-down1.svg"
                    />
                  </div>
                  <div className={styles.return1}>{t('gelecekurunadeti')}</div>
                </div>
              </div>
            </div>


          </div>
          <div className={styles.parent3}>
            <div className={styles.isabellaMoran}>{t('genelstok')}</div>
            <div className={styles.chartiki}>
             
             
            <PieChart
  width={800}
  height={200}
  series={[
    {
      data: testbir,
    },
  ]}
/>



            </div>
            


          


          </div>
          <div className={styles.parent4}>
            <div className={styles.div18}>{t('cesiticistok')}</div>
            <div className={styles.tmParent}>
              <div className={aramacesit == 0 ? styles.weekly :styles.tm} onClick={(e) => {
                  e.stopPropagation(); 
                 handlePresscesitsec(0,0);
                }}>Tümü</div>
              

              {Object.keys(catadet).map((key, index) => {
        const item = catadet[key];
        return (
          

<div key={key} className={aramacesit == index+1 ? styles.weekly :styles.tm} onClick={(e) => {
                  e.stopPropagation(); 
                 handlePresscesitsec(key,index+1);
                }}>{key}</div>
        );
      })}

            </div>
            <div className={styles.chartx}>
             
            <PieChart
  width={1200}
  height={400}
  series={[
    {
      data: testiki,
    },
  ]}
/>




            </div>
           
        








          </div>
          <div className={styles.salesReport}>
            <div className={styles.month}>

              
              <div className={styles.mask} />
              

              <div className={styles.barmask} >

              <BarChart
  xAxis={[{ scaleType: 'band', data: ['Ocak','Şubat','Mart','Nisan','Mayıs','Haziran','Temmuz','Ağustos','Eylül','Ekim','Kasım','Aralık'] }]}
  series={[{ data: [0, 0, gelecekktoplambariki ], color: '#FFCD38',label:"Depoya Giren" },
  { data: [0, 0, gidecekktoplambar], color: '#3DD598',label:"Depodan Çıkan" } ]}
  width={1200}
  height={300}
/>
</div>


            </div>


            
            <div className={styles.salesReport1}>{t('durumozet')}</div>
          </div>
          <div className={styles.frameParent1}>
            <div className={styles.newCustomersParent}>
              <div className={styles.newCustomers}>{t('musteriler')}</div>
              <div className={styles.viewMoreCustomers} onClick={(e) => {
                  e.stopPropagation(); 
                 musteriekle();
                }}>{t('tumunugor')}</div>
            </div>
            <div className={styles.frameParent2}>
              

            {musterilist.map((item) => (

              <div key={item.musteri} className={styles.customer}>
                <div className={styles.ppParent}>
                  <img className={styles.ppIcon} alt="" src="/pp@2x.png" />
                  <div className={styles.isabellaMoranParent}>
                    <div className={styles.isabellaMoran}>{item.firma} </div>
                    <div className={styles.customerId00222}>
                      {item.musteri} / {item.telefon} 
                    </div>
                  </div>
                  <img
                    className={styles.homePageIcon}
                    alt=""
                    src="/phone@2x.png"
                  />
                </div>
              </div>

))}



              
              <div className={styles.viewMoreCustomers1} onClick={(e) => {
                  e.stopPropagation(); 
                 musteriekle();
                }}>{t('musteriekle')}</div>
            </div>
          </div>
          <div className={styles.frameParent1}>
            <div className={styles.newCustomersParent}>
              <div className={styles.newCustomers}>{t('tedarikciler')}</div>
              <div className={styles.viewMoreCustomers} onClick={(e) => {
                  e.stopPropagation(); 
                 tedarikciekle();
                }}>{t('tumunugor')}</div>
            </div>
            <div className={styles.frameParent2}>
             

            {tedarikcilist.map((item) => (

              <div key={item.tedarikci} className={styles.customer}>
                <div className={styles.ppParent}>
                  <img className={styles.ppIcon} alt="" src="/pp@2x.png" />
                  <div className={styles.isabellaMoranParent}>
                    <div className={styles.isabellaMoran}>{item.firma}</div>
                    <div className={styles.customerId00222}>
                      {item.tedarikci} / {item.telefon}
                    </div>
                  </div>
                  <img
                    className={styles.homePageIcon}
                    alt=""
                    src="/phone@2x.png"
                  />
                </div>
              </div>
                        ))}



              <div className={styles.viewMoreCustomers1} onClick={(e) => {
                  e.stopPropagation(); 
                 tedarikciekle();
                }}>{t('tedarikciekle')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
else {
return(
<AramaSayfasi handleAramaChange={handleAramaChange}/>
)
}






};

export default AnaSayfaWeb;
