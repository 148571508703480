import styles from "./Exceldengecis.module.css";
import React, { useState } from 'react';

import * as XLSX from 'xlsx';
import { initializeApp } from "firebase/app";
import { getDocs, getFirestore } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy} from "firebase/firestore"; 
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Link, useNavigate } from 'react-router-dom';
import Ymenuopen from "./acikmenu";
const firebaseConfig = {
  apiKey: "AIzaSyBvuq7FyhGZ1OuPuYMDlxIvNx-ip_WVC8E",
  authDomain: "myinventory-73dc6.firebaseapp.com",
  projectId: "myinventory-73dc6",
  storageBucket: "myinventory-73dc6.appspot.com",
  messagingSenderId: "1009595674740",
  appId: "1:1009595674740:web:618105201f35104b27e121",
  measurementId: "G-F89WMPB355"
};
const Exceldengecis = () => {
  const navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth();
  
  const [basliklist, setbasliklist] = useState([]);
  const [Veriler, setVeriler] = useState([]);
  const [VeriSayilari, setVeriSayilari] = useState(0);

  const [sayfaadlari, setsayfaadlari] = useState([]);

  const [secilisayfa, setsecilisayfa] = useState(0);


  const [seciliad, setseciliad] = useState([]);
  const [seciliadet, setseciliadet] = useState([]);
  const [secilicesit, setsecilicesit] = useState([]);
  const [secilikaynak, setsecilikaynak] = useState([]);
  const [secilimarka, setsecilimarka] = useState([]);
  const [secilibarkod, setsecilibarkod] = useState([]);
  const [seciliaciklama, setseciliaciklama] = useState([]);

  const [seciliadb, setseciliadb] = useState([]);
  const [seciliadetb, setseciliadetb] = useState([]);
  const [secilicesitb, setsecilicesitb] = useState([]);
  const [secilikaynakb, setsecilikaynakb] = useState([]);
  const [secilimarkab, setsecilimarkab] = useState([]);
  const [secilibarkodb, setsecilibarkodb] = useState([]);
  const [seciliaciklamab, setseciliaciklamab] = useState([]);


  const [advisible, setadvisible] = useState(0);
  const [adetvisible, setadetvisible] = useState(0);
  const [cesitvisible, setcesitvisible] = useState(0);
  const [kaynakvisible, setkaynakvisible] = useState(0);
  const [markavisible, setmarkavisible] = useState(0);
  const [barkodvisible, setbarkodvisible] = useState(0);
  const [aciklamavisible, setaciklamavisible] = useState(0);


  const [testt, settestt] = useState([]);



  const [yerim, setyerim] = useState(0);

  const adsecme = (isim) => {
    setseciliad(Veriler[isim])
    setseciliadb(isim)

    setadvisible(1)
  };  

  const adetsecme = (isim) => {
    if (isim == 1 ) {

      setseciliadetb(isim)
      setseciliadet(Array(VeriSayilari).fill(1))
      setadetvisible(1)


    }
    else {
      setseciliadet(Veriler[isim])
      setadetvisible(1)
      setseciliadetb(isim)

    }
    
  };  

  const aciklamasecme = (isim) => {
    if (isim == "-" ) {

      setseciliaciklamab(isim)
      setseciliaciklama(Array(VeriSayilari).fill("-"))
      setaciklamavisible(1)


    }
    else {
      setseciliaciklama(Veriler[isim])
      setaciklamavisible(1)
      setseciliaciklamab(isim)

    }
    
  }; 

  const cesitsecme = (isim) => {
    if (isim == "-" ) {
      setsecilicesitb(isim)

      setsecilicesit(Array(VeriSayilari).fill("-"))
      setcesitvisible(1)
    }

    else {
      setsecilicesitb(isim)

      setsecilicesit(Veriler[isim])
      setcesitvisible(1)
    }
  };  

  const kaynaksecme = (isim) => {

    if (isim == "-" ) {
      setsecilikaynakb(isim)

  setsecilikaynak(Array(VeriSayilari).fill("-"))
    setkaynakvisible(1)
 }
 else {
    setsecilikaynak(Veriler[isim])
    setkaynakvisible(1)
    setsecilikaynakb(isim)

 }
  };  

  const markasecme = (isim) => {
    if (isim == "-" ) {
      setsecilimarkab(isim)

    setsecilimarka(Array(VeriSayilari).fill("-"))
    setmarkavisible(1)
    }
    else {
      setsecilimarkab(isim)

      setsecilimarka(Veriler[isim])
    setmarkavisible(1)
    }
  };  

  const barkodsecme = (isim) => {
    if (isim == "-" ) {
      setsecilibarkodb(isim)

    setsecilibarkod(Array(VeriSayilari).fill("-"))
    setbarkodvisible(1)
    }
    else {
      setsecilibarkodb(isim)

      setsecilibarkod(Veriler[isim])
      setbarkodvisible(1)

    }
  };  

  const [data, setData] = useState([]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
  
    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      setData(excelData);
      const sheetNames = workbook.SheetNames;
      setsayfaadlari(sheetNames)
      
        setyerim(2);

    
    
      const headers = excelData[0];
      setbasliklist(headers);
  
      // Her başlık için bir nesne oluştur
      const dataObject = {};
  
      headers.forEach(header => {
        dataObject[header] = [];
      });
  
      for (let i = 1; i < excelData.length; i++) {
        setVeriSayilari(0)
        for (let j = 0; j < headers.length; j++) {
          const header = headers[j];
          const value = excelData[i][j] || "-"; // Eksik verileri kontrol et ve "-" eşle
          dataObject[header].push(value);
          setVeriSayilari(excelData.length-1)
          
        }
      }
  
      
  
      // Veya dışarı aktarabilirsiniz
      setVeriler(dataObject);
    };
  
    reader.readAsBinaryString(file);
  };


  const  navfunc = async (nere) => {
     
    navigate(nere);
  };
 

  const user = auth.currentUser;

  const kayittamamla = async () => {
    for (let i = 0; i < VeriSayilari ; i++) {

    const now = new Date();
    const formattedDateTime = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`;
    try {
      const docRef = await addDoc(collection(db, "urunler"), {
        urun: seciliad[i],
        barkod: [secilibarkod[i]],
        adet: isNaN(parseInt(seciliadet[i])) ? 0 : parseInt(seciliadet[i]),
        cesit: secilicesit[i],
        imgurl: "",
        marka: secilimarka[i],
        kaynak: secilikaynak[i],
        cikan: 0,
        palet : "*",
        son_giris : formattedDateTime,
        tarih : formattedDateTime,
        tariht: now,
        giren: isNaN(parseInt(seciliadet[i])) ? 0 : parseInt(seciliadet[i]),
        aciklama:seciliaciklama[i],
        sahip: localStorage.getItem('depo') ,
        
      });
  

  
    } catch (error) {
      
    }
  }

  navigate('/panel/');



  }




  return (
<div>

    <div className={styles.exceldengecis}>
    <Ymenuopen data="veriaktar"/>

   
      <div className={styles.headerParent}>




       
        <div className={styles.wrapperParent}>


          {yerim == 0 ? 
          <div className={styles.wrapper5}>
            <div className={styles.lg}>
              <div className={styles.stepper}>
                <div className={styles.steps}>
                  <div className={styles.step}>
                    <div className={styles.circle}>
                      <div className={styles.div9}>1</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Dosya Yükleyin</div>
                      <div className={styles.line} />
                    </div>
                  </div>
                  
                  <div className={styles.step}>
                    <div className={styles.circle1}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Başlıkları Eşleştirin</div>
                      <div className={styles.line} />
                    </div>
                  </div>
                  <div className={styles.step4}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step5}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step6}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step7}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step8}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step9}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step10}>
                    <div className={styles.circle1}>
                      <div className={styles.div9}>3</div>
                    </div>
                    <div className={styles.textAndLine9}>
                      <div className={styles.step1}>Kaydedin</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.content}>
                <b className={styles.dosyaYkle}>Dosya Yükle</b>
                <div className={styles.ykleyebileceinizTrlerXContainer}>
                  <p className={styles.ykleyebileceinizTrler}>
                    Yükleyebileceğiniz Türler:
                  </p>
                  <p className={styles.xlsxcsvxls}>(.xlsx,.csv,.xls)</p>
                </div>
                
                <div className={styles.uploadDropzone}>
                  <div className={styles.textAndButton}>

                    <div
                      className={styles.uploadXlsxXls}
                    >{`Dosya Türleri .xlsx, .xls veya .csv `}</div>
                    <div type="file" className={styles.button} onChange={handleFileUpload}>
                      <input className={styles.button} type="file" onChange={handleFileUpload} />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        : null }






{yerim == 2 ? 


          <div className={styles.wrapper7}>
            <div className={styles.lg}>
              <div className={styles.stepper}>
                <div className={styles.steps}>
                  <div className={styles.step}>
                    <div className={styles.circle1}>
                      <div className={styles.div9}>1</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Dosya Yükleyin</div>
                      <div className={styles.line} />
                    </div>
                  </div>
                  
                  <div className={styles.step}>
                    <div className={styles.circle}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Başlıkları Eşleştirin</div>
                      <div className={styles.line} />
                    </div>
                  </div>
                  <div className={styles.step4}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step5}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step6}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step7}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step8}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step9}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step10}>
                    <div className={styles.circle1}>
                      <div className={styles.div9}>3</div>
                    </div>
                    <div className={styles.textAndLine9}>
                      <div className={styles.step1}>Kaydedin</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.content2}>
                <b className={styles.dosyaYkle}>Başlıkları Eşleştirin</b>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.frameWrapper}>
                  <div className={styles.frameGroup}>
                    <div className={styles.frameContainer}>
                      <div className={styles.urunAdWrapper}>
                        <div className={styles.urunAdWrapper}>
                          <b className={styles.urunAd}>Urun Adı</b>
                        </div>
                      </div>
                    </div>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="/vector-1.svg"
                    />
                    <div className={styles.priorityLevelDropdown}>
                      <div className={styles.label2} />
                      <div className={styles.container1} onClick={(e) => {
                              e.stopPropagation(); 
                              setadvisible(advisible === 1 ? 0 : 1);
                            }}>
                        <div className={styles.placeholder}>
                          <div className={styles.left1}>
                            <div className={styles.smallLabel}>{advisible == 0 ? "Urun Adı Başlığı Seçiniz" : seciliadb  }</div>
                          </div>
                          <img
                            className={styles.uiIconclosefilled}
                            alt=""
                            src="/chevrondown.svg"
                            
                          />
                        </div>
                      </div>



{ advisible == 0 ? 
                      <div className={styles.dropdownMenu} >


                        

                        {basliklist.map((item) => (

                        <div className={styles.optionA1} onClick={(e) => {
                          e.stopPropagation(); 
                          adsecme(item);
                        }}>
                          <div className={styles.smallLabel}>{item}</div>
                        </div>
                        ))}

                        
                      </div>
: null}



                    </div>
                  </div>
                </div>
                <div className={styles.frameWrapper}>
                  <div className={styles.frameGroup}>
                    <div className={styles.frameContainer}>
                      <div className={styles.urunAdWrapper}>
                        <div className={styles.urunAdWrapper}>
                          <b className={styles.urunAd}>Adet</b>
                        </div>
                      </div>
                    </div>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="/vector-1.svg"
                    />
                    <div className={styles.priorityLevelDropdown}>
                      <div className={styles.label2} />
                      <div className={styles.container1}  onClick={(e) => {
                              e.stopPropagation(); 
                              setadetvisible(adetvisible === 1 ? 0 : 1);
                            }}>
                        <div className={styles.placeholder}>
                          <div className={styles.left1}>
                            <div className={styles.smallLabel}>{adetvisible == 0 ? "Adet Başlığı Seçiniz" : seciliadetb  }</div>
                          </div>
                          <img
                            className={styles.uiIconclosefilled}
                            alt=""
                            src="/chevrondown.svg"
                           
                          />
                        </div>
                      </div>

                      { adetvisible == 0 ? 

                      <div className={styles.dropdownMenu}>


                        <div className={styles.optionA} onClick={(e) => {
                          e.stopPropagation(); 
                          adetsecme(1);
                        }}>
                          <div className={styles.smallLabel}>1 Adet</div>
                        </div>

                        {basliklist.map((item) => (

                        <div className={styles.optionA1} onClick={(e) => {
                          e.stopPropagation(); 
                          adetsecme(item);
                        }}>
                          <div className={styles.smallLabel}>{item}</div>
                        </div>
                        ))}

                        
                      </div>
: null }


                    </div>
                  </div>
                </div>


                <div className={styles.frameWrapper}>
                  <div className={styles.frameGroup}>
                    <div className={styles.frameContainer}>
                      <div className={styles.urunAdWrapper}>
                        <div className={styles.urunAdWrapper}>
                          <b className={styles.urunAd}>Çeşit</b>
                        </div>
                      </div>
                    </div>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="/vector-1.svg"
                    />
                    <div className={styles.priorityLevelDropdown}>
                      <div className={styles.label2} />
                      <div className={styles.container1} onClick={(e) => {
                              e.stopPropagation(); 
                              setcesitvisible(cesitvisible === 1 ? 0 : 1);
                            }}>
                        <div className={styles.placeholder}>
                          <div className={styles.left1}>
                            <div className={styles.smallLabel}>{cesitvisible == 0 ? "Çeşit Başlığı Seçiniz" : secilicesitb  }</div>
                          </div>
                          <img
                            className={styles.uiIconclosefilled}
                            alt=""
                            src="/chevrondown.svg"
                            
                          />
                        </div>
                      </div>

                      { cesitvisible == 0 ? 

                      <div className={styles.dropdownMenu}>


                        <div className={styles.optionA} onClick={(e) => {
                          e.stopPropagation(); 
                          cesitsecme("-");
                        }}>
                          <div className={styles.smallLabel}>Boş Bırak</div>
                        </div>

                        {basliklist.map((item) => (

                        <div className={styles.optionA1} onClick={(e) => {
                          e.stopPropagation(); 
                          cesitsecme(item);
                        }}>
                          <div className={styles.smallLabel}>{item}</div>
                        </div>
                        ))}

                        
                      </div>
: null }

                    </div>
                  </div>
                </div>
                <div className={styles.frameWrapper}>
                  <div className={styles.frameGroup}>
                    <div className={styles.frameContainer}>
                      <div className={styles.urunAdWrapper}>
                        <div className={styles.urunAdWrapper}>
                          <b className={styles.urunAd}>Kaynak</b>
                        </div>
                      </div>
                    </div>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="/vector-1.svg"
                    />
                    <div className={styles.priorityLevelDropdown}>
                      <div className={styles.label2} />
                      <div className={styles.container1} onClick={(e) => {
                              e.stopPropagation(); 
                              setkaynakvisible(kaynakvisible === 1 ? 0 : 1);
                            }}>
                        <div className={styles.placeholder}>
                          <div className={styles.left1}>
                            <div className={styles.smallLabel}>{kaynakvisible == 0 ? "Kaynak Başlığı Seçiniz" : secilikaynakb  }</div>
                          </div>
                          <img
                            className={styles.uiIconclosefilled}
                            alt=""
                            src="/chevrondown.svg"
                            
                          />
                        </div>
                      </div>

                      { kaynakvisible == 0 ? 

                      <div className={styles.dropdownMenu}>


                        <div className={styles.optionA} onClick={(e) => {
                          e.stopPropagation(); 
                          kaynaksecme("-");
                        }}>
                          <div className={styles.smallLabel}>Boş Bırak</div>
                        </div>

                        {basliklist.map((item) => (

                        <div className={styles.optionA1} onClick={(e) => {
                          e.stopPropagation(); 
                          kaynaksecme(item);
                        }}>
                          <div className={styles.smallLabel}>{item}</div>
                        </div>
                        ))}

                        
                      </div>
: null }


                    </div>
                  </div>
                </div>
                <div className={styles.frameWrapper}>
                  <div className={styles.frameGroup}>
                    <div className={styles.frameContainer}>
                      <div className={styles.urunAdWrapper}>
                        <div className={styles.urunAdWrapper}>
                          <b className={styles.urunAd}>Marka</b>
                        </div>
                      </div>
                    </div>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="/vector-1.svg"
                    />
                    <div className={styles.priorityLevelDropdown}>
                      <div className={styles.label2} />
                      <div className={styles.container1}  onClick={(e) => {
                              e.stopPropagation(); 
                              setmarkavisible(markavisible === 1 ? 0 : 1);
                            }}>
                        <div className={styles.placeholder}>
                          <div className={styles.left1}>
                            <div className={styles.smallLabel}>{markavisible == 0 ? "Marka Başlığı Seçiniz" : secilimarkab  }</div>
                          </div>
                          <img
                            className={styles.uiIconclosefilled}
                            alt=""
                            src="/chevrondown.svg"
                           
                          />
                        </div>
                      </div>
                      { markavisible == 0 ? 


                      <div className={styles.dropdownMenu}>


<div className={styles.optionA} onClick={(e) => {
                          e.stopPropagation(); 
                          markasecme("-");
                        }}>
  <div className={styles.smallLabel}>Boş Bırak</div>
</div>

{basliklist.map((item) => (

<div className={styles.optionA1} onClick={(e) => {
                          e.stopPropagation(); 
                          markasecme(item);
                        }}>
  <div className={styles.smallLabel}>{item}</div>
</div>
))}


</div>

: null }



                    </div>
                  </div>
                </div>
                <div className={styles.frameWrapper}>
                  <div className={styles.frameGroup}>
                    <div className={styles.frameContainer}>
                      <div className={styles.urunAdWrapper}>
                        <div className={styles.urunAdWrapper}>
                          <b className={styles.urunAd}>Barkod</b>
                        </div>
                      </div>
                    </div>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="/vector-1.svg"
                    />
                    <div className={styles.priorityLevelDropdown}>
                      <div className={styles.label2} />
                      <div className={styles.container1}onClick={(e) => {
                              e.stopPropagation(); 
                              setbarkodvisible(barkodvisible === 1 ? 0 : 1);
                            }}>
                        <div className={styles.placeholder}>
                          <div className={styles.left1}>
                            <div className={styles.smallLabel}>{barkodvisible === 0 ? "Barkod Başlığı Seçiniz" : secilibarkodb  }</div>
                          </div>
                          <img
                            className={styles.uiIconclosefilled}
                            alt=""
                            src="/chevrondown.svg"
                            
                          />
                        </div>
                      </div>
                      { barkodvisible == 0 ? 


                      <div className={styles.dropdownMenu}>


<div className={styles.optionA} onClick={(e) => {
                          e.stopPropagation(); 
                          barkodsecme("-");
                        }}>
  <div className={styles.smallLabel}>Boş Bırak</div>
</div>

{basliklist.map((item) => (

<div className={styles.optionA1} onClick={(e) => {
                          e.stopPropagation(); 
                          barkodsecme(item);
                        }}>
  <div className={styles.smallLabel}>{item}</div>
</div>
))}


</div>
: null }


                    </div>
                  </div>
                </div>
                <div className={styles.frameWrapper}>
                  <div className={styles.frameGroup}>
                    <div className={styles.frameContainer}>
                      <div className={styles.urunAdWrapper}>
                        <div className={styles.urunAdWrapper}>
                          <b className={styles.urunAd}>Açıklama</b>
                        </div>
                      </div>
                    </div>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="/vector-1.svg"
                    />
                    <div className={styles.priorityLevelDropdown}>
                      <div className={styles.label2} />
                      <div className={styles.container1}onClick={(e) => {
                              e.stopPropagation(); 
                              setaciklamavisible(aciklamavisible === 1 ? 0 : 1);
                            }}>
                        <div className={styles.placeholder}>
                          <div className={styles.left1}>
                            <div className={styles.smallLabel}>{aciklamavisible === 0 ? "Açıklama Başlığı Seçiniz" : seciliaciklamab  }</div>
                          </div>
                          <img
                            className={styles.uiIconclosefilled}
                            alt=""
                            src="/chevrondown.svg"
                            
                          />
                        </div>
                      </div>
                      { aciklamavisible == 0 ? 


                      <div className={styles.dropdownMenu}>


<div className={styles.optionA} onClick={(e) => {
                          e.stopPropagation(); 
                          aciklamasecme("-");
                        }}>
  <div className={styles.smallLabel}>Boş Bırak</div>
</div>

{basliklist.map((item) => (

<div className={styles.optionA1} onClick={(e) => {
                          e.stopPropagation(); 
                          aciklamasecme(item);
                        }}>
  <div className={styles.smallLabel}>{item}</div>
</div>
))}


</div>
: null }


                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.nextStepButton} onClick={(e) => {
                              e.stopPropagation(); 
                              setyerim(3);
                            }}>
                <div className={styles.button2}>
                  <div className={styles.uploadXlsxXls}>İlerle</div>
                </div>
              </div>
            </div>
          </div>

: null }

{yerim == 3 ? 

          <div className={styles.wrapper7}>
            <div className={styles.lg}>
              <div className={styles.stepper}>
                <div className={styles.steps}>
                  <div className={styles.step}>
                    <div className={styles.circle1}>
                      <div className={styles.div9}>1</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Dosya Yükleyin</div>
                      <div className={styles.line} />
                    </div>
                  </div>
                  
                  <div className={styles.step}>
                    <div className={styles.circle1}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Başlıkları Eşleştirin</div>
                      <div className={styles.line} />
                    </div>
                  </div>
                  <div className={styles.step4}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step5}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step6}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step7}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step8}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step9}>
                    <div className={styles.circle3}>
                      <div className={styles.div9}>2</div>
                    </div>
                    <div className={styles.textAndLine}>
                      <div className={styles.step1}>Step 2</div>
                      <div className={styles.line3} />
                    </div>
                  </div>
                  <div className={styles.step10}>
                    <div className={styles.circle}>
                      <div className={styles.div9}>3</div>
                    </div>
                    <div className={styles.textAndLine9}>
                      <div className={styles.step1}>Kaydedin</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.content}>
                <div className={styles.header1}>
                  <b className={styles.dosyaYkle}>Önizle</b>
                  
                </div>
                <div className={styles.table1}>
                  <div className={styles.urunAdWrapper}>
                    <div className={styles.rnParent}>
                      <b className={styles.rn}>Ürün</b>
                      <div className={styles.border} />
                    </div>

                    {seciliad.map((item) => (

                    <div className={styles.founderOfChakraUiParent}>
                      <div className={styles.founderOfChakra}>
                        {item}
                      </div>
                      <div className={styles.border} />
                    </div>
                    ))}
                  </div>
                  <div className={styles.urunAdWrapper}>
                    <div className={styles.rnParent}>
                      <b className={styles.rn}>Adet</b>
                      <div className={styles.border} />
                    </div>
                    {seciliadet.map((item) => (

<div className={styles.founderOfChakraUiParent}>
  <div className={styles.founderOfChakra}>
    {item}
  </div>
  <div className={styles.border} />
</div>
))}
                  </div>
                  <div className={styles.urunAdWrapper}>
                    <div className={styles.rnParent}>
                      <b className={styles.rn}>Kaynak</b>
                      <div className={styles.border} />
                    </div>
                    {secilikaynak.map((item) => (

<div className={styles.founderOfChakraUiParent}>
  <div className={styles.founderOfChakra}>
    {item}
  </div>
  <div className={styles.border} />
</div>
))}
                  </div>
                  <div className={styles.urunAdWrapper}>
                    <div className={styles.rnParent}>
                      <b className={styles.rn}>Marka</b>
                      <div className={styles.border} />
                    </div>
                    {secilimarka.map((item) => (

<div className={styles.founderOfChakraUiParent}>
  <div className={styles.founderOfChakra}>
    {item}
  </div>
  <div className={styles.border} />
</div>
))}
                  </div>


                  <div className={styles.urunAdWrapper}>
                    <div className={styles.rnParent}>
                      <b className={styles.rn}>Çeşit</b>
                      <div className={styles.border} />
                    </div>
                    {secilicesit.map((item) => (

<div className={styles.founderOfChakraUiParent}>
  <div className={styles.founderOfChakra}>
    {item}
  </div>
  <div className={styles.border} />
</div>
))}
                  </div>


                  <div className={styles.urunAdWrapper}>
                    <div className={styles.rnParent}>
                      <b className={styles.rn}>Barkod</b>
                      <div className={styles.border} />
                    </div>
                    {secilibarkod.map((item) => (

<div className={styles.founderOfChakraUiParent}>
  <div className={styles.founderOfChakra}>
    {item}
  </div>
  <div className={styles.border} />
</div>
))}
                  </div>

                  <div className={styles.urunAdWrapper}>
                    <div className={styles.rnParent}>
                      <b className={styles.rn}>Açıklama</b>
                      <div className={styles.border} />
                    </div>
                    {seciliaciklama.map((item) => (

<div className={styles.founderOfChakraUiParent}>
  <div className={styles.founderOfChakra}>
    {item}
  </div>
  <div className={styles.border} />
</div>
))}
                  </div>
                </div>
              </div>
              <div className={styles.nextStepButton} onClick={(e) => {
                          e.stopPropagation(); 
                          kayittamamla();
                        }}>
                <div className={styles.button2}>
                  <div className={styles.uploadXlsxXls}>Kaydet</div>
                </div>
              </div>
            </div>
          </div>
: null }

        </div>
      </div>
    </div>
    </div>
  );
};

export default Exceldengecis;
