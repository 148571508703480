import styles from "./YeniGiris.module.css";
import styles2 from "./YeniGirisqr.module.css";

import React, { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getDocs, getFirestore } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy,doc,onSnapshot} from "firebase/firestore"; 
import { getAuth, signInWithEmailAndPassword , setPersistence, browserSessionPersistence, localPersistence } from "firebase/auth";
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase';
import Alert from '@mui/material/Alert';
import QRCode from "react-qr-code";

import axios from "axios";

import { Link, useNavigate } from 'react-router-dom';

const firebaseConfig = {
  apiKey: "AIzaSyBvuq7FyhGZ1OuPuYMDlxIvNx-ip_WVC8E",
  authDomain: "myinventory-73dc6.firebaseapp.com",
  projectId: "myinventory-73dc6",
  storageBucket: "myinventory-73dc6.appspot.com",
  messagingSenderId: "1009595674740",
  appId: "1:1009595674740:web:618105201f35104b27e121",
  measurementId: "G-F89WMPB355"
};

const YeniGiris = () => {

    const generateRandomValue = () => {
        sethangi(1)
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomValue = '';
        for (let i = 0; i < 16; i++) {
          randomValue += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setqrvalue(randomValue)
        
    
        try {
          const docRef = addDoc(collection(db, "qr_logins"), {
            qr : randomValue,
            durum : 0,
            
            
            
          });
    
          docRef.then(doc => {
            setqrid(doc.id)
          }).catch(error => {
            console.error("Belge ID alınamadı:", error);
          });
          
        } catch (error) {
          
        }
    
    
    
    
      };
    
      const [qrvalue, setqrvalue] = useState("");
    
      const [ip, setIP] = useState("");
      const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip);
      };
    
      useEffect(() => {
        getData();
      }, []);
    
    
    
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
    
      
    
      const [idlist, setidlist] = useState([]);
      const [hangi, sethangi] = useState(0);
      const navigate = useNavigate();
      const [qrmi, setqrmi] = useState(0);

    
      async function girisKayit() {
        sethangi(hangi === 1 ? 0 : 1);
        setqrmi(0);

      }
      async function girisKayitt() {
        sethangi(0);
        setqrmi(0);

      }
    
      async function qrgir() {
        generateRandomValue();

        setqrmi(1);
    
      }
    
    
      const registrationHandler = async () => {
    
        try {
            const response = await createUserWithEmailAndPassword(auth, email, sifre);
           
    
            kayittamamla(response.user.uid)
            girisKayit()
        } catch (error) {
    
          alert("Hatalı Kayıt Bilgileri")

        }
    
    
    
    }
    
    const kayittamamla = async (idsi) => {
    
      
      try {
          // Add a new document with a generated id.
        const docRef = await addDoc(collection(db, "users"), {
          firma : firma,
          id: idsi,
          user: isim
        });
    
    
    
      } catch (error) {
        
      }
    }
    
    
    
    
    
    
    
    
    
     
    
    
        const [sifre, setsifre] = useState("");
        const [email,setemail] = useState("");
        const [isim, setisim] = useState("");
        const [firma, setfirma] = useState("");
    
        const [qrid, setqrid] = useState("w");
    
    
        useEffect(() => {
          const fetchData = async () => {
      
          
      
      
            try {
      
            
        
              const fetchData = async () => {
                const cesitliste = [];
      
        
                const querySnapshot = await getDocs(
                  query(
                    collection(db, "nfc_list")
                  )
                ); 
              
                querySnapshot.forEach((doc) => {
        
      
                    
                    
                    cesitliste.push(doc.data().idsi);
                    
                  
        
        
                });
               
                setidlist(cesitliste)
                
              };  
              fetchData();
            
            }
            catch(error) {
              
            }
          
          };
      
      
      
      
        
      
          fetchData();
          
        }, []);
    
    
        const loginHandler = async () => {
            try {
    
    
              const response = await signInWithEmailAndPassword(auth, email, sifre);
              const user = response.user;
              
              navigate('/panel/');
    
              
              
               
    
              } catch (error) {
                alert("Hatalı Giriş Bilgileri")
    
                
                
              
              
              
            } 
    
    
           
    
    
    
    
    
    
    
    
    
    
    
          };
    
    
    
    
          const [browserInfo, setBrowserInfo] = useState(null);
    
          useEffect(() => {
            const getInfo = () => {
              const { userAgent, language, platform } = window.navigator;
              const info = {
                userAgent,
                language,
                platform
              };
              setBrowserInfo(info.platform);
            };
            
            getInfo();
          }, []);
        
        
    
            const unsub = onSnapshot(doc(db, "qr_logins", qrid), (doc) => {
              if(doc.data()) {
    
                  if(doc.data().durum == 1) {
                    const now = new Date();
                    const formattedDateTime = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`;
                    const docRef = addDoc(collection(db, "giris_kayit"), {
                      tarih : formattedDateTime,
                      ip : ip,
                      tarayici : browserInfo,
                      tariht : now,
                      
                      
                      
                    });
                    navigate('/panel/');
    
                  }
    
              }
              
    
          });
        




  return (
    <div className={styles.yeniGiris}   >
      <div className={styles.dk011Parent}>
        <div className={styles.groupParent}>
          <div className={styles.rectangleParent}>
            <div className={styles.groupChild} />
            

{qrmi == 1 ? 
            <div className={styles2.qrgiris}>
              <div className={styles2.qrgirisChild} />
              <div className={styles2.qrGirisParent}>
                <div className={styles.kaytOl}>QR Giris</div>
                <div className={styles2.image9Parent}>
                <div style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%" }}>
  
  <QRCode
    size={256}
    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
    value= {qrvalue}
    viewBox={`0 0 256 256`}
  />
  </div> 
                  <div className={styles2.mobilUygulamadakiQrGiriWrapper}>
                    <div className={styles2.qstock}>
                      Mobil Uygulamadaki “QR Giriş” bölümünden taratabilirsiniz.
                    </div>
                  </div>
                </div>
                <div className={styles.lineParent}>
                  <div className={styles.frameChild} />
                  <div className={styles.veya}>Veya</div>
                  <div className={styles.frameChild} />
                </div>
                <div className={styles.buttonSignup} onClick={(e) => {
                  e.stopPropagation(); 
                  girisKayitt();
                }}>
                  <div className={styles.inputPassword2} />
                  <div className={styles.girisYap} >Giris Yap</div>
                </div>
              </div>
            </div>

:
<div>
            {hangi == 1 ? 
            <div className={styles.kayit}>
              <div className={styles.kayitChild} />
              <div className={styles.kaytOlParent}>
                <div className={styles.kaytOl}>Kayıt Ol</div>
                <div className={styles.groupContainer}>
                  <div className={styles.simParent}>
                    <div className={styles.sim}>İsim</div>
                    
                    <div className={styles.inputUsernameParent}>
                      <div className={styles.inputUsername} />
                      <input
          className={styles.email3}
          placeholder="İsminizi Giriniz"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setisim(e.target.value);
          }}
        />                    </div>
                    <div className={styles.rectangleGroup}>
                      <div className={styles.groupItem} />
                      <img
                        className={styles.personIcon}
                        alt=""
                        src="/person@2x.png"
                      />
                    </div>
                  </div>
                  <div className={styles.frameParent}>
                    <div className={styles.firmaParent}>
                      <div className={styles.firma}>Firma</div>
                      <div className={styles.inputUsernameGroup}>
                        <div className={styles.inputUsername} />
                        <input
          className={styles.email3}
          placeholder="Firmanızı Giriniz"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setfirma(e.target.value);
          }}
        />                      </div>
                    </div>
                    <div className={styles.rectangleGroup}>
                      <div className={styles.groupItem} />
                      <img
                        className={styles.companyIcon}
                        alt=""
                        src="/company@2x.png"
                      />
                    </div>
                  </div>
                  <div className={styles.simParent}>
                    <div className={styles.inputUsernameParent}>
                      <div className={styles.inputUsername} />
                      <input
          className={styles.email3}
          placeholder="Emailinizi Giriniz"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setemail(e.target.value);
          }}
        />
                    </div>
                    <div className={styles.sim}>Email</div>
                    
                    <div className={styles.rectangleGroup}>
                      <div className={styles.groupItem} />
                      <img
                        className={styles.letterIcon}
                        alt=""
                        src="/letter@2x.png"
                      />
                    </div>
                  </div>
                  <div className={styles.groupParent1}>
                    <div className={styles.inputPasswordParent}>
                      <div className={styles.inputUsername} />
                      <input
          className={styles.ifre3}
          type="password"
          placeholder="Şifrenizi Giriniz"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setsifre(e.target.value);
          }}
        />                    </div>
                    <div className={styles.sim}>Şifre</div>
                    <div className={styles.rectangleParent2}>
                      <div className={styles.groupItem} />
                      <img
                        className={styles.letterIcon}
                        alt=""
                        src="/lock@2x.png"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.buttonLogin}  onClick={(e) => {
                e.stopPropagation(); 
                registrationHandler();
              }}>
                  <div className={styles.inputPassword1} />
                  <div className={styles.kaytOl1}>Kayıt Ol</div>
                </div>
                <div className={styles.lineParent}>
                  <div className={styles.frameChild} />
                  <div className={styles.veya}>Veya</div>
                  <div className={styles.frameChild} />
                </div>
                <div className={styles.buttonSignup} onClick={(e) => {
                  e.stopPropagation(); 
                  girisKayit();
                }}>
                  <div className={styles.inputPassword2} />
                  <div className={styles.girisYap} >Giris Yap</div>
                </div>
              </div>
            </div>

:
            <div className={styles.rectangleParent3}>
              <div className={styles.groupChild2} />
              <div className={styles.girisParent}>
                <div className={styles.giris}>Giris</div>
                <div className={styles.emailParent}>
                  <div className={styles.email2}>Email</div>
                  <div className={styles.inputPasswordGroup}>
                    <div className={styles.inputUsername} />
                    <input
          className={styles.email3}
          placeholder="Emailinizi Giriniz"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setemail(e.target.value);
          }}
        />
                  </div>
                  <div className={styles.rectangleParent4}>
                    <div className={styles.groupItem} />
                    <img
                      className={styles.letterIcon}
                      alt=""
                      src="/letter@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.ifreParent}>
                  <div className={styles.email2}>Şifre</div>
                  <div className={styles.inputPasswordGroup}>
                    <div className={styles.inputUsername} />
                    <input
          className={styles.ifre3}
          type="password"
          placeholder="Şifrenizi Giriniz"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setsifre(e.target.value);
          }}
        />  
                  </div>
                  <div className={styles.rectangleParent5}>
                    <div className={styles.groupItem} />
                    <img
                      className={styles.letterIcon}
                      alt=""
                      src="/lock@2x.png"
                    />
                  </div>
                </div>
                <div className={styles.buttonLogin1} onClick={(e) => {
                e.stopPropagation(); 
                loginHandler();
              }}>
                  <div className={styles.inputPassword1} />
                  <div className={styles.kaytOl1}>Giris Yap</div>
                </div>
                <div className={styles.ctaButton} onClick={(e) => {
                e.stopPropagation(); 
                qrgir();
              }}>
                  <div className={styles.label}>
                    <div className={styles.qrGiriYap} >QR Giriş Yap</div>
                  </div>
                </div>
                <div className={styles.lineGroup}>
                  <div className={styles.frameChild} />
                  <div className={styles.veya}>Veya</div>
                  <div className={styles.frameChild} />
                </div>
                <div className={styles.buttonSignup1} onClick={(e) => {
                  e.stopPropagation(); 
                  girisKayit();
                }}>
                  <div className={styles.inputPassword2} />
                  <div className={styles.girisYap} >Kayıt Ol</div>
                </div>
              </div>
            </div> }
            </div>
}

          </div>
          <div className={styles.image5Wrapper}>
            <img className={styles.image5Icon} alt="" src="/image-5@2x.png" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default YeniGiris;
