import styles from "./GirisSayfasi.module.css";
import React, { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getDocs, getFirestore } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy,doc,onSnapshot} from "firebase/firestore"; 
import { getAuth, signInWithEmailAndPassword , setPersistence, browserSessionPersistence, localPersistence } from "firebase/auth";
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase';
import Alert from '@mui/material/Alert';
import QRCode from "react-qr-code";

import axios from "axios";

import { Link, useNavigate } from 'react-router-dom';

const firebaseConfig = {
  apiKey: "AIzaSyBvuq7FyhGZ1OuPuYMDlxIvNx-ip_WVC8E",
  authDomain: "myinventory-73dc6.firebaseapp.com",
  projectId: "myinventory-73dc6",
  storageBucket: "myinventory-73dc6.appspot.com",
  messagingSenderId: "1009595674740",
  appId: "1:1009595674740:web:618105201f35104b27e121",
  measurementId: "G-F89WMPB355"
};
const GirisSayfasi = () => {

  const generateRandomValue = () => {
    sethangi(1)
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomValue = '';
    for (let i = 0; i < 16; i++) {
      randomValue += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setqrvalue(randomValue)
    

    try {
      const docRef = addDoc(collection(db, "qr_logins"), {
        qr : randomValue,
        durum : 0,
        
        
        
      });

      docRef.then(doc => {
        setqrid(doc.id)
      }).catch(error => {
        console.error("Belge ID alınamadı:", error);
      });
      
    } catch (error) {
      
    }




  };

  const [qrvalue, setqrvalue] = useState("");

  const [ip, setIP] = useState("");
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };

  useEffect(() => {
    getData();
  }, []);



  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  

  const [idlist, setidlist] = useState([]);
  const [hangi, sethangi] = useState(0);
  const navigate = useNavigate();


  async function girisKayit() {
    sethangi(hangi === 1 ? 0 : 1);

  }



  const registrationHandler = async () => {

    try {
        const response = await createUserWithEmailAndPassword(auth, email, sifre);
       

        kayittamamla(response.user.uid)
        girisKayit()
    } catch (error) {

     
    }



}

const kayittamamla = async (idsi) => {

  
  try {
      // Add a new document with a generated id.
    const docRef = await addDoc(collection(db, "users"), {
      firma : firma,
      id: idsi,
      user: isim
    });



  } catch (error) {
    
  }
}









 


    const [sifre, setsifre] = useState("");
    const [email,setemail] = useState("");
    const [isim, setisim] = useState("");
    const [firma, setfirma] = useState("");

    const [qrid, setqrid] = useState("w");


    useEffect(() => {
      const fetchData = async () => {
  
      
  
  
        try {
  
        
    
          const fetchData = async () => {
            const cesitliste = [];
  
    
            const querySnapshot = await getDocs(
              query(
                collection(db, "nfc_list")
              )
            ); 
          
            querySnapshot.forEach((doc) => {
    
  
                
                
                cesitliste.push(doc.data().idsi);
                
              
    
    
            });
           
            setidlist(cesitliste)
            
          };  
          fetchData();
        
        }
        catch(error) {
          
        }
      
      };
  
  
  
  
    
  
      fetchData();
      
    }, []);


    const loginHandler = async () => {
        try {


          const response = await signInWithEmailAndPassword(auth, email, sifre);
          const user = response.user;
          
          navigate('/panel/');

          
          
           

          } catch (error) {
            alert("Hatalı Giriş Bilgileri")

            
            
          
          
          
        } 


       











      };




      const [browserInfo, setBrowserInfo] = useState(null);

      useEffect(() => {
        const getInfo = () => {
          const { userAgent, language, platform } = window.navigator;
          const info = {
            userAgent,
            language,
            platform
          };
          setBrowserInfo(info.platform);
        };
        
        getInfo();
      }, []);
    
    

        const unsub = onSnapshot(doc(db, "qr_logins", qrid), (doc) => {
          if(doc.data()) {

              if(doc.data().durum == 1) {
                const now = new Date();
                const formattedDateTime = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`;
                const docRef = addDoc(collection(db, "giris_kayit"), {
                  tarih : formattedDateTime,
                  ip : ip,
                  tarayici : browserInfo,
                  tariht : now,
                  
                  
                  
                });
                navigate('/panel/');

              }

          }
          

      });
    
      
    
        
     








  return (
    
    <div className={styles.girisSayfasi}>
      
      <div className={styles.body}>
        <div className={styles.header}>
          <img className={styles.image5Icon} alt="" src="/image-5@2x.png" />
        </div>


        <div className={styles.form}>

        {hangi === 0 ? 

          <div className={styles.email}>
            <div className={styles.label}>
              <div className={styles.smallLabel}>Email</div>
            </div>
            <div className={styles.container}>
              <div className={styles.label}>
                <input
          className={styles.smallLabel}
          placeholder="Emailinizi Giriniz"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setemail(e.target.value);
          }}
        />
              </div>
            </div>
          </div> : null }
          <div className={styles.password}>

          {hangi === 0 ? 

            <div className={styles.email}>
              <div className={styles.label}>
                <div className={styles.smallLabel}>Şifre</div>
              </div>
              <div className={styles.container}>
                <div className={styles.label}>
                  <div className={styles.placeholder1}>
                  <input
          className={styles.smallLabel}
          type="password"
          placeholder="Şifrenizi Giriniz"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setsifre(e.target.value);
          }}
        />                  </div>
                </div>
              </div>
            </div>
            : null }

            {hangi === 1 ? 
 <div style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%" }}>
  
<QRCode
  size={256}
  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
  value= {qrvalue}
  viewBox={`0 0 256 256`}
/>
</div>  : null}

{hangi === 0 ? 
            <div className={styles.ctaButton} onClick={(e) => {
                e.stopPropagation(); 
                loginHandler();
              }}>
              <div className={styles.label2}>
                <div className={styles.xlLabel}>Giriş Yap</div>
              </div>
            </div>

: <div className={styles.ctaButton} onClick={(e) => {
  e.stopPropagation(); 
  girisKayit();
}}>
<div className={styles.label2}>
  <div className={styles.xlLabel}>Email ile Giriş Yap</div>
</div>
</div> }

{hangi === 0 ? 


            <div className={styles.ctaButtonqr} onClick={(e) => {
                e.stopPropagation(); 
                generateRandomValue();
              }}>
              <div className={styles.label2}>
                <div className={styles.xlLabel}>QR ile Giriş Yap</div>
              </div>
            </div>
: null }


          </div>
          
        </div>








        <div className={styles.formKayit}>
          <div className={styles.email}>
            <div className={styles.label}>
              <div className={styles.smallLabel}>Email</div>
            </div>
            <div className={styles.container}>
              <div className={styles.label}>
                <div className={styles.smallLabel}>Emailinidzi Giriniz</div>
              </div>
            </div>
          </div>
          <div className={styles.password}>
            <div className={styles.email}>
              <div className={styles.label}>
                <div className={styles.smallLabel}>Şifre</div>
              </div>
              <div className={styles.container}>
                <div className={styles.label}>
                  <div className={styles.placeholder1}>
                    <div className={styles.smallLabel}>Şifrenizi Giriniz</div>
                  </div>
                  <img className={styles.hideIcon} alt="" src="/hide.svg" />
                </div>
              </div>
            </div>
            <div className={styles.ctaButton}>
              <div className={styles.label2}>
                <div className={styles.xlLabel}>Kayıt Ol</div>
              </div>
            </div>
          </div>
          <div className={styles.thirdPartyLogin1}>
            <div className={styles.apple}>
              <img className={styles.nfcIcon} alt="" src="/nfc@2x.png" />
              <div className={styles.label2}>
                <b className={styles.smallLabel4}>Hızlı Giriş Yap</b>
              </div>
            </div>
          </div>
        </div>





        




        
        
      </div>
   
    </div>
  );
};

export default GirisSayfasi;
