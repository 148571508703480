import styles from "./urunliste.module.css";
import styles2 from "./AnaSayfaWeb.module.css";

import React, { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getDocs, getFirestore } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy, onSnapshot} from "firebase/firestore"; 
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { Link, useNavigate } from 'react-router-dom';
import AramaSayfasi from "./AramaSayfasi";
import Ymenuopen from "./acikmenu";
const firebaseConfig = {
  apiKey: "AIzaSyBvuq7FyhGZ1OuPuYMDlxIvNx-ip_WVC8E",
  authDomain: "myinventory-73dc6.firebaseapp.com",
  projectId: "myinventory-73dc6",
  storageBucket: "myinventory-73dc6.appspot.com",
  messagingSenderId: "1009595674740",
  appId: "1:1009595674740:web:618105201f35104b27e121",
  measurementId: "G-F89WMPB355"
};
const Urunlist = () => {

  const [usern, setusern] = useState("");
  const [firman, setfirman] = useState("");

  const auth = getAuth();
  const user = auth.currentUser;
  useEffect(() => {
  
    const updatedkod = [];
  
    const fetchData = async () => {
      try {
        
  
    
      
      const querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("id", "==", user.uid)
        )
      );
  
      for (const doc of querySnapshot.docs) {
  
        setusern(doc.data().user)
        setfirman(doc.data().firma)
        
      }
    }
  
    catch (error) {
  
      
  
  }
  
      
  
    };
      
    fetchData();
  
  }, []);

    const [urunlist, seturunlist] = useState([]);
    const [cesitlist, setcesitlist] = useState([]);
    const [kategorilist, setkategorilist] = useState([]);
    const [secilikategori, setsecilikategori] = useState("Tümü");




    const [urunAdi, setUrunAdi] = useState('');
    const [barkod, setbarkod] = useState('');
    const [adet, setadet] = useState(1);
    const [markalist, setmarkalist] = useState([]);
    const [kaynaklist, setkaynaklist] = useState([]);
  
    const [yenimarka, setyenimarka] = useState('');
    const [secilimarka, setsecilimarka] = useState('');
    const [yenikaynak, setyenikaynak] = useState('');
    const [secilikaynak, setsecilikaynak] = useState('');
  
    const [yenicesit, setyenicesit] = useState('');
    const [secilicesit, setsecilicesit] = useState('');
  
    const [aciklama, setaciklama] = useState('');



    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);


    

      

      const [kategorivisible, setkategorivisible] = useState(0);

    const handleToggleVisibility = () => {
      setkategorivisible(kategorivisible === 1 ? 0 : 1);
    }; 

    






    const getkategori = async () => {
    
        
        
    
      try {

      
  
        const fetchData = async () => {
          const cesitliste = [];

  
          const querySnapshot = await getDocs(
            query(
              collection(db, "urun_cesit"),where("sahip","==",localStorage.getItem('depo'))
            )
          ); 
        
          querySnapshot.forEach((doc) => {
  

              const data = {
                
                cesit: doc.data().cesit,
                
               
                
    
              };
              
              cesitliste.push(data);
              
            
  
  
          });
         
          setkategorilist(cesitliste)
          console.log(cesitliste)
        };  
        fetchData();
      
      }
      catch(error) {
        console.log("hata:",error)
      }
    
    };

      const doSomething = async() =>{
        //datayenile()
        getkategori()
          const fetchData = async () => {
      
          
      
      
            try {
              const fetchData = async () => {
                  const q = query(collection(db, "urunler"), where("sahip", "==", localStorage.getItem('depo')), orderBy("adet", "desc"));
          
                  const unsubscribe = onSnapshot(q, (querySnapshot) => {
                      const urunMap = new Map(); // Ürünleri depolamak için bir harita kullanıyoruz
          
                      querySnapshot.forEach((doc) => {
                          const urunId = doc.id;
                          const existingUrun = urunMap.get(doc.data().urun); // Bu ürün zaten haritada var mı?
          
                          if (existingUrun) {
                              // Eğer ürün varsa, adet miktarını arttır ve barkodları birleştir
                              existingUrun.adet += doc.data().adet;
                              existingUrun.giris += doc.data().giren;
                              existingUrun.cikis += doc.data().cikan;
  
                              existingUrun.barkod = existingUrun.barkod.concat(doc.data().barkod);
                          } else {
                              // Eğer ürün yoksa, yeni bir öğe olarak ekle
                              urunMap.set(doc.data().urun, {
                                  urun: doc.data().urun,
                                  aciklama: doc.data().aciklama,
                                  adet: doc.data().adet,
                                  idsi: urunId,
                                  giris: doc.data().giren,
                                  cikis: doc.data().cikan,
                                  imgurl: doc.data().imgurl,
                                  barkod: doc.data().barkod,
                                  cesit: doc.data().cesit,
                                  kaynak: doc.data().kaynak,
                                  marka: doc.data().marka,
                              });
                          }
                      });
          
                      // Haritadaki tüm öğeleri tek bir diziye dönüştür
                      const urunliste = Array.from(urunMap.values());
                      seturunlist(urunliste);
                  });
              };
              fetchData();
          } catch (error) {
              console.log("hata:", error);
          }
          
          
          };
      
      
      
      
        
      
          fetchData();       
        
        }

      useEffect(() =>{
        doSomething();
       },[])

      const kategorisecme = (isim) => {
        setsecilikategori(isim)
        setkategorivisible(0)
        if (isim == "Tümü")
        {
            doSomething()
        }
        else {
            yeniliste(isim)

        }



      };  
      const yeniliste = (kategori) => {
        

        const fetchData = async () => {
            seturunlist([])

            
          
    
    
            try {
      
            
        
              const fetchData = async () => {
      
          
      
      
                try {
                  const fetchData = async () => {
                      const q = query(collection(db, "urunler"), where("sahip", "==", localStorage.getItem('depo')),where("cesit","==",kategori), orderBy("adet", "desc"));
              
                      const unsubscribe = onSnapshot(q, (querySnapshot) => {
                          const urunMap = new Map(); // Ürünleri depolamak için bir harita kullanıyoruz
              
                          querySnapshot.forEach((doc) => {
                              const urunId = doc.id;
                              const existingUrun = urunMap.get(doc.data().urun); // Bu ürün zaten haritada var mı?
              
                              if (existingUrun) {
                                  // Eğer ürün varsa, adet miktarını arttır ve barkodları birleştir
                                  existingUrun.adet += doc.data().adet;
                                  existingUrun.giris += doc.data().giren;
                                  existingUrun.cikis += doc.data().cikan;
      
                                  existingUrun.barkod = existingUrun.barkod.concat(doc.data().barkod);
                              } else {
                                  // Eğer ürün yoksa, yeni bir öğe olarak ekle
                                  urunMap.set(doc.data().urun, {
                                      urun: doc.data().urun,
                                      aciklama: doc.data().aciklama,
                                      adet: doc.data().adet,
                                      idsi: urunId,
                                      giris: doc.data().giren,
                                      cikis: doc.data().cikan,
                                      imgurl: doc.data().imgurl,
                                      barkod: doc.data().barkod,
                                      cesit: doc.data().cesit,
                                      kaynak: doc.data().kaynak,
                                      marka: doc.data().marka,
                                  });
                              }
                          });
              
                          // Haritadaki tüm öğeleri tek bir diziye dönüştür
                          const urunliste = Array.from(urunMap.values());
                          seturunlist(urunliste);
                      });
                  };
                  fetchData();
              } catch (error) {
                  console.log("hata:", error);
              }
              
              
              };
              fetchData();
            
            }
            catch(error) {
              console.log("hata:",error)
            }
          
          };
      
      
      
      
        
      
          fetchData();

        
      }; 

      const tumliste = () => {
        

        const fetchData = async () => {
            seturunlist([])

        
    
    
            try {
      
            
        
              const fetchData = async () => {
                const cesitliste = [];
      
        
                const querySnapshot = await getDocs(
                  query(
                    collection(db, "urunler"),where("sahip","==",localStorage.getItem('depo'))
                  )
                ); 
              
                querySnapshot.forEach((doc) => {
        
      
                    const data = {
                      urun: doc.data().urun,
                    aciklama: doc.data().aciklama,
                    idsi : doc.id,
                    giren: doc.data().giren,
                    cikan: doc.data().cikan,
                    imgurl : doc.data().imgurl,

                    adet: doc.data().adet,
                    barkod: doc.data().barkod,
                    cesit: doc.data().cesit,
                    kaynak: doc.data().kaynak,
                    marka: doc.data().marka,
                    giris: doc.data().giren,
                    cikis: doc.data().cikan,
                      
          
                    };
                    cesitliste.push(data);
                    
                  
        
        
                });
               
                seturunlist(cesitliste)
              };  
              fetchData();
            
            }
            catch(error) {
              
            }
          
          };
      
      
      
      
        
      
          fetchData();

        
      }; 
      const [navvisible, setnavvisible] = useState(0);

      const handleClick = () => {
      
        setnavvisible(navvisible === 1 ? 0 : 1);
    
      };
      const  navfunc = async (nere) => {
         
        navigate(nere);
      };

      const  eklefunc = async () => {
        seteklevisible(eklevisible === 1 ? 0 : 1);
        console.log("eklee")

      };

      const [imgurl, setimgurl] = useState('');

      const  godetay = async (nere) => {
         
        navigate("/panel/stokbilgi?id="+nere);
      };
      const navigate = useNavigate();

      const [eklevisible, seteklevisible] = useState(0);




      const kayittamamla = async () => {

        const now = new Date();
        const formattedDateTime = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`;
        try {
          const docRef = await addDoc(collection(db, "urunler"), {
            urun: urunAdi,
            barkod: barkod,
            adet: isNaN(parseInt(adet)) ? 0 : parseInt(adet),
            cesit: secilicesit,
            sahip:localStorage.getItem('depo'),  
            marka: secilimarka,
            kaynak: secilikaynak,
            imgurl : imgurl,

            giren: adet,
            cikan: 0,
            son_giris : formattedDateTime,
            tarih : formattedDateTime,
            tariht: now,
            giren: isNaN(parseInt(adet)) ? 0 : parseInt(adet),
            aciklama:aciklama,
            
          });
      
          window.location.reload();

      
        } catch (error) {
          
        }
      }
    


      const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {

    setShowPopup(!showPopup);
  };




    
      useEffect(() => {
        const fetchData = async () => {
    
        
    
    
          try {
    
          
      
            const fetchData = async () => {
              const cesitliste = [];
    
      
              const querySnapshot = await getDocs(
                query(
                  collection(db, "urun_cesit"),where("sahip","==",localStorage.getItem('depo'))
                )
              ); 
            
              querySnapshot.forEach((doc) => {
      
    
                  const data = {
                    cesit: doc.data().cesit,
                   
                    
        
                  };
                  cesitliste.push(data);
                  
                
      
      
              });
             
              setcesitlist(cesitliste)
            };  
            fetchData();
          
          }
          catch(error) {
            
          }
        
        };
    
    
    
    
      
    
        fetchData();
        
      }, []);
      
      useEffect(() => {
        const fetchData = async () => {
    
        
    
    
          try {
    
          
      
            const fetchData = async () => {
              const markaliste = [];
    
      
              const querySnapshot = await getDocs(
                query(
                  collection(db, "urun_marka"),where("sahip","==",localStorage.getItem('depo'))
                )
              ); 
            
              querySnapshot.forEach((doc) => {
      
    
                  const data = {
                    marka: doc.data().marka,
                   
                    
        
                  };
                  markaliste.push(data);
                  
                
      
      
              });
             
              setmarkalist(markaliste)
            };  
            fetchData();
          
          }
          catch(error) {
            
          }
        
        };
    
    
    
    
      
    
        fetchData();
        
      }, []);
      
      useEffect(() => {
        const fetchData = async () => {
    
        
    
    
          try {
    
          
      
            const fetchData = async () => {
              const kaynakliste = [];
    
      
              const querySnapshot = await getDocs(
                query(
                  collection(db, "urun_kaynak"),where("sahip","==",localStorage.getItem('depo'))
                )
              ); 
            
              querySnapshot.forEach((doc) => {
      
    
                  const data = {
                    kaynak: doc.data().kaynak,
                   
                    
        
                  };
                  kaynakliste.push(data);
                  
                
      
      
              });
             
              setkaynaklist(kaynakliste)
            };  
            fetchData();
          
          }
          catch(error) {
            
          }
        
        };
    
    
    
    
      
    
        fetchData();
        
      }, []);
      
    
      const [cesiteklevisible, setcesiteklevisible] = useState(0);
    
      const [cesitvisible, setcesitvisible] = useState(0);
    
      const [markaeklevisible, setmarkaeklevisible] = useState(0);
    
      const [kaynakeklevisible, setkaynakeklevisible] = useState(0);
    
      const handleToggleVisibilitycesit = () => {
        setcesitvisible(cesitvisible === 1 ? 0 : 1);
      };  
    
      const cesitsecme = (isim) => {
        setsecilicesit(isim)
        setcesitvisible(0)
      };  
    
      const kaynaksecme = (isim) => {
        setsecilikaynak(isim)
        setkaynakeklevisible(0)
        setcesitvisiblekaynak(0)
      };  
    
      const handleTogglecesiteklevisible = () => {
        setcesiteklevisible(cesiteklevisible === 1 ? 0 : 1);
      };
      const handleTogglekaynakeklevisible = () => {
        setkaynakeklevisible(kaynakeklevisible === 1 ? 0 : 1);
      };
    
      const handleTogglecesitonay = () => {
    
    
        const now = new Date();
        const formattedDateTime = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`;
        try {
          const docRef = addDoc(collection(db, "urun_cesit"), {
            cesit: yenicesit,
            tarih:formattedDateTime
            
          });
    
          setcesiteklevisible(0)
          setsecilicesit(yenicesit)
          setcesitvisible(0)
    
    
      
      
      
        } catch (error) {
          
        }
    
    
    
      };  
    
    
    
      const handleTogglekaynakonay = () => {
    
    
        const now = new Date();
        const formattedDateTime = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`;
        try {
          const docRef = addDoc(collection(db, "urun_kaynak"), {
            kaynak: yenikaynak,
            tarih:formattedDateTime
            
          });
    
          setkaynakeklevisible(0)
          setsecilikaynak(yenikaynak)
          setkaynakeklevisible(0)
    
    
      
      
      
        } catch (error) {
          
        }
    
    
    
      };  
    
    
    
      const markasecme = (isim) => {
        setsecilimarka(isim)
        setcesitvisiblemarka(0)
      };  
    
    
      const handleTogglemarkaeklevisible = () => {
        setmarkaeklevisible(markaeklevisible === 1 ? 0 : 1);
      };
    
      const handleTogglemarkaonay = () => {
    
    
        const now = new Date();
        const formattedDateTime = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`;
        try {
          const docRef = addDoc(collection(db, "urun_marka"), {
            marka: yenimarka,
            tarih:formattedDateTime
            
          });
    
          setcesitvisiblemarka(0)
          setsecilimarka(yenimarka)
          setcesitvisiblemarka(0)
    
    
      
      
      
        } catch (error) {
          
        }
    
    
    
      };  
    
    
    
    
      const [cesitvisiblemarka, setcesitvisiblemarka] = useState(0);
    
      const handleToggleVisibilitymarka = () => {
        setcesitvisiblemarka(cesitvisiblemarka === 1 ? 0 : 1);
      }; 
    
      const [cesitvisiblekaynak, setcesitvisiblekaynak] = useState(0);
    
      const handleToggleVisibilitykaynak = () => {
        setcesitvisiblekaynak(cesitvisiblekaynak === 1 ? 0 : 1);
      }; 
    
      const handleToggleadetart = () => {

        setadet(parseInt(adet) + 1)
      }; 
      const handleToggleadetcikart = () => {
        if(adet > 1) {
          setadet(parseInt(adet) - 1)
    
        }
      }; 
    

      const [arama, setarama] = useState(0);

      const handleAramaChange = (newValue) => {
    
        setarama(arama === 1 ? 0 : 1);
    
       
      };

      if (arama === 0 ) {

        const closePopup = () => {
          setShowPopup(false);
        };
      
  return (





<div className={styles.urunListesiN}>

      <div className={styles.navigationDrawerParent}>


      <Ymenuopen data="urunler"/>


      {showPopup == true ?
        <div className="popup-overlay"  onClick={closePopup}>
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <div className="popup-inner">







            <div className={styles.headerParent}>
        <div className={styles.header}>
          <div className={styles.ctaButton} onClick={(e) => {
                e.stopPropagation(); 
                togglePopup();
              }}>
            <img
              className={styles.uiIconclosefilled}
              alt=""
              src="/arrowleft.svg"
            />
          </div>
          <b className={styles.rnEkle}>Stok Ekle</b>
        </div>
        <div className={styles.frameParent2}>
          <div className={styles.frameParent3}>
            <div className={styles.taskNameParent}>
              <div className={styles.taskName}>
                <div className={styles.placeholder}>
                  <div className={styles.smallLabel}>Ürün Adı</div>
                </div>
                <div className={styles.container2}>
                  <div className={styles.placeholder}>
                  <input
          className={styles.smallLabelxx}
          placeholder="Ürün Adı"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setUrunAdi(e.target.value);
          }}
        />                  </div>
                </div>
              </div>



              <div className={styles.priorityLevelDropdown1}>
                <div className={styles.placeholder}>
                  <div className={styles.smallLabel}>Marka</div>
                </div>


                <div className={styles.container1} onClick={(e) => {
                e.stopPropagation(); 
                handleToggleVisibilitymarka();
              }}>
                  <div className={styles.placeholder}>
                    <div className={styles.left1}>
                      <div className={styles.smallLabel}>{secilimarka}</div>
                    </div>
                    <img
                      className={styles.uiIconclosefilled}
                      alt=""
                      src="/chevrondown.svg"
                    />
                  </div>
                </div>


                {cesitvisiblemarka === 1 ? 

                <div className={styles.dropdownMenu}>
{markalist.map((item) => (

                  <div className={styles.optionA2} onClick={(e) => {
                    e.stopPropagation(); 
                    markasecme(item.marka);
                  }}>
                    <div className={styles.smallLabel}>{item.marka}</div>
                  </div>
                  
))}


                </div>
: null }


              </div>
            </div>




            <div className={styles.priorityLevelDropdownParent}>
              <div className={styles.priorityLevelDropdown2}>
                <div className={styles.placeholder}>
                  <div className={styles.smallLabel}>Ürün Çeşidi</div>
                </div>


                <div className={styles.container1} onClick={(e) => {
                e.stopPropagation(); 
                handleToggleVisibilitycesit();
              }}>
                  <div className={styles.placeholder}>
                    <div className={styles.left1}>
                      <div className={styles.smallLabel}>{secilicesit}</div>
                    </div>
                    <img
                      className={styles.uiIconclosefilled}
                      alt=""
                      src="/chevrondown.svg"
                    />
                  </div>
                </div>

                {cesitvisible === 1 ? 

                <div className={styles.dropdownMenu}>

{cesitlist.map((item) => (

                  <div className={styles.optionA2} onClick={(e) => {
                    e.stopPropagation(); 
                    cesitsecme(item.cesit);
                  }} >
                    <div className={styles.smallLabel}>{item.cesit}</div>
                  </div>
))}





                </div>

                : null }


              </div>
              <div className={styles.taskName1}>
                <div className={styles.placeholder}>
                  <div className={styles.smallLabel}>Barkod</div>
                </div>
                <div className={styles.containerParent}>
                  <div className={styles.container5}>
                    <div className={styles.placeholder}>
                      
                      <input
          className={styles.smallLabel}
          placeholder="Barkod"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setbarkod(e.target.value);
          }}
        />
                    </div>
                    
                  </div>
                  <img
                        className={styles.barcodeReaderIcon}
                        alt=""
                        src="/barcode-reader@2x.png"
                      />
                </div>
              </div>
            </div>




          </div>

          <div className={styles.priorityLevelDropdownGroup}>
            <div className={styles.priorityLevelDropdown3}>
              <div className={styles.placeholder}>
                <div className={styles.smallLabel}>Ürün Kaynağı</div>
              </div>
              <div className={styles.container1} onClick={(e) => {
                e.stopPropagation(); 
                handleToggleVisibilitykaynak();
              }}>
                <div className={styles.placeholder}>
                  <div className={styles.left1}>
                    <div className={styles.smallLabel}>{secilikaynak}</div>
                  </div>
                  <img
                    className={styles.uiIconclosefilled}
                    alt=""
                    src="/chevrondown.svg"
                  />
                </div>
              </div>
              {cesitvisiblekaynak === 1 ? 

              <div className={styles.dropdownMenu}>

{kaynaklist.map((item) => (

                <div className={styles.optionA2} onClick={(e) => {
                  e.stopPropagation(); 
                  kaynaksecme(item.kaynak);
                }}>
                  <div className={styles.smallLabel}>{item.kaynak}</div>
                </div>
))}
                
              </div>
: null }

            </div>





            <div className={styles.taskName2}>
              <div className={styles.placeholder}>
                <div className={styles.smallLabel}>Adet</div>
              </div>
              <div className={styles.containerParent}>
                <div className={styles.container5}>
                  <div className={styles.placeholder}>
                  <input
          className={styles.smallLabel}
          placeholder="Adet"
          value={adet.toString()}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            setadet(e.target.value);
          }}
        />                  </div>
                </div>
                <img
                  className={styles.homePageIcon}
                  onClick={(e) => {
                    e.stopPropagation(); 
                    handleToggleadetcikart();
                  }}
                  alt=""
                  src="/negative@2x.png"
                />
                <img
                onClick={(e) => {
                  e.stopPropagation(); 
                  handleToggleadetart();
                }}
                  className={styles.homePageIcon}
                  alt=""
                  src="/add-new@2x.png"
                />
              </div>
            </div>
          </div>


          <div className={styles.taskName3}>
            <div className={styles.placeholder}>
              <div className={styles.smallLabel}>Açıklama/Not</div>
            </div>
            <div className={styles.container8}>
              <div className={styles.placeholder}>
               
                <input
          className={styles.aklamaEkleaklamaEkleak}
          placeholder="Açıklama/Not"
          
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setaciklama(e.target.value);
          }}
        />   
              </div>
            </div>
          </div>
          


          <div className={styles.ctaButton1} onClick={(e) => {
                  e.stopPropagation(); 
                  kayittamamla();
                }}>
            <div className={styles.label9}>
              <div className={styles.xlLabel}>Ekle</div>
            </div>
          </div>
        </div>
      </div>












            </div>
          </div>
        </div>
      : null }



<div className={styles.frameParent}>
        <div className={styles.menu01Parent}>
         
        <div className={styles.inputField}>
            <div className={styles.inputFieldBase}>
              <div className={styles.inputFieldBase}>
                <div className={styles.label}>Email</div>
                <div className={styles.input}>
                  <div className={styles.content}>
                    <img
                      className={styles.searchSmIcon}
                      alt=""
                      src="/searchsm.svg"
                    />
                    <div className={styles.text} onClick={(e) => {
                e.stopPropagation(); 
                handleAramaChange();
              }}>Ara</div>
                  </div>
                  <img
                    className={styles.helpIcon}
                    alt=""
                    src="/help-icon.svg"
                  />
                </div>
              </div>
              
            </div>
          </div>
          
        </div>
        <div className={styles.productsListParent}>
          <div className={styles.productsList}>
            <div className={styles.productsList1}>Stok Listesi ({urunlist.length})</div>
            <div className={styles.add} onClick={(e) => {
                e.stopPropagation(); 
                togglePopup();
              }} >
              <div className={styles.btnSend}>
                <div className={styles.shape} />
              </div>
              <img
                className={styles.componenticonicPlus}
                alt=""
                src="/componenticonic-plus.svg"
              />
            </div>
          </div>
          <div className={styles.priorityLevelDropdown}>
           



            <div className={styles.container1} >
              <div className={styles.placeholder} onClick={handleToggleVisibility}>
                <div className={styles.left1}>
                  <div className={styles.smallLabel}>{secilikategori}</div>
                </div>
                <img
                  className={styles.uiIconclosefilled}
                  alt=""
                  src="/chevrondown.svg"
                />
              </div>
            </div>

{kategorivisible === 1 ? 
            <div className={styles.dropdownMenu}>

<div className={styles.optionA} onClick={(e) => {
                e.stopPropagation(); 
                kategorisecme("Tümü");
              }}>
                <div className={styles.smallLabel}>Tümü</div>
              </div>
{kategorilist.map((item) => (

              
              <div key={item.cesit} className={styles.optionA1} onClick={(e) => {
                e.stopPropagation(); 
                kategorisecme(item.cesit);
              }}>
                <div key={item.cesit}  className={styles.smallLabel}>{item.cesit}</div>
              </div>
))}

            </div>

:null}
          </div>


          <div className={styles.frameGroup}>


          {urunlist.map((item) => (



            <div key={item.idsi}  className={styles.frameWrapper} onClick={(e) => {
              e.stopPropagation(); 
              godetay(item.urun);
            }}>
              <div className={styles.frameContainer}>
                <div className={styles.frameParent1}>
                  <div className={styles.image8Parent}>
                  {item.imgurl ==="" ? 

                    <img
                      className={styles.image8Icon}
                      alt=""
                      src="/wicon.png"
                                          />
 :  <img
 className={styles.image8Icon}
 alt=""
 src= {item.imgurl}
/> }
                    <div className={styles.backpackRiddParent}>
                      <div className={styles.backpackRidd}>{item.urun}</div>
                      <div className={styles.markaId}>{item.marka}</div>
                    </div>
                  </div>
                  {item.adet > 0 ? <div className={styles.midRisk}>
                    <div className={styles.midRisk1}>Stokta ({item.adet})</div>
                  </div> : <div className={styles.midRisky}>
                    <div className={styles.midRisk1}>Stokta Yok ({item.adet})</div>
                  </div>}
                  
                </div>
                <div className={styles.idParent}>
                  <div className={styles.id}>
                    <div className={styles.smallLabel}>Barkod</div>


                    { item.barkod[1] !== undefined ? 

<div className={styles.div9}>
 {(item.barkod).length} Adet
</div>
:  <div className={styles.div9}>
{(item.barkod)}
</div> }


                  </div>
                  <div className={styles.var}>
                    <div className={styles.smallLabel}>Toplam Giriş</div>
                    <div className={styles.div9}>{item.giris}</div>
                  </div>
                  <div className={styles.var}>
                    <div className={styles.smallLabel}>Toplam Çıkış</div>
                    <div className={styles.div9}>{item.cikis}</div>
                  </div>
                </div>
              </div>
            </div>



          ))}


            
            
          </div>


        </div>
      </div>
      </div>


      {eklevisible === 1 ? 

      <div className={styles.headerParent}>
        <div className={styles.header}>
          <div className={styles.ctaButton} onClick={(e) => {
                e.stopPropagation(); 
                eklefunc();
              }}>
            <img
              className={styles.uiIconclosefilled}
              alt=""
              src="/arrowleft.svg"
            />
          </div>
          <b className={styles.rnEkle}>Stok Ekle</b>
        </div>
        <div className={styles.frameParent2}>
          <div className={styles.frameParent3}>
            <div className={styles.taskNameParent}>
              <div className={styles.taskName}>
                <div className={styles.placeholder}>
                  <div className={styles.smallLabel}>Ürün Adı</div>
                </div>
                <div className={styles.container2}>
                  <div className={styles.placeholder}>
                  <input
          className={styles.smallLabel}
          placeholder="Ürün Adı"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setUrunAdi(e.target.value);
          }}
        />                  </div>
                </div>
              </div>



              <div className={styles.priorityLevelDropdown1}>
                <div className={styles.placeholder}>
                  <div className={styles.smallLabel}>Marka</div>
                </div>


                <div className={styles.container1} onClick={(e) => {
                e.stopPropagation(); 
                handleToggleVisibilitymarka();
              }}>
                  <div className={styles.placeholder}>
                    <div className={styles.left1}>
                      <div className={styles.smallLabel}>{secilimarka}</div>
                    </div>
                    <img
                      className={styles.uiIconclosefilled}
                      alt=""
                      src="/chevrondown.svg"
                    />
                  </div>
                </div>


                {cesitvisiblemarka === 1 ? 

                <div className={styles.dropdownMenu}>
{markalist.map((item) => (

                  <div className={styles.optionA2} onClick={(e) => {
                    e.stopPropagation(); 
                    markasecme(item.marka);
                  }}>
                    <div className={styles.smallLabel}>{item.marka}</div>
                  </div>
                  
))}


                </div>
: null }


              </div>
            </div>




            <div className={styles.priorityLevelDropdownParent}>
              <div className={styles.priorityLevelDropdown2}>
                <div className={styles.placeholder}>
                  <div className={styles.smallLabel}>Ürün Çeşidi</div>
                </div>


                <div className={styles.container1} onClick={(e) => {
                e.stopPropagation(); 
                handleToggleVisibilitycesit();
              }}>
                  <div className={styles.placeholder}>
                    <div className={styles.left1}>
                      <div className={styles.smallLabel}>{secilicesit}</div>
                    </div>
                    <img
                      className={styles.uiIconclosefilled}
                      alt=""
                      src="/chevrondown.svg"
                    />
                  </div>
                </div>

                {cesitvisible === 1 ? 

                <div className={styles.dropdownMenu}>

{cesitlist.map((item) => (

                  <div className={styles.optionA2} onClick={(e) => {
                    e.stopPropagation(); 
                    cesitsecme(item.cesit);
                  }} >
                    <div className={styles.smallLabel}>{item.cesit}</div>
                  </div>
))}





                </div>

                : null }


              </div>
              <div className={styles.taskName1}>
                <div className={styles.placeholder}>
                  <div className={styles.smallLabel}>Barkod</div>
                </div>
                <div className={styles.containerParent}>
                  <div className={styles.container5}>
                    <div className={styles.placeholder}>
                      
                      <input
          className={styles.smallLabel}
          placeholder="Barkod"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setbarkod(e.target.value);
          }}
        />
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>




          </div>

          <div className={styles.priorityLevelDropdownGroup}>
            <div className={styles.priorityLevelDropdown3}>
              <div className={styles.placeholder}>
                <div className={styles.smallLabel}>Ürün Kaynağı</div>
              </div>
              <div className={styles.container1} onClick={(e) => {
                e.stopPropagation(); 
                handleToggleVisibilitykaynak();
              }}>
                <div className={styles.placeholder}>
                  <div className={styles.left1}>
                    <div className={styles.smallLabel}>{secilikaynak}</div>
                  </div>
                  <img
                    className={styles.uiIconclosefilled}
                    alt=""
                    src="/chevrondown.svg"
                  />
                </div>
              </div>
              {cesitvisiblekaynak === 1 ? 

              <div className={styles.dropdownMenu}>

{kaynaklist.map((item) => (

                <div className={styles.optionA2} onClick={(e) => {
                  e.stopPropagation(); 
                  kaynaksecme(item.kaynak);
                }}>
                  <div className={styles.smallLabel}>{item.kaynak}</div>
                </div>
))}
                
              </div>
: null }

            </div>





            <div className={styles.taskName2}>
              <div className={styles.placeholder}>
                <div className={styles.smallLabel}>Adet</div>
              </div>
              <div className={styles.containerParent}>
                <div className={styles.container5}>
                  <div className={styles.placeholder}>
                  <input
          className={styles.smallLabel}
          placeholder="Adet"
          value={adet.toString()}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            setadet(e.target.value);
          }}
        />                  </div>
                </div>
                <img
                  className={styles.homePageIcon}
                  onClick={(e) => {
                    e.stopPropagation(); 
                    handleToggleadetcikart();
                  }}
                  alt=""
                  src="/negative@2x.png"
                />
                <img
                onClick={(e) => {
                  e.stopPropagation(); 
                  handleToggleadetart();
                }}
                  className={styles.homePageIcon}
                  alt=""
                  src="/add-new@2x.png"
                />
              </div>
            </div>
          </div>


          <div className={styles.taskName3}>
            <div className={styles.placeholder}>
              <div className={styles.smallLabel}>Açıklama/Not</div>
            </div>
            <div className={styles.container8}>
              <div className={styles.placeholder}>
               
                <input
          className={styles.aklamaEkleaklamaEkleak}
          placeholder="Açıklama/Not"
          
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setaciklama(e.target.value);
          }}
        />   
              </div>
            </div>
          </div>
          


          <div className={styles.ctaButton1} onClick={(e) => {
                  e.stopPropagation(); 
                  kayittamamla();
                }}>
            <div className={styles.label9}>
              <div className={styles.xlLabel}>Ekle</div>
            </div>
          </div>
        </div>
      </div>
: null}


    </div>
















  );
              }
              else {
                return(
                <AramaSayfasi handleAramaChange={handleAramaChange}/>
                )
                }


};

export default Urunlist;
