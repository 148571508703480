import React, { useState } from 'react';
import * as XLSX from 'xlsx';

function App() {
  const [data, setData] = useState([]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetNames = workbook.SheetNames;
      console.log("sayfalar:",sheetNames)  
      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      setData(excelData);

      const headers = excelData[0];
      console.log('Tablo Başlıkları:');
      console.log(headers);
      const targetHeader = headers[2]; // Değiştirilecek

      const columnIndex = headers.indexOf(targetHeader);
    if (columnIndex !== -1) {
      console.log(`'${targetHeader}' başlığındaki veriler:`);
      for (let i = 1; i < excelData.length; i++) {
        console.log(excelData[i][columnIndex]);
      }
    } else {
      console.log(`'${targetHeader}' başlığı bulunamadı.`);
    }
    };

    reader.readAsBinaryString(file);
  };

  return (
    <div>
      <input type="file" onChange={handleFileUpload} />
      <hr />
      <h2>Excel Data</h2>
      <table>
        <thead>
          <tr>
            {data.length > 0 &&
              data[0].map((header, index) => (
                <th key={index}>{header}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default App;
