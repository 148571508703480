import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import AnaSayfaWeb from "./pages/AnaSayfaWeb";
import Urunlist from "./pages/urunliste";
import Gelengiden from "./pages/Gelengiden"
import { useTranslation } from 'react-i18next';
import "./pages/i18n"
import DurumGncelleme from "./pages/DurumGncelleme"
import StokBilgi from "./pages/StokBilgi"
import MusteriListesi from "./pages/MusteriListesi"
import GirisSayfasi from "./pages/GirisSayfasi"
import TedarikciListesi from "./pages/TedarikciListesi"
import ExcelReader  from "./pages/exceldenyukle";
import Exceldengecis from "./pages/Exceldengecis";
import YeniGiris from "./pages/yenigiris";
import BelgeCreateYeni from "./pages/BelgeCreateYeni";
import Belgeyap from "./pages/belgeyap";
function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/panel" element={<AnaSayfaWeb />} />
      <Route path="/panel/stok" element={<Urunlist/>} />
      <Route path="/panel/veriakisix" element={<ExcelReader />} />
      <Route path="/panel/veriakisi" element={<Exceldengecis />} />
      <Route path="/panel/belgeolustur" element={<Belgeyap/>} />

      <Route path="/panel/gelengiden" element={<Gelengiden/>} />
      <Route path="/panel/gelengiden/durum" element={<DurumGncelleme/>} />
      <Route path="/panel/stokbilgi" element={<StokBilgi/>} />
      <Route path="/panel/musteriler" element={<MusteriListesi/>} />
      <Route path="/panel/tedarikciler" element={<TedarikciListesi/>} />

      <Route path="/" element={<YeniGiris/>} />

    </Routes>
  );
}
export default App;
