import styles from "./AramaSayfasi.module.css";
import styles2 from "./AnaSayfaWeb.module.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import React, { useEffect, useState,useRef } from 'react';
import { initializeApp } from "firebase/app";
import { getDocs, getFirestore } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy,getDoc,doc} from "firebase/firestore"; 
import Ymenuopen from "./acikmenu";

import { PieChart } from '@mui/x-charts/PieChart';

import { Link, useNavigate } from 'react-router-dom';


const firebaseConfig = {
  apiKey: "AIzaSyBvuq7FyhGZ1OuPuYMDlxIvNx-ip_WVC8E",
  authDomain: "myinventory-73dc6.firebaseapp.com",
  projectId: "myinventory-73dc6",
  storageBucket: "myinventory-73dc6.appspot.com",
  messagingSenderId: "1009595674740",
  appId: "1:1009595674740:web:618105201f35104b27e121",
  measurementId: "G-F89WMPB355"
};


const AramaSayfasi = ({ handleAramaChange }) => {

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const textInputRef = useRef(null);
  const [musterilist, setMusterilist] = useState([]);
  const [tedarikcilist, setTedarikcilist] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [urunlist, seturunlist] = useState([]);
  const [gglist, setgglist] = useState([]);

  const [musterilistarama, setMusterilistarama] = useState([]);
  const [tedarikcilistarama, setTedarikcilistarama] = useState([]);
  const [urunlistarama, seturunlistarama] = useState([]);
  const [gglistarama, setgglistarama] = useState([]);


  const [navvisible, setnavvisible] = useState(0);

  const handleClickarama = () => {
    // Yeni değeri burada ayarlayabilirsiniz
    handleAramaChange(1); // Örneğin, 2 olarak ayarlayalım
};
  const handleClick = () => {
  
    setnavvisible(navvisible === 1 ? 0 : 1);

  };
  const  navfunc = async (nere) => {
    
     
    navigate(nere);
  };
  const navigate = useNavigate();



  const musteri_liste = async () => {
    try {
      const musteriliste = [];
      const querySnapshot = await getDocs(
        query(
          collection(db, "musteri_listesi")
          
        )
      );
      querySnapshot.forEach((doc) => {
        const data = {
          musteri: doc.data().musteri,
          email: doc.data().email,
          firma: doc.data().firma,
          telefon: doc.data().telefon,
          not: doc.data().not,
        };
        musteriliste.push(data);
      });
      
      setMusterilist(musteriliste)

    } catch (error) {
      console.error("musteri_liste Hastasssı:", error);
    }
  };

  const urun_liste = async () => {
    
        
    
    
    try {

    

        const urunliste = [];

        const querySnapshot = await getDocs(
          query(
            collection(db, "urunler"),where("sahip","==",localStorage.getItem('depo'))
          )
        ); 
      
        querySnapshot.forEach((doc) => {


            const data = {
              urun: doc.data().urun,
              aciklama: doc.data().aciklama,
              adet: doc.data().adet,
              idsi: doc.id,
              palet: doc.data().palet,
              barkod: doc.data().barkod,
              cesit: doc.data().cesit,
              kaynak: doc.data().kaynak,
              marka: doc.data().marka,
              giren: doc.data().giren,
              cikan: doc.data().cikan,




              
  
            };
            urunliste.push(data);

          


        });
       
        seturunlist(urunliste)
    
    }
    catch(error) {
      console.log("hata:",error)
    }
  
  };

  const tedarikci_liste = async () => {
    try {
      const tedarikciliste = [];
      const querySnapshot = await getDocs(
        query(collection(db, "tedarikci_listesi"), orderBy("tariht", "desc"))
      );
      querySnapshot.forEach((doc) => {
        const data = {
          tedarikci: doc.data().tedarikci,
          email: doc.data().email,
          firma: doc.data().firma,
          telefon: doc.data().telefon,
          not: doc.data().not,
        };
        tedarikciliste.push(data);
      });
      setTedarikcilist(tedarikciliste);
    } catch (error) {
      console.error("tedarikci_liste Hatası:", error);
    }
  };



  const gelengiden_liste = async () => {
    
        
    const ggliste = [];

    
    try {

    



        const querySnapshot = await getDocs(
          query(
            collection(db, "gidecek_urun_kayit"),orderBy("tariht","desc")
          )
        ); 
      
        querySnapshot.forEach((doc) => {


            const data = {
              urun: doc.data().urun,
              aciklama: doc.data().aciklama,
              adet: doc.data().adet,
              barkod: doc.data().barkod,
              cesit: doc.data().cesit,
              kaynak: doc.data().kaynak,
              marka: doc.data().marka,
              baslik: doc.data().baslik,
              tarih: doc.data().tarih,
              durum: doc.data().durum,
              id: doc.id,
              giren: doc.data().giren,
              cikan: doc.data().cikan,


              
  
            };
            ggliste.push(data);
            
          


        });

        const querySnapshot2 = await getDocs(
            query(
              collection(db, "gelecek_urun_kayit"),orderBy("tariht","desc")
            )
          ); 
        
          querySnapshot2.forEach((doc) => {
  
  
              const data = {
                urun: doc.data().urun,
                aciklama: doc.data().aciklama,
                adet: doc.data().adet,
                barkod: doc.data().barkod,
                cesit: doc.data().cesit,
                kaynak: doc.data().kaynak,
                marka: doc.data().marka,
                baslik: doc.data().baslik,
                tarih: doc.data().tarih,
                durum: doc.data().durum,
                id: doc.id,
                giren: doc.data().giren,
                cikan: doc.data().cikan,
  
                
    
              };
              ggliste.push(data);
              
            
  
  
          });


       
        setgglist(ggliste)
      
    
    }
    catch(error) {
      
    }
  
  };



  const fetchData = async () => {
    try {
      await gelengiden_liste();
      await musteri_liste();
      await tedarikci_liste();
      await urun_liste();
    } catch (error) {
      console.error("fetchData Hatası:", error);
    }
  };

  const onRefresh = async () => {
    setRefreshing(true);
    try {
      await fetchData();
    } catch (error) {
      console.error("onRefresh Hatası:", error);
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const [arama, setArama] = useState('');

  const handleSearch = () => {

    const l1 = []
    const l2 = []
    const l3 = []
    const l4 = []



    setMusterilistarama(musterilist.filter(item => (item.musteri.includes(arama) || item.email.includes(arama)) || item.firma.includes(arama) || item.not.includes(arama)));

    setTedarikcilistarama(tedarikcilist.filter(item => item.tedarikci.includes(arama) || item.email.includes(arama) || item.firma.includes(arama) || item.not.includes(arama) ));
    
    

    seturunlistarama(urunlist.filter(item => {
      var aramaDegeri = arama.toLowerCase();
  
      return (typeof item.urun === 'string' && item.urun.toLowerCase().includes(aramaDegeri)) ||
             (typeof item.aciklama === 'string' && item.aciklama.toLowerCase().includes(aramaDegeri)) ||
             (typeof item.marka === 'string' && item.marka.toLowerCase().includes(aramaDegeri)) ||
             (typeof item.cesit === 'string' && item.cesit.toLowerCase().includes(aramaDegeri)) ||
             (Array.isArray(item.barkod) && item.barkod.some(barkod => typeof barkod === 'string' && barkod.toLowerCase().includes(aramaDegeri)));
  })); 
    
    
    
    setgglistarama(gglist.filter(item => item.urun.includes(arama) || item.aciklama.includes(arama) || item.marka.includes(arama) || item.barkod.includes(arama) || item.cesit.includes(arama) || item.baslik.includes(arama)));


  };

  const handleChangeText = (text) => {
    setArama(text);
    
    handleSearch(); 
  };


  const [usern, setusern] = useState("");
  const [firman, setfirman] = useState("");

  const auth = getAuth();
  const user = auth.currentUser;
  useEffect(() => {
  
    const updatedkod = [];
  
    const fetchData = async () => {
      try {
        
  
    
      
      const querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("id", "==", user.uid)
        )
      );
  
      for (const doc of querySnapshot.docs) {
  
        setusern(doc.data().user)
        setfirman(doc.data().firma)
        
      }
    }
  
    catch (error) {
  
      
      
  
  }
  
      
  
    };
      
    fetchData();
  
  }, []);




  return (
    <div className={styles.aramaSayfasi}>

      
<Ymenuopen data="arama"/>



      <div className={styles.frameParent}>

        <div className={styles.frameWrapper}>

          <div className={styles.ctaButtonParent}>





            <div className={styles.inputField}>
              <div className={styles.inputFieldBase}>
                <div className={styles.inputFieldBase}>
                  <div className={styles.input}>
                    <div className={styles.content}>
                      <img
                        className={styles.searchSmIcon}
                        alt=""
                        src="/searchsm.svg"
                      />
                      <input
          className={styles.text}
          placeholder="Ara"
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            handleChangeText(e.target.value);
          }}
        />
                    </div>
                    <img
                      className={styles.helpIcon}
                      alt=""
                      src="/help-icon.svg"
                    />
                  </div>
                </div>
                
              </div>
            </div>
            <div className={styles.vazge} onClick={() => {
            handleClickarama();
          }} >Vazgeç</div>
          </div>

        </div>
        <div className={styles.frameGroup}>

        {
  musterilistarama.map((item, index) => (
          <div className={styles.frameContainer}>
            <div className={styles.frameParent1}>
              <div className={styles.image53x1Parent}>
                <img
                  className={styles.image53x1Icon}
                  alt=""
                  src="/image53x-1@2x.png"
                />
                <div className={styles.frameWrapper1}>
                  <div className={styles.canKraParent}>
                    <b className={styles.canKra}>{item.musteri}</b>
                    <div className={styles.aFirmas}>{item.firma}</div>
                  </div>
                </div>
              </div>
              <img className={styles.frameChild} alt="" src="/vector-1.svg" />
              <div className={styles.frameParent2}>
                <div className={styles.phoneParent}>
                  <img
                    className={styles.phoneIcon}
                    alt=""
                    src="/phone1@2x.png"
                  />
                  <div className={styles.krckrccorpnet}>{item.telefon}</div>
                </div>
                <div className={styles.emailSignParent}>
                  <img
                    className={styles.phoneIcon}
                    alt=""
                    src="/email-sign@2x.png"
                  />
                  <div className={styles.krckrccorpnet}>{item.email}</div>
                </div>
              </div>
              <div className={styles.notAlannotAlannotAlanWrapper}>
                <div className={styles.notAlannotAlannot}>
                {item.not}
                </div>
              </div>
            </div>
          </div>

  ))}

{
  tedarikcilistarama.map((item, index) => (
          <div className={styles.frameContainer}>
            <div className={styles.frameParent1}>
              <div className={styles.image53x1Parent}>
                <img
                  className={styles.image53x1Icon}
                  alt=""
                  src="/image53x-1@2x.png"
                />
                <div className={styles.frameWrapper1}>
                  <div className={styles.canKraParent}>
                    <b className={styles.canKra}>{item.tedarikci}</b>
                    <div className={styles.aFirmas}>{item.firma}</div>
                  </div>
                </div>
              </div>
              <img className={styles.frameChild} alt="" src="/vector-1.svg" />
              <div className={styles.frameParent2}>
                <div className={styles.phoneParent}>
                  <img
                    className={styles.phoneIcon}
                    alt=""
                    src="/phone1@2x.png"
                  />
                  <div className={styles.krckrccorpnet}>{item.telefon}</div>
                </div>
                <div className={styles.emailSignParent}>
                  <img
                    className={styles.phoneIcon}
                    alt=""
                    src="/email-sign@2x.png"
                  />
                  <div className={styles.krckrccorpnet}>{item.email}</div>
                </div>
              </div>
              <div className={styles.notAlannotAlannotAlanWrapper}>
                <div className={styles.notAlannotAlannot}>
                {item.not}
                </div>
              </div>
            </div>
          </div>

  ))}


  
{ gglistarama.map((item) => (

          <div className={styles.recommended}>
            <div className={styles.recommended1}>Oluşturulma Tarihi {item.tarih}</div>
            <div className={styles.funds1}>
              <div className={styles.frameParent3}>
                <div className={styles.frameParent4}>
                  <div className={styles.image53x1Group}>
                    <img
                      className={styles.image53x1Icon}
                      alt=""
                      src="/image53x-1@2x.png"
                    />
                    <div className={styles.newWorldFundParent}>
                      <div className={styles.newWorldFund}>{item.baslik}</div>
                      <div className={styles.industries}>{item.kaynak}</div>
                    </div>
                  </div>
                  <div className={styles.midRiskWrapper}>
                    <div className={styles.midRisk}>{item.durum}</div>
                  </div>
                </div>
                <div className={styles.frameParent5}>
                  <div className={styles.yearParent}>
                    <div className={styles.year}>
                      <div className={styles.year1}>Ürün</div>
                      <div className={styles.div10}>
                      {item.urun}
                      </div>
                    </div>
                    <div className={styles.year}>
                      <div className={styles.year1}>Marka</div>
                      <div className={styles.div10}>{item.marka}</div>
                    </div>
                    <div className={styles.year}>
                      <div className={styles.year1}>Model</div>
                      <div className={styles.div10}>{item.model}</div>
                    </div>
                    <div className={styles.year}>
                      <div className={styles.year1}>Adet</div>
                      <div className={styles.div10}>{item.adet}</div>
                    </div>
                  </div>
                  <div className={styles.year8}>
                    <div className={styles.year1}>Not</div>
                    <div className={styles.div14}>
                    {item.aciklama}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.categories} />
            </div>
          </div>
))}
{  urunlistarama.map((item) => (

          <div className={styles.frameParent6}>
            <div className={styles.frameParent7}>
              <div className={styles.left}>
                <img
                  className={styles.image53x1Icon}
                  alt=""
                  src="/image53x-2@2x.png"
                />
                <div className={styles.backpackRiddParent}>
                  <div className={styles.backpackRidd}>{item.urun}</div>
                  <div className={styles.markaId}>{item.marka}/ Palet No : {item.palet}</div>
                </div>
              </div>
              <div className={styles.div15}>Stokta ({item.adet})</div>
            </div>
            <div className={styles.idParent}>
              <div className={styles.id}>
                <div className={styles.id1}>Barkod</div>
                <div className={styles.div16}>{(item.barkod).length > 1 ? (item.barkod).length : item.barkod} Adet</div>
              </div>
              <div className={styles.stock}>
                <div className={styles.id1}>Toplam Giriş</div>
                <div className={styles.div16}>{item.giren}</div>
              </div>
              <div className={styles.stock}>
                <div className={styles.id1}>Toplam Çıkış</div>
                <div className={styles.div16}>{item.cikan}</div>
              </div>
            </div>
          </div>
))}

        </div>
      </div>
    </div>
  );
};

export default AramaSayfasi;
