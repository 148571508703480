import styles from "./Ymenuopen.module.css";
import styles2 from "./Ymenukapali.module.css";
import React, { useEffect, useState } from 'react';
import { documentId, getDocs, getFirestore } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy , onSnapshot} from "firebase/firestore"; 
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const firebaseConfig = {
  apiKey: "AIzaSyBvuq7FyhGZ1OuPuYMDlxIvNx-ip_WVC8E",
  authDomain: "myinventory-73dc6.firebaseapp.com",
  projectId: "myinventory-73dc6",
  storageBucket: "myinventory-73dc6.appspot.com",
  messagingSenderId: "1009595674740",
  appId: "1:1009595674740:web:618105201f35104b27e121",
  measurementId: "G-F89WMPB355"
};

const Ymenuopen = (nerdeyim) => {
  const navigate = useNavigate();

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth();
  const user = auth.currentUser;
  const [navvisible, setnavvisible] = useState(1);
  const handleopen = (newValue) => {

    setnavvisible(navvisible === 1 ? 0 : 1);

   
  };

  const tedarikciekle = async () => {
 
    navigate('/panel/tedarikciler');
};

const musteriekle = async () => {
 
  navigate('/panel/musteriler');
};

const gomain = async () => {
 
  navigate('/panel');
};

const gocikis = async () => {
 
  navigate('/');
};

const gobelge = async () => {
 
  navigate('/panel/belgeolustur');
};


const { t, i18n } = useTranslation();

const changeLanguage = (lang) => {

  i18n.changeLanguage(lang);
  setdil(lang)
  localStorage.setItem('dil', lang);
  console.log("dilbu:",lang)


};
const [dil, setdil] = useState("");


const goveri = async () => {
 
  navigate('/panel/veriakisi');
};

const gostok = async () => {
 
  navigate('/panel/stok');
};

const gogg = async () => {
 
  navigate('/panel/gelengiden');
};

const [usern, setusern] = useState("");
  const [firman, setfirman] = useState("");


useEffect(() => {
  

  const fetchData = async () => {

    
    i18n.changeLanguage(localStorage.getItem('dil'));

    try {
      

  
    
    const querySnapshot = await getDocs(
      query(
        collection(db, "users"),
        where("id", "==", user.uid)
      )
    );

    for (const doc of querySnapshot.docs) {

      setusern(doc.data().user)
      setfirman(doc.data().firma)
      
    }
  }

  catch (error) {

    console.log(error)

}

    

  };
    
  fetchData();

}, []);


if (navvisible === 1) {

  return (
    <div className={styles.ymenuopen}>
      <div className={styles.sidebar}>
        <div className={styles.frameParent}>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.t11Parent}>
                <img className={styles.t11Icon} alt="" src="/t1-1@2x.png" />
              </div>
              <img className={styles.shapeIcon} onClick={(e) => {
                e.stopPropagation(); 
                handleopen();
              }}  alt="" src="/Shape.png" />
            </div>
            <div className={styles.frameWrapper}>
              <div className={styles.rectangleParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="/t1-1@2x.png"
                />
                <div className={styles.canKraParent}>
                  <b className={styles.canKra}>{usern}</b>
                  <div className={styles.krc}>{firman}</div>
                </div>
                <img
                  className={styles.icons16pxArrowdown}
                  alt=""
                  src="/Down.png"
                />
              </div>
            </div>
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.frameParent1}>
              <div className={styles.frameParent2}>
                <div className={styles.frameParent3}>
                  <div className={styles.sayfalarWrapper}>
                    <div className={styles.sayfalar}>Sayfalar</div>
                  </div>
                  <div className={styles.frameParent4}>
                    
                    <div className={nerdeyim.data == "anaSayfa" ? styles.homePageParent : styles.hangarParent } onClick={(e) => {
                e.stopPropagation(); 
                gomain();
              }}>
                {nerdeyim.data != "anaSayfa" ? 
                      <img
                        className={styles.homePageIcon}
                        alt=""
                        src="/home-page@2x.png"
                      /> :
                      <img
                        className={styles.homePageIcon}
                        alt=""
                        src="/Home Pagex.png"
                      />
                    }
                      <div className={styles.stok}>Ana Sayfa</div>
                    </div>


                    <div className={nerdeyim.data == "urunler" ? styles.homePageParent : styles.hangarParent } onClick={(e) => {
                e.stopPropagation(); 
                gostok();
              }}>
                                {nerdeyim.data != "urunler" ? 

                      <img
                        className={styles.homePageIcon}
                        alt=""
                        src="/hangar@2x.png"
                      /> : 
                      <img
                        className={styles.homePageIcon}
                        alt=""
                        src="/Hangarx.png"
                      />
            }
                      <div className={styles.stok}>Stok</div>
                    </div>


                    <div className={nerdeyim.data == "gelengiden" ? styles.homePageParent : styles.hangarParent }  onClick={(e) => {
                e.stopPropagation(); 
                gogg();
              }}> 
                                              {nerdeyim.data != "gelengiden" ? 

                      <img
                        className={styles.homePageIcon}
                        alt=""
                        src="/sorting-arrows-horizontal@2x.png"
                      /> : 
                      <img
                        className={styles.homePageIcon}
                        alt=""
                        src="/Sorting Arrows Horizontal.png"
                      />
            }
                      <div className={styles.stok}>Gelen/Giden</div>
                    </div>

                    <div className={nerdeyim.data == "musteriler" ? styles.homePageParent : styles.hangarParent }  onClick={(e) => {
                e.stopPropagation(); 
                musteriekle();
              }}>
                                                {nerdeyim.data != "musteriler" ? 

                      <img
                        className={styles.homePageIcon}
                        alt=""
                        src="/queue@2x.png"
                      /> : 
                      <img
                        className={styles.homePageIcon}
                        alt=""
                        src="/Queue-1.png"
                      />
            }
                      <div className={styles.stok}>Müşteriler</div>
                    </div>

                    
                    <div className={nerdeyim.data == "tedarikciler" ? styles.homePageParent : styles.hangarParent }  onClick={(e) => {
                e.stopPropagation(); 
                tedarikciekle();
              }}>
                                                {nerdeyim.data != "tedarikciler" ? 

                      <img
                        className={styles.homePageIcon}
                        alt=""
                        src="/supplier@2x.png"
                      /> :
                      <img
                        className={styles.homePageIcon}
                        alt=""
                        src="/Supplier.png"
                      />
            }
                      <div className={styles.stok}>Tedarikçiler</div>
                    </div>


                  </div>
                </div>
                <div className={styles.frameParent3}>
                  <div className={styles.sayfalarWrapper}>
                    <div className={styles.sayfalar}>Araçlar</div>
                  </div>
                  <div className={styles.frameParent4}>

                    <div className={styles.hangarParent}onClick={(e) => {
                e.stopPropagation(); 
                gobelge();
              }}>
                      <img
                        className={styles.homePageIcon}
                        alt=""
                        src="/Create Document-1.png"
                      />
                      
                      <div className={styles.stok}>Belge Oluştur</div>
                    </div>

                    <div className={styles.hangarParent} onClick={(e) => {
                e.stopPropagation(); 
                goveri();
              }}>
                      <img
                        className={styles.homePageIcon}
                        alt=""
                        src="/Data Migration-1.png"
                      />
                      
                      <div className={styles.stok}>Veri Aktar</div>
                    </div>

                  </div>
                </div>
              </div>
              <div className={styles.frameItem} />
              <div className={styles.logoutParent} onClick={(e) => {
                e.stopPropagation(); 
                gocikis();
              }}>
                <img
                  className={styles.homePageIcon}
                  alt=""
                  src="/logout@2x.png"
                />
                <div className={styles.stok}>Çıkış Yap</div>
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.dividerParent}>
                <div className={styles.divider} />
                <div className={styles.navItem}>
                  <div className={styles.left}>
                    <img
                      className={styles.uiIconhelplight}
                      alt=""
                      src="/ui-iconhelplight.svg"
                    />
                    <div className={styles.sss}>S.S.S</div>
                  </div>
                  <div className={styles.wrapper}>
                    <div className={styles.div}>2</div>
                  </div>
                </div>
              </div>
              <div className={styles.switch}>



                <div className={styles.toggle} onClick={(e) => {
                e.stopPropagation(); 
                changeLanguage('tr');
              }}>
                  <div className={styles.tr}>TR</div>
                  <img
                    className={styles.image6Icon}
                    alt=""
                    src="/image-6@2x.png"
                  />
                </div>



                <div className={styles.toggle1} onClick={(e) => {
                e.stopPropagation(); 
                changeLanguage('en');
              }}>
                  <div className={styles.tr}>EN</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
    
}
else {

  return (
    <div className={styles2.ymenukapali}>
      <div className={styles2.short}>
        <div className={styles2.shortInner}>
          <img
            className={styles2.frameChild}
            alt=""
            src="/group-1000000895.svg"
          />
        </div>
        <div className={styles2.shortChild}>
          <img
            className={styles2.frameItem}
            alt=""
            src="/group-1000000894.svg"
          />
        </div>
        <div className={styles2.frameParent}>
          <div className={styles2.frameGroup}>
            <div className={styles2.frameGroup}>
              <img className={styles2.t11Icon} alt="" src="/t1-1@2x.png" />
              <img className={styles2.shapeIcon} onClick={(e) => {
                e.stopPropagation(); 
                handleopen();
              }}  alt="" src="/Shape (1).png" />
            <div className={styles2.aralar}>Sayfalar</div>          
                </div>
            <div className={styles2.frameInner} />
            <div className={styles2.homePageParent}>

              <img
                className={styles2.homePageIcon}
                alt=""
                src="/home-page@2x.png"
                onClick={(e) => {
                  e.stopPropagation(); 
                  gomain();
                }} />

              <img className={styles2.hangarIcon} alt="" src="/hangar@2x.png" onClick={(e) => {
                  e.stopPropagation(); 
                  gostok();
                }} />

              <img
                className={styles2.hangarIcon}
                alt=""
                src="/sorting-arrows-horizontal@2x.png"
                onClick={(e) => {
                  e.stopPropagation(); 
                  gogg();
                }}/>

              <img className={styles2.hangarIcon} alt="" src="/queue@2x.png"  onClick={(e) => {
                  e.stopPropagation(); 
                  musteriekle();
                }}/>

              <img
                className={styles2.hangarIcon}
                alt=""
                src="/supplier@2x.png"
                onClick={(e) => {
                  e.stopPropagation(); 
                  tedarikciekle();
                }} />

            </div>
            <div className={styles2.aralar}>Araçlar</div>
            <div className={styles2.frameInner} />

            <div className={styles2.createDocumentParent}>
              <img
                className={styles2.hangarIcon}
                alt=""
                src="/Create Document-1.png"
              />
              <img
                className={styles2.hangarIcon}
                alt=""
                src="/Data Migration-1.png"
                onClick={(e) => {
                  e.stopPropagation(); 
                  goveri();
                }}/>
            </div>
            <div className={styles2.frameInner} />
          </div>
          <div className={styles2.frameContainer} onClick={(e) => {
                e.stopPropagation(); 
                gocikis();
              }}>
            <div className={styles2.logoutWrapper}>
              <img className={styles2.hangarIcon} alt="" src="/logout@2x.png" />
            </div>
            <div className={styles2.frameInner} />
          </div>
        </div>
      </div>
    </div>
  );

}


};

export default Ymenuopen;
