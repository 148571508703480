import styles from "./DurumGncelleme.module.css";
import React, { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { documentId, getDocs, getFirestore } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy,doc,updateDoc} from "firebase/firestore"; 
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Ymenuopen from "./acikmenu";
import styles2 from "./AnaSayfaWeb.module.css";
import { Link, useNavigate } from 'react-router-dom';

import AramaSayfasi from "./AramaSayfasi";
const firebaseConfig = {
  apiKey: "AIzaSyBvuq7FyhGZ1OuPuYMDlxIvNx-ip_WVC8E",
  authDomain: "myinventory-73dc6.firebaseapp.com",
  projectId: "myinventory-73dc6",
  storageBucket: "myinventory-73dc6.appspot.com",
  messagingSenderId: "1009595674740",
  appId: "1:1009595674740:web:618105201f35104b27e121",
  measurementId: "G-F89WMPB355"
};
const DurumGncelleme = () => {

  const [usern, setusern] = useState("");
  const [firman, setfirman] = useState("");

  const auth = getAuth();
  const user = auth.currentUser;
  useEffect(() => {
  
    const updatedkod = [];
  
    const fetchData = async () => {
      try {
        
  
    
      
      const querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("id", "==", user.uid)
        )
      );
  
      for (const doc of querySnapshot.docs) {
  
        setusern(doc.data().user)
        setfirman(doc.data().firma)
        
      }
    }
  
    catch (error) {
  
      
      
  
  }
  
      
  
    };
      
    fetchData();
  
  }, []);


  const params = new URLSearchParams(window.location.search);
  const data = params.get('id');
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const [gglist, setgglist] = useState([]);
  const [gglistgecmis, setgglistgecmis] = useState([]);
  const [ggnotlist, setggnotlist] = useState([]);
  const [yeninot, setyeninot] = useState("");
  const [refreshing, setRefreshing] = useState(false);
  const [durumgvisible, setdurumgvisible] = useState(0);
  const [yenidurumgvisible, setyenidurumgvisible] = useState(0);
  const [notalvisible, setnotalvisible] = useState(0);

  const gg_gecmis = async (durum) => {
    const now = new Date();
    const formattedDateTime = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`;
    
    try {
      const docRef = await addDoc(collection(db, "durum_guncelleme"), {
        idsi: gglist.id,
        eski_durum: gglist.durum,
        kullanici: "QUSER",
        yeni_durum: durum,
        tarih: formattedDateTime,
        tariht: now,
      });

      const washingtonRef = doc(db, "gelecek_urun_kayit", gglist.id);
      await updateDoc(washingtonRef, {
        durum: durum,
      });

      onRefresh()

    } catch (error) {
      console.error("hata4:", error);
    }
  };

  const gg_notlar = async () => {
    const now = new Date();
    const formattedDateTime = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}`;
    
    try {
      const docRef = await addDoc(collection(db, "durum_guncelleme_notlar"), {
        idsi: gglist.id,
        tarih: formattedDateTime,
        tariht: now,
        yazan: "QUSER",
        durum: gglist.durum,
        not: yeninot,
      });

      setyeninot("");
      onRefresh();
    } catch (error) {
      console.error("hata3:", error);
    }
  };

  const gg_kayit = async () => {

    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, "gelecek_urun_kayit"), where("tarih", "==", data)
        )
      );

      querySnapshot.forEach((doc) => {
        const data = {
          urun: doc.data().urun,
          aciklama: doc.data().aciklama,
          adet: doc.data().adet,
          barkod: doc.data().barkod,
          cesit: doc.data().cesit,
          kaynak: doc.data().kaynak,
          marka: doc.data().marka,
          kullanici: doc.data().olusturan,
          baslik: doc.data().baslik,
          tarih: doc.data().tarih,
          durum: doc.data().durum,
          id: doc.id,
        };
        setgglist(data);
        gg_durumlar(data.id);

        gg_get_notlar(data.id);

      });
      

      
    } catch (error) {
      console.error("hata2:", error);
    }
  };




  const onRefresh = async () => {
    setRefreshing(true);
    try {
      await fetchData(); // Verileri yeniden getir
    } catch (error) {
      console.error("onRefresh Hatası:", error);
    } finally {
      setRefreshing(false);
    }
  };

  const fetchData = async () => {
    try {
      setgglist([])
      

      await gg_kayit();
      await gg_kayit_gidecek();

      
    } catch (error) {
      console.error("fetchData Hatası:", error);
    }
  };


  const gg_kayit_gidecek = async () => {

    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, "gidecek_urun_kayit"), where("tarih", "==", data)
        )
      );

      querySnapshot.forEach((doc) => {
        const data = {
          urun: doc.data().urun,
          aciklama: doc.data().aciklama,
          adet: doc.data().adet,
          barkod: doc.data().barkod,
          cesit: doc.data().cesit,
          kaynak: doc.data().kaynak,
          marka: doc.data().marka,
          kullanici: doc.data().olusturan,
          baslik: doc.data().baslik,
          tarih: doc.data().tarih,
          durum: doc.data().durum,
          id: doc.id,
        };
        setgglist(data);
        gg_durumlar(data.id);

        gg_get_notlar(data.id);
      });
      

      
    } catch (error) {
      console.error("hata2:", error);
    }
  };

  const gg_get_notlar = async (idsi) => {
    try {
      const ggliste = [];

      const querySnapshot = await getDocs(
        query(
          collection(db, "durum_guncelleme_notlar"), where("idsi", "==", idsi), orderBy("tariht", "asc")
        )
      );

      querySnapshot.forEach((doc) => {
        const data = {
          yazan: doc.data().yazan,
          not: doc.data().not,
          tarih: doc.data().tarih,
          durum: doc.data().durum,
        };
        ggliste.push(data);
      });

      setggnotlist(ggliste);
    } catch (error) {
      console.error("hata5:", error);
    }
  };

  const gg_durumlar = async (idsi) => {
    try {
      const ggliste = [];

      const querySnapshot = await getDocs(
        query(
          collection(db, "durum_guncelleme"), where("idsi", "==", idsi)
        )
      );

      querySnapshot.forEach((doc) => {
        const data = {
          tarih: doc.data().tarih,
          kullanici: doc.data().kullanici,
          eski_durum: doc.data().eski_durum,
          yeni_durum: doc.data().yeni_durum,
        };
        ggliste.push(data);
      });

      setgglistgecmis(ggliste);
    } catch (error) {
      console.error("hata:", error);
    }
  };


  useEffect(() => {

    gg_kayit();
    gg_kayit_gidecek();

  }, []);

  const handleToggleVisibilitydurum = () => {
    setdurumgvisible(durumgvisible === 1 ? 0 : 1);
  };

  const handleToggleVisibilityyenidurum = () => {
    setyenidurumgvisible(yenidurumgvisible === 1 ? 0 : 1);
  };

  const handleToggleVisibilitynotal = () => {
    setnotalvisible(notalvisible === 1 ? 0 : 1);
  };



  const [navvisible, setnavvisible] = useState(0);

  const handleClick = () => {
  
    setnavvisible(navvisible === 1 ? 0 : 1);
  
  };
  const  navfunc = async (nere) => {
   
     
    navigate(nere);
  };
  const navigate = useNavigate();


  const [arama, setarama] = useState(0);

  const handleAramaChange = (newValue) => {

    setarama(arama === 1 ? 0 : 1);

   
  };

  const  gogeri = async (nere) => {
         
    navigate("/panel/gelengiden");
  };

  if (arama === 0 ) {

  return (


    <div className={styles.durumGncelleme}>
        <Ymenuopen data="gelengiden"/>

      {navvisible === 1 ? 
      <div className={styles2.navigationDrawer}>
      <div className={styles2.top}>
        <div className={styles2.userParent} onClick={(e) => {
                e.stopPropagation(); 
                handleClick();
              }}>
          <div className={styles2.user}>
            <img className={styles2.icon} alt="" src="/wicon.png" />
            <div className={styles2.name}>
              <div className={styles2.aliVeli}>{usern}</div>
              <div className={styles2.irketAd}>{firman}</div>
            </div>
          </div>
          <div className={styles2.iconRightcouple}>
            <div className={styles2.iconRightcouple1}>
              <div className={styles2.number}>
                <div className={styles2.div}>8</div>
              </div>
              <div className={styles2.iconadd}>
                <div className={styles2.icon1}>
                  <img
                    className={styles2.addIcon}
                    alt=""
                    src="/add-icon.svg"
                  />
                </div>
              </div>
              <div 
              onClick={(e) => {
                e.stopPropagation(); 
                handleClick();
              }}>
      <img
        className={styles2.uiIconclosefilled}
        alt=""
        src="/ui-iconclosefilled.svg"
      />
    </div>
            </div>
          </div>
        </div>
        <div className={styles2.navItemParent}>


          <div className={styles2.navItem}  onClick={(e) => {
                e.stopPropagation(); 
                navfunc("/panel");
              }}>
            <div className={styles2.left}>
              <img
                className={styles2.homePageIcon}
                alt=""
                src="/home-page@2x.png"
              />
              <div className={styles2.anaSayfa}>Ana Sayfa</div>
            </div>
            <div className={styles2.wrapper}>
              <div className={styles2.div1}>2</div>
            </div>
          </div>


          <div className={styles2.navItem1} onClick={(e) => {
                e.stopPropagation(); 
                navfunc("/panel/stok");
              }}>
            <div className={styles2.left1}>
              <div className={styles2.left}>
                <img
                  className={styles2.hangarIcon}
                  alt=""
                  src="/hangar@2x.png"
                />
                <div className={styles2.anaSayfa}>Stok</div>
              </div>
            </div>
            
          </div>
          <div className={styles2.navItem1} onClick={(e) => {
                e.stopPropagation(); 
                navfunc("/panel/gelengiden");
              }}>
            <div className={styles2.left}>
              <img
                className={styles2.homePageIcon}
                alt=""
                src="/sorting-arrows-horizontal@2x.png"
              />
              <div className={styles2.anaSayfa}>Gelen/Giden</div>
            </div>
           
          </div>
          <div className={styles2.navItem1} onClick={(e) => {
                e.stopPropagation(); 
                navfunc("/panel/musteriler");
              }}>
            <div className={styles2.left}>
              <img
                className={styles2.homePageIcon}
                alt=""
                src="/queue@2x.png"
              />
              <div className={styles2.anaSayfa}>Müşteriler</div>
            </div>
           
          </div>
          <div className={styles2.navItem1} onClick={(e) => {
                e.stopPropagation(); 
                navfunc("/panel/tedarikciler");
              }}>
            <div className={styles2.left}>
              <img
                className={styles2.homePageIcon}
                alt=""
                src="/supplier@2x.png"
              />
              <div className={styles2.anaSayfa}>Tedarikçiler</div>
            </div>
            
          </div>

          <div className={styles2.navItem1} onClick={(e) => {
                e.stopPropagation(); 
                navfunc("/panel/veriakisi");
              }}>
            <div className={styles2.left}>
              <img
                className={styles2.homePageIcon}
                alt=""
                src="/data-migration.png"
              />
              <div className={styles2.anaSayfa}>Veri Aktar</div>
            </div>
            
          </div>
          
          <div className={styles2.navItem1} onClick={(e) => {
                e.stopPropagation(); 
                navfunc("/");
              }}>
            <div className={styles2.left}>
              <img
                className={styles2.logoutIcon}
                alt=""
                src="/logout@2x.png"
              />
              <div className={styles2.anaSayfa}>Çıkış Yap</div>
            </div>
            
          </div>
        </div>
      </div>
      <div className={styles2.bottom}>
        <div className={styles2.dividerParent}>
          <div className={styles2.divider} />
          <div className={styles2.navItem1}>
            <div className={styles2.left}>
              <img
                className={styles2.uiIconclosefilled}
                alt=""
                src="/ui-iconhelplight.svg"
              />
              <div className={styles2.anaSayfa}>destek@qstock.com.tr</div>
            </div>
            <div className={styles2.wrapper2}>
              <div className={styles2.div1}>2</div>
            </div>
          </div>
        </div>
        <div className={styles2.switch}>
          <div className={styles2.toggle}>
            <div className={styles2.tr}>TR</div>
            <img className={styles2.image6Icon} alt="" src="/image-6@2x.png" />
          </div>
          <div className={styles2.toggle1}>
            <div className={styles2.tr}>EN</div>
          </div>
        </div>
      </div>
    </div>
: null }
      <div className={styles.headerParent}>
        <div className={styles.header}>
          <div className={styles.header1}>
            <div className={styles.ctaButton} onClick={(e) => {
                  e.stopPropagation(); 
                  gogeri();
                }}>
              <img
                className={styles.uiIconclosefilled}
                alt=""
                
                src="/arrowleft.svg"
              />
            </div>
            <b className={styles.durumGncellemeleri}>Durum Güncelleme</b>
          </div>
          <div className={styles.recommended}>
            <div className={styles.recommended1}>Oluşturulma Tarihi: {gglist.tarih}</div>
            <div className={styles.funds1}>
              <div className={styles.frameParent}>
                <div className={styles.frameGroup}>
                  <div className={styles.frameWrapper}>
                    <div className={styles.newWorldFundParent}>
                      <div className={styles.newWorldFund}>{gglist.baslik !== "" ? gglist.baslik : "Stok Girişi"}</div>
                      <div className={styles.industries}>{gglist.kaynak}</div>
                    </div>
                  </div>
                  <div className={styles.midRisk}>
                    {gglist.durum == "Yolda" ? <div className={styles.midRisk1}>{gglist.durum}</div> : null }
                    {gglist.durum == "İptal" ? <div className={styles.midRisk1iptal}>{gglist.durum}</div> : null }
                    {gglist.durum == "Tamamlandı" ? <div className={styles.midRisk1ok}>{gglist.durum}</div> : null }
                    {gglist.durum == "Oluşturuldu" ? <div className={styles.midRisk1new}>{gglist.durum}</div> : null }

                  </div>
                </div>
                <div className={styles.frameContainer}>
                  <div className={styles.yearParent}>
                    <div className={styles.year}>
                      <div className={styles.year1}>Ürün</div>
                      <div className={styles.div9}>{gglist.urun}</div>
                    </div>
                    <div className={styles.year}>
                      <div className={styles.year1}>Marka</div>
                      <div className={styles.div9}>{gglist.marka}</div>
                    </div>
                    <div className={styles.year}>
                      <div className={styles.year1}>Model</div>
                      <div className={styles.div9}>{gglist.cesit}</div>
                    </div>
                    <div className={styles.year}>
                      <div className={styles.year1}>Adet</div>
                      <div className={styles.div9}>{gglist.adeth}</div>
                    </div>
                  </div>
                  <div className={styles.year8}>
                    <div className={styles.year9}>Not</div>
                    <div className={styles.div13}>
                    {gglist.aciklama}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.body}>


        {durumgvisible === 1 ? 

<div className={styles.onProgressKapali} onClick={(e) => {
                e.stopPropagation(); 
                handleToggleVisibilitydurum();
              }}>
<div className={styles.placeholder}>
  <div className={styles.left9}>
    <b
      className={styles.durumGncellemeleri}
    >{`Durum Güncellemeleri `}</b>
    <div className={styles.div14}>{(gglistgecmis.length)+1}/4</div>
  </div>
  <img
    className={styles.uiIconclosefilled}
    alt=""
    src="/chevrondown.svg"
  />
</div>
</div> : 

          <div className={styles.onProgress} >
            <div className={styles.placeholder} onClick={(e) => {
            e.stopPropagation(); 
            handleToggleVisibilitydurum();
          }}>
              <div className={styles.left9}>
                <b
                  className={styles.durumGncellemeleri}
                >{`Durum Güncellemeleri `}</b>
                <div className={styles.div14}>{(gglistgecmis.length)+1}/4</div>
              </div>
              <img
                className={styles.uiIconclosefilled}
                alt=""
                src="/chevronup.svg"
              />
            </div>
            <img className={styles.dividerIcon} alt="" src="/divider.svg" />
            <div className={styles.container1}>
              <div className={styles.timeUploaded}>
                <div className={styles.sonGncelleme}>Son güncelleme</div>
                <div className={styles.jan092024}></div>
              </div>

            
              
              {gglistgecmis.some(item => item.yeni_durum === "İptal") === true ?

<div className={styles.task01}>
  <div className={styles.badges3}>
    <div className={styles.label}>
      <b className={styles.durumGncellemeleri}>İptal</b>
    </div>
  </div>
  <div className={styles.container2}>
    <div className={styles.right}>
      <div className={styles.container3}>
        <div className={styles.leftParent}>
          <div className={styles.left10}>
            <div className={styles.bottom1}>
              <div className={styles.date}>
                <div className={styles.tarih}>Tarih:</div>
                {gglistgecmis.filter(item => item.yeni_durum === "İptal").length > 0 && gglistgecmis.filter(item => item.yeni_durum === "İptal")[0].tarih !== undefined ? 
<div className={styles.canKra}>{gglistgecmis.filter(item => item.yeni_durum === "İptal")[0].tarih}</div>
: null 
}                            </div>
            </div>
          </div>
          <div className={styles.left10}>
            <div className={styles.bottom1}>
              <div className={styles.date}>
                <div className={styles.tarih}>Oluşturan</div>
                <div className={styles.canKra}>{gglistgecmis.filter(item => item.yeni_durum === "İptal").length > 0 && gglistgecmis.filter(item => item.yeni_durum === "İptal")[0].kullanici !== undefined ? 

<div className={styles.canKra}>{gglistgecmis.filter(item => item.yeni_durum === "İptal")[0].kullanici}</div>
: null }</div>                            </div>
            </div>
          </div>
        </div>
        <div className={styles.right1}>
          <div className={styles.menu}>
            
          </div>
          <img
            className={styles.removeIcon}
            alt=""
            src="/remove@2x.png"
          />
        </div>
      </div>
    </div>
  </div>
</div>

:null } 
              {gglistgecmis.some(item => item.yeni_durum === "Tamamlandı") === true ?

              <div className={styles.task01}>
                <div className={styles.badges2}>
                  <div className={styles.label}>
                    <b className={styles.durumGncellemeleri}>Tamamlandı</b>
                  </div>
                </div>
                <div className={styles.container2}>
                  <div className={styles.right}>
                    <div className={styles.container3}>
                      <div className={styles.leftParent}>
                        <div className={styles.left10}>
                          <div className={styles.bottom1}>
                            <div className={styles.date}>
                              <div className={styles.tarih}>Tarih:</div>
                              {gglistgecmis.filter(item => item.yeni_durum === "Tamamlandı").length > 0 && gglistgecmis.filter(item => item.yeni_durum === "Tamamlandı")[0].tarih !== undefined ? 
  <div className={styles.canKra}>{gglistgecmis.filter(item => item.yeni_durum === "Tamamlandı")[0].tarih}</div>
  : null 
}                            </div>
                          </div>
                        </div>
                        <div className={styles.left10}>
                          <div className={styles.bottom1}>
                            <div className={styles.date}>
                              <div className={styles.tarih}>Oluşturan</div>
                              <div className={styles.canKra}>{gglistgecmis.filter(item => item.yeni_durum === "Tamamlandı").length > 0 && gglistgecmis.filter(item => item.yeni_durum === "Tamamlandı")[0].kullanici !== undefined ? 

<div className={styles.canKra}>{gglistgecmis.filter(item => item.yeni_durum === "Tamamlandı")[0].kullanici}</div>
: null }</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.right1}>
                        <div className={styles.menu}>
                          
                        </div>
                        <img
                          className={styles.removeIcon}
                          alt=""
                          src="/remove@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
: null }
            {gglistgecmis.some(item => item.yeni_durum === "Yolda") === true ?
              <div className={styles.task01}>
                <div className={styles.badges}>
                  <div className={styles.label}>
                    <b className={styles.durumGncellemeleri}>Yolda</b>
                  </div>
                </div>
                <div className={styles.container2}>
                  <div className={styles.right}>
                    <div className={styles.container3}>
                      <div className={styles.leftParent}>
                        <div className={styles.left10}>
                          <div className={styles.bottom1}>
                            <div className={styles.date}>
                              <div className={styles.tarih}>Tarih:</div>
                              {gglistgecmis.filter(item => item.yeni_durum === "Yolda").length > 0 && gglistgecmis.filter(item => item.yeni_durum === "Yolda")[0].tarih !== undefined ? 
  <div className={styles.canKra}>{gglistgecmis.filter(item => item.yeni_durum === "Yolda")[0].tarih}</div>
  : null 
}
                            </div>
                          </div>
                        </div>
                        <div className={styles.left10}>
                          <div className={styles.bottom1}>
                            <div className={styles.date}>
                              <div className={styles.tarih}>Oluşturan</div>
                              {gglistgecmis.filter(item => item.yeni_durum === "Yolda").length > 0 && gglistgecmis.filter(item => item.yeni_durum === "Yolda")[0].kullanici !== undefined ? 

                              <div className={styles.canKra}>{gglistgecmis.filter(item => item.yeni_durum === "Yolda")[0].kullanici}</div>
                              : null }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.right1}>
                        <div className={styles.menu}>
                          
                        </div>
                        <img
                          className={styles.removeIcon}
                          alt=""
                          src="/remove@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
: null }
            
              
              <div className={styles.task01}>
                <div className={styles.badges1}>
                  <div className={styles.label}>
                    <b className={styles.durumGncellemeleri}>Oluşturuldu</b>
                  </div>
                </div>
                <div className={styles.container2}>
                  <div className={styles.right}>
                    <div className={styles.container3}>
                      <div className={styles.leftParent}>
                        <div className={styles.left10}>
                          <div className={styles.bottom1}>
                            <div className={styles.date}>
                              <div className={styles.tarih}>Tarih:</div>
                              <div className={styles.canKra}>{gglist.tarih}</div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.left10}>
                          <div className={styles.bottom1}>
                            <div className={styles.date}>
                              <div className={styles.tarih}>Oluşturan</div>
                              <div className={styles.canKra}>{gglist.kullanici}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.right1}>
                        <div className={styles.menu}>
                          
                        </div>
                        <img
                          className={styles.removeIcon}
                          alt=""
                          src="/remove@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
{yenidurumgvisible === 1 ? 
          <div className={styles.onProgress} >
            <div className={styles.placeholder} onClick={(e) => {
                e.stopPropagation(); 
                handleToggleVisibilityyenidurum();
              }}>
              <div className={styles.left9}>
                <b className={styles.durumGncellemeleri}>Yeni Durum Seç</b>
                <div className={styles.div14}>{4-((gglistgecmis.length)+1)}/4</div>
              </div>
              <img
                className={styles.uiIconclosefilled}
                alt=""
                src="/chevronup.svg"
              />
            </div>
            <img className={styles.dividerIcon} alt="" src="/divider.svg" />
            <div className={styles.container1}>
              <div className={styles.timeUploaded}>
                <div className={styles.sonGncelleme}>Son güncelleme</div>
                <div className={styles.jan092024}></div>
              </div>





              {gglistgecmis.some(item => item.yeni_durum === "Yolda") === false ?


              <div className={styles.task01} onClick={(e) => {
                e.stopPropagation(); 
                gg_gecmis("Yolda");
              }}>
                <div className={styles.badges}>
                  <div className={styles.label}>
                    <b className={styles.durumGncellemeleri}>Yolda</b>
                  </div>
                </div>
                <div className={styles.container11}>
                  <div className={styles.right8}>
                    <div className={styles.container12}>
                      <div className={styles.sonGncelleme}>Yolda İse Seçin</div>
                      <div className={styles.name}>
                        <div className={styles.left19} />
                      </div>
                      <div className={styles.right1}>
                        <div className={styles.menu}>
                          
                        </div>
                        <img
                          className={styles.checkAllIcon}
                          alt=""
                          src="/check-all@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
: null }

              

              {gglistgecmis.some(item => item.yeni_durum === "Tamamlandı") === false ?

              <div className={styles.task01} onClick={(e) => {
                e.stopPropagation(); 
                gg_gecmis("Tamamlandı");
              }}>
                <div className={styles.badges2}>
                  <div className={styles.label}>
                    <b className={styles.durumGncellemeleri}>Tamamlandı</b>
                  </div>
                </div>
                <div className={styles.container11}>
                  <div className={styles.right8}>
                    <div className={styles.container12}>
                      <div className={styles.sonGncelleme}>Tamamlandı İse Seçin</div>
                      <div className={styles.name}>
                        <div className={styles.left19} />
                      </div>
                      <div className={styles.right1}>
                        <div className={styles.menu}>
                          
                        </div>
                        <img
                          className={styles.checkAllIcon}
                          alt=""
                          src="/check-all@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
:null}

{gglistgecmis.some(item => item.yeni_durum === "İptal") === false ?

              <div className={styles.task01} onClick={(e) => {
                e.stopPropagation(); 
                gg_gecmis("İptal");
              }}>
                <div className={styles.badges3}>
                  <div className={styles.label}>
                    <b className={styles.durumGncellemeleri}>İptal</b>
                  </div>
                </div>
                <div className={styles.container11}>
                  <div className={styles.right8}>
                    <div className={styles.container12}>
                      <div className={styles.sonGncelleme}>İptal İse Seçin</div>
                      <div className={styles.name}>
                        <div className={styles.left19} />
                      </div>
                      <div className={styles.right1}>
                        <div className={styles.menu}>
                         
                        </div>
                        <img
                          className={styles.checkAllIcon}
                          alt=""
                          src="/check-all@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

: null }



            </div>
          </div>
: 
<div className={styles.done} onClick={(e) => {
                e.stopPropagation(); 
                handleToggleVisibilityyenidurum();
              }}>
            <div className={styles.placeholder}>
              <div className={styles.left9}>
                <b className={styles.durumGncellemeleri}>Yeni Durum Seç</b>
                <div className={styles.div14}>{4-((gglistgecmis.length)+1)}/4</div>
              </div>
              <img
                className={styles.uiIconclosefilled}
                alt=""
                src="/chevrondown.svg"
              />
            </div>
          </div>
}






{notalvisible === 1 ? 

          <div className={styles.done} onClick={(e) => {
           
            handleToggleVisibilitynotal();
          }}>
            <div className={styles.placeholder}>
              <div className={styles.left9}>
                <b className={styles.durumGncellemeleri}>Alınan Notlar</b>
                <div className={styles.div14}>0/0</div>
              </div>
              <img
                className={styles.uiIconclosefilled}
                alt=""
                src="/chevrondown.svg"
              />
            </div>
          </div> :


          <div className={styles.yeniDurumAcik1} >
            <div className={styles.placeholder5} onClick={(e) => {
           
            handleToggleVisibilitynotal();
          }}>
              <div className={styles.left9}>
                <b className={styles.durumGncellemeleri}>Alınan Notlar</b>
                <div className={styles.div14}>({ggnotlist.length})</div>
              </div>
              <img
                className={styles.uiIconclosefilled}
                alt=""
                src="/chevronup.svg"
              />
            </div>
            <img className={styles.dividerIcon} alt="" src="/divider.svg" />
            <div className={styles.container19}>
              <div className={styles.timeUploaded2}>
                
              </div>


              {ggnotlist.map((item) => (

              <div className={styles.task31}>
                <div className={styles.rightParent}>
                  <div className={styles.right16}>
                    <img
                      className={styles.maleUserIcon}
                      alt=""
                      src="/male-user@2x.png"
                    />
                    <div className={styles.date}>
                      <div className={styles.container20}>
                        <div className={styles.sonGncelleme}>
                          <span>Yazan:</span>
                          <span> {item.yazan}</span>
                        </div>
                        <div className={styles.sonGncelleme}>
                          Tarih :{item.tarih}
                        </div>
                        <div className={styles.sonGncelleme}>
                          <span>{`Durum : `}</span>
                          <span className={styles.yolda}>{item.durum}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.container21}>
                    <div className={styles.durumuAlndOlarak}>
                    {item.not}
                    </div>
                  </div>
                </div>
              </div>
              ))}



            </div>
            <div className={styles.frameParent1}>
              <div className={styles.notBraknNotBraknNoWrapper}>
                
              <input
          className={styles.notBraknNot}
          placeholder="Not Bırakın ..."
          onClick={(e) => e.stopPropagation()} 
          onChange={(e) => {
            setyeninot(e.target.value);
          }}
        />
              </div>
              <div className={styles.ctaButton1} onClick={(e) => {
                e.stopPropagation(); 
                gg_notlar();
              }}>
                <div className={styles.label}>
                  <div className={styles.xlLabel}>Gönder</div>
                </div>
              </div>
            </div>
          </div>

}
        </div>
      </div>
    </div>
  );
  }
  

  else {
    return(
    <AramaSayfasi handleAramaChange={handleAramaChange}/>
    )
    }


};

export default DurumGncelleme;
